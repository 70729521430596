export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deals = require("../assets/deals.svg")
export const accordition_icon = require("../assets/accordition_icon.svg");
export const customer = require("../assets/customer.svg");
export const others = require("../assets/others.svg");
export const dealSuccess = require("../assets/deal_success.svg");
export const rightCheck = require("../assets/right_check.svg");
export const dealsMain = require("../assets/dealsmain.svg");
export const searchIcon = require("../assets/search.svg")
export const downloadIcon = require("../assets/download.svg")
export const deleteIcon = require("../assets/delete.svg")
export const closeIcon = require("../assets/close.svg")
export const graphBars = require("../assets/bars.svg")
export const leadsGraphBars = require("../assets/leads_bar.svg")
export const emptySvg = require("../assets/empty.svg")
export const uploadIcon = require("../assets/upload.svg")
export const filesUploaded = require("../assets/files_uploaded.svg")
export const uploadFile = require("../assets/upload_file.svg")