import React from 'react';
import { Line } from "react-chartjs-2";
import { lineData } from './commonUsage';

export default function AllTimeGraph(props: any) {
    return (
        <div style={{ height: '35px', width: '104px' }}>
            <Line
                data={lineData(props.data)}
                options={{
                    elements: {
                        point:{
                            radius: 0
                        }
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: { display: false },
                        tooltip: { enabled: false } // Disable tooltips
                    },
                    scales: {
                        y: {
                            ticks: {
                                display: false
                            },
                            grid: {
                                drawBorder: false,
                                display: false
                            }
                        },
                        x: {
                            ticks: {
                                display: false
                            },
                            grid: {
                                drawBorder: false,
                                display: false
                            }
                        },
                    },
                }}
            />
        </div>
    )
}
