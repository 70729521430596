import React, { useEffect, useState } from "react";
import { View, Button, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";
import './sidenav.css';
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { getToken } from "./commonUsage";

const siteLogo = require("./site_logo.svg")
const dashboard = require("./dashboard.svg")
const deals = require("./deals.svg")
const assets = require("./assets.svg")
const commision = require("./commision.svg")
const companies = require("./companies.svg")
const lead_companies = require("./lead_companies.svg")
const contract = require("./contract.svg")
const dashboard_active = require("./dashboard_active.svg")
const deals_active = require("./deals_active.svg")
const assets_active = require("./assets_active.svg")
const commision_active = require("./commision_active.svg")
const companies_active = require("./companies_active.svg")
const lead_companies_active = require("./lead_companies_active.svg")
const contract_active = require("./contract_active.svg")
const down_arrow = require("./down_arrow.svg")
const down_arrow_active = require("./down_arrow_active.svg")
const help_support = require("./help-support.svg")

const SideNav = ({ history }: any) => {

    const [navItem, setNavItem] = useState("")
    const [expanded, setExpanded] = useState(null);

    const sideNavOptions = [
        { label: 'Dashboard', value: 'dashboard', logo: dashboard, active_logo: dashboard_active, navigation: "/DashboardWeb" },
        { label: 'Deals and leads', value: 'deals', logo: deals, active_logo: deals_active, navigation: "/LeadDashboard" },
        { label: 'Asset library', value: 'assets', logo: assets, active_logo: assets_active, navigation: "/AssetsDashboardWeb" },
        { label: 'Commission report', value: 'commision', logo: commision, active_logo: commision_active, navigation: "/Commissionreports2" },
        { label: 'Leads company', value: 'companies', logo: lead_companies, active_logo: lead_companies_active, navigation: "/Companycontactpage2" },
        // { label: 'Contract Details', value: 'contract', logo: contract, active_logo: contract_active, navigation: "/" }
    ]

    useEffect(() => {
        setNavItem(window.location.pathname)
    }, [window.location.pathname])

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        //@ts-ignore
        setExpanded(isExpanded ? panel : null);
    };

    useEffect(() => {
        // Check if any of the sub-items of "My Company" is active
        if (navItem === '/Contractmanagement2' || navItem === '/ContractDetail' || navItem === '/PayoutSettings') {
            //@ts-ignore
            setExpanded('panel1'); // Expand the accordion when any of the sub-items is active
        } else {
            setExpanded(null); // Collapse the accordion otherwise
        }
    }, [navItem]);

    return (
        <div className="nav-container">
            <div>
            <div className="logo-wrapper">
                <img src={siteLogo} className="main-logo" alt="Builder.ai" />
            </div>
            {/* <Button title="Home" onPress={() => history.push("/")} /> */}
            <ul className="menu-list">
                {
                    sideNavOptions.map((item, index) => {
                        return (
                            <li onClick={() => {
                                setNavItem(item.navigation)
                                history.push(item.navigation)
                                localStorage.removeItem('company_name')
                                localStorage.removeItem('company_id')
                            }} className={item.navigation === navItem ? "nav-item nav-item-active" : "nav-item"} key={index}>
                                <img src={item.navigation === navItem ? item.active_logo : item.logo || item.logo} alt="item.label" /><label className="nav-label">{item.label}</label>
                            </li>
                        )
                    })
                }
                {
                    getToken('partner_role') !== 'member' &&
                        <li>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={expanded ? 'nav-accordion active-accordion-item' : 'nav-accordion'}>
                                <AccordionSummary
                                    expandIcon={expanded ? <img className="arrow-img" src={down_arrow_active} /> : <img className="arrow-img" src={down_arrow} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className="nav-accordion-title nav-item"
                                >
                                    <img src={expanded ? companies_active : companies} alt='' />My company
                                </AccordionSummary>
                                <AccordionDetails className="accordion-nav-list">
                                    <ul>
                                        <li onClick={() => {
                                            setNavItem("/Contractmanagement2")
                                            history.push("/Contractmanagement2")
                                            localStorage.removeItem('company_name')
                                            localStorage.removeItem('company_id')
                                        }}
                                            className={navItem === '/Contractmanagement2' ? "active-item" : ""}
                                        >
                                            My teams
                                        </li>
                                        <li onClick={() => {
                                            setNavItem("/ContractDetail")
                                            history.push("/ContractDetail")
                                        }}
                                            className={navItem === '/ContractDetail' ? "active-item" : ""}
                                        >
                                            Contract details
                                        </li>
                                        <li onClick={() => {
                                            setNavItem("/PayoutSettings")
                                            history.push("/PayoutSettings")
                                        }}
                                            className={navItem === '/PayoutSettings' ? "active-item" : ""}
                                        >
                                            Payouts settings
                                        </li>
                                        
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </li>
                }
            </ul>
            </div>
            <div className="help-link-wrap">
            <p className="help-link" onClick={() => {
                setNavItem("/TicketSystem")
                history.push("/TicketSystem")
            }}>
                <img src={help_support} alt="help & support" />Support center</p>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    main: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: "#cccccc"
    }
});

export default withRouter(SideNav);
