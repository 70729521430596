// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { NavigationStackProp } from "react-navigation-stack";
import { imgPasswordInVisible } from "./assets";
import { getCloseDealDateFormat, getErrorResponse, getMemberErrorMessages, getToken, removeAllToken } from "../../../components/src/commonUsage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  inputData?: Array;
  rawInputData?: Array;
  showdropdown: boolean;
  showSubmitBtn: boolean;
  formId: string;
  headerTitle: string;
  successMsg: string;
  errorMessage: any;
  createNewCompany: boolean;
  leadInfo: any;
  companyArray: any;
  industryArray: any;
  selectedRows: any;
  selectAll: any;
  singleLeadInfo: any;
  currentPage: any;
  perPage: any;
  deleteModal: any;
  searchValue: any;
  sortValue: any;
  stagesValue: any;
  sortMenu: any;
  selectMenu: any;
  companyId: any;
  query: any;
  accordionStatus: any;
  csvData: any;
  uploadCsvModal: boolean;
  isUploaded: boolean;
  isDragging: boolean;
  fileSizeError: any;
  fileTypeError: any;
  uploadLeadsCount: any;
  selectedFile: any;
  confirmationModal: boolean;
  sampleCsvData: any;
  dealStatusFocus: boolean;
  companyNameFocus: boolean;
  industryFocus: boolean;
  countryFocus: boolean;
  leadRegistraion: boolean;
  dealRegistraton: boolean;
  countryData: any;
  countryCodes: any;
  currencyData: any;
  currencyFocus: boolean;
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeadManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllInputsApiId: string = "";
  postInputDataApiId: string = "";
  createNewDealApiId: string = "";
  getAllLeadsApiId: string = "";
  getAllIndustryApiId: string = "";
  getAllCompaniesApiId: string = "";
  getSingleLeadApiCallID: string = "";
  updateLeadPointApiId: string = "";
  deleteLeadPointApiId: string = "";
  downloadCSVLeadID: string = "";
  fileInputRef: any;
  fileInputNewRef: any;
  uploadCsvApiCallId: string = "";
  checkCsvApiCallId: string = "";
  localStorage: any;
  sampleCsvDownloadApiId: string = "";
  getAllCountryiesApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      alertData: {
        title: "",
        content: "",
        visible: false,
        btnData: [{}],
      },
      inputData: [],
      rawInputData: [],
      showdropdown: false,
      showSubmitBtn: false,
      formId: "",
      headerTitle: "",
      successMsg: "",
      errorMessage: null,
      createNewCompany: false,
      leadInfo: {
        "leads": {
          "data": []
        },
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": null,
          "previous_page": null
        },
        "csv_url": "",
        "open_deals": 0
      },
      companyArray: [],
      industryArray: [],
      selectedRows: [],
      selectAll: false,
      singleLeadInfo: {
        "id": "",
        "form_id": "",
        "created_at": "",
        "updated_at": "",
        "form_data": "",
        "form_name": "",
        "first_name": "",
        "partner_id": "",
        "last_name": "",
        "customer_email": "",
        "conversion_value": "",
        "commission_percentage": "",
        "deal_status": "",
        "deal_name": "",
        "description": "",
        "source": "",
        "deal_currency": "",
        "deal_value": "",
        "company_lead": {
          "company_name": "",
          "industry": "",
          "company_website": "",
          "country": "",
          "state": "",
          "city": "",
        },
      },
      currentPage: 1,
      perPage: 10,
      deleteModal: false,
      searchValue: '',
      sortValue: 'Latest',
      stagesValue: 'All',
      selectMenu: null,
      sortMenu: null,
      companyId: "",
      query: "",
      accordionStatus: {
        deal: true, // Open the "Deal Information" accordion by default
        customer: true,
        other: true,
        leadCustomer: true,
        leadInformation: true
      },
      csvData:
      {
        "headers": [],
        "data": []
      },
      uploadCsvModal: false,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {},
      confirmationModal: false,
      isDealStatusFocused: false,
      sampleCsvData:
      {
        "headers": [
          "company name",
          "first name",
          "last name",
          "email",
          "website",
          "industry",
          "country"
        ],
        "data": [
          {
            "company name": null,
            "first name": null,
            "last name": null,
            "email": null,
            "website": null,
            "industry": null,
            "country": null
          }
        ]
      },
      dealStatusFocus: false,
      companyNameFocus: false,
      industryFocus: false,
      countryFocus: false,
      leadRegistraion: true,
      dealRegistraton: false,
      countryData: [],
      countryCodes: [],
      currencyData: [],
      currencyFocus: false
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.setState({
      isLoading: false,
    });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson)) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.createNewDealApiId:
            this.handleCreateNewDealResponse(responseJson);
            break;
          case this.updateLeadPointApiId:
            this.handleResponse(responseJson, true, 'LeadDashboard', null);
            break;
          case this.getAllLeadsApiId:
            this.handleResponse(responseJson, false, '', 'leadInfo');
            this.downloadDealsCSV();
            break;
          case this.getAllIndustryApiId:
            this.handleResponse(responseJson, false, '', 'industryArray');
            break;
          case this.getAllCompaniesApiId:
            this.handleResponse(responseJson, false, '', 'companyArray');
            break;
          case this.getSingleLeadApiCallID:
            this.handleResponse(responseJson.data.attributes, false, '', 'singleLeadInfo');
            break;
          case this.checkCsvApiCallId:
            this.handleCheckCsvResponse(responseJson);
            break;
          case this.uploadCsvApiCallId:
            this.handleUploadCsvResponse(responseJson);
            break;
          case this.deleteLeadPointApiId:
            this.setState({
              selectAll: false,
              deleteModal: false,
              selectedRows: [],
              currentPage: 1,
              perPage: 10,
              leadInfo: {
                "leads": {
                  "data": []
                },
                "meta": {
                  "total_pages": 0,
                  "total_count": 0,
                  "current_page": 0,
                  "next_page": null,
                  "previous_page": null
                },
                "csv_url": "",
                "open_deals": 0
              }
            });
            this.getLeads('normal');
            break;
          case this.downloadCSVLeadID:
            this.handleResponse(responseJson, false, '', 'csvData');
            break;
          case this.sampleCsvDownloadApiId:
            this.setState({
              sampleCsvData: {
                headers: responseJson.headers,
                data: responseJson.data
              }
            });
            break;
          case this.getAllCountryiesApiId:
            this.setState({
              countryData: responseJson.map((country) => ({
                label: country.name,
                value: country.name,
                ...country
              })),
              currencyData: responseJson.filter((item: any) => item.currency_label !== undefined).map((item: any) => ({
                label: item.currency_label,
                value: item.currency_label,
                ...item
              })),
              countryCodes: responseJson
            })
            break;
          // Add more cases here as needed
          default:
            // Handle the default case here
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source =
        !this.txtInputProps.secureTextEntry && imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: !this.txtInputProps.secureTextEntry && imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgName = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgName.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgName);
  }

  // Customizable Area Start

  async componentDidMount() {
    console.log("Hi")
  }

  handleCreateNewDealResponse(responseJson) {
    if (responseJson.errors) {
      this.setState({
        errorMessage: getErrorResponse(responseJson),
      });
    } else {
      this.openConfirmationModal();
    }
  }


  handleCheckCsvResponse(responseJson) {
    if (!responseJson.errors) {
      this.setState({
        isUploaded: true,
        uploadLeadsCount: responseJson.successful_rows,
      });
    }
  }

  handleUploadCsvResponse(responseJson) {
    if (!responseJson.errors) {
      this.props.navigation.navigate('LeadDashboard', {
        uploadMessage: responseJson.upload_data,
      });
    }
  }

  handlePageChange(value) {
    this.setState({ currentPage: parseInt(value) }, () => {
      this.getLeads('normal');
    });
  }

  handleForms(value: any) {
    if (value === 'deal') {
      this.setState({
        dealRegistraton: true,
        leadRegistraion: false,
        errorMessage: null,
        createNewCompany: false
      })
    }
    if (value === 'lead') {
      this.setState({
        errorMessage: null,
        dealRegistraton: false,
        leadRegistraion: true
      })
    }
  }

  handleResponse(responseJson, navigaion, path, statedata) {
    if (!responseJson.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState);
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });
    }
  }

  getFormikError(error, erros) {
    return error && Boolean(erros)
  }
  getFormikHelperText(error, erros) {
    return error && erros
  }

  testValidateFunction() {
    this.setState({
      inputData: successResponse.data.inputFields,
    });
    this.validateFields();
    this.updateCheckBoxData(true, 2, "Cricket");
    this.updateCheckBoxData(false, 2, "Tennis");
    this.updateCheckBoxData(true, 2, "Football");
    this.updateInputData("text", 1);
    this.updateInputData("text area", 2);
    this.updateInputData("98989", 5);
    this.updateInputData("98989787634", 5);
    this.updateInputData("Male", 3);
    this.validateFields();
    this.updateInputData("", 3);
    this.validateFields();
    this.getInputType("radio");
    this.submitInputData({});
  }

  prepareInputData(responseData) {
    let temp = [];
    let submitBtn = false;

    responseData.forEach((item) => {
      switch (item.field_type?.toLowerCase()) {
        case "submit button":
          submitBtn = true;
          break;

        case "input":
        case "textarea":
        case "select":
          const isRequired =
            ["1", "true", true].includes(item.validations?.is_required) || false;

          const minLen = 1;

          const maxLen = 400;

          const type = this.getInputType(item.field_type?.toLowerCase());

          const errorMessage = item.label;

          temp.push({
            ...item,
            key: item.label,
            isRequired,
            validationRegex: item.validations?.validation_regex || "",
            minLen,
            maxLen,
            type,
            errorMessage,
            value: null,
          });
          break;
      }
    });

    this.setInputData(temp, submitBtn);

  }

  setInputData(tempData, submitBtn) {
    this.setState({
      inputData: tempData,
      showSubmitBtn: submitBtn,
    });
  }

  getInputType(type) {
    if (type?.includes("radio")) {
      return configJSON.typeRadio;
    }
    return type;
  }

  updateCheckBoxData(boolVal: boolean, index: number, idStr: string) {
    let temp = this.state.inputData;
    let prevValue = temp[index].value;

    temp[index].value = prevValue;
    temp[index].validationError = !prevValue || prevValue.length === 0;

    this.setState({
      inputData: temp,
    });
  }

  updateInputData(value: string, index: number) {
    let temp = this.state.inputData;
    temp[index].value = value;

    this.setState({
      inputData: temp,
    });

  }

  validateFields() {
    let temp = this.state.inputData;
    let errorCount = 0;
    let leadFields = [];

    this.setState({
      inputData: temp,
    });

    if (errorCount === 0) {
      this.submitInputData({
        form_id: this.state.formId,
        form_data: leadFields,
      });
    }
  }

  submitInputData = (leadData) => {
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postInputDataApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postInputData
      );

      const httpBody = {
        lead: leadData,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getAllInputFields = async () => {
    try {
      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllInputsApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getInputDataEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  submitNewLead = async (values: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewDealApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewLeadEndPoint
    );

    let entity_value = this.state.dealRegistraton ? 'deal' : 'lead';
    let deal_status = this.state.dealRegistraton ? values.deal_status.value : '';
    let phone_number = this.state.dealRegistraton ? "" : `${values.lead_country_code.countryValue}${values.phone_number}`;
    let currency = this.state.dealRegistraton ? '' : values.currency.label;

    const httpBody =
      this.state.createNewCompany ? {
        "data":
        {
          "entity_value": entity_value,
          "first_name": values.first_name,
          "last_name": values.last_name,
          "customer_email": values.customer_email,
          "deal_name": values.deal_name,
          "conversion_value": 1.1,
          "commission_percentage": 1.1,
          "deal_status": deal_status,
          "description": values.description,
          "source": values.lead_source,
          "deal_value": values.deal_value,
          "phone_number": phone_number,
          "deal_currency": currency
        },

        "company_params": {
          "company_name": values.company_name.label,
          "city": '',
          "state": '',
          "country": values.country.label,
          "company_website": values.company_website,
          "industry": values.industry.value,
          "created_by_partner": "true"
        },
      } :
        {
          "data":
          {
            "entity_value": entity_value,
            "first_name": values.first_name,
            "last_name": values.last_name,
            "customer_email": values.customer_email,
            "deal_name": values.deal_name,
            "conversion_value": 1.1,
            "commission_percentage": 1.1,
            "deal_status": deal_status,
            "description": values.description,
            "source": values.lead_source,
            "deal_value": values.deal_value,
            "phone_number": phone_number,
            "deal_curency": currency

          },
          'company_id': values.company_name.value
        }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  createNewCompany = () => {
    this.setState({
      createNewCompany: true
    })
  }

  navigateToNewDeal() {
    this.props.navigation.navigate('LeadManagementWeb')
  }
  navigateToViewDeal() {
    this.props.navigation.navigate('LeadDashboard')
  }
  getLeads = async (type: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllLeadsApiId = requestMessage.messageId;
    if (type === 'normal') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}`
      );
    }
    if (type === 'filter') {
      if (this.state.companyId != "") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}&company_name=${this.state.query}&company_name_check=true`
        );

      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}`
        );
      }
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getCompanies = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCompaniesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getIndustry = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllIndustryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  selectSingleLead(leadId: any) {
    localStorage.setItem('leadId', leadId)
    this.props.navigation.navigate("LeadManagementUpdate")
  }
  getSingleLead(leadId: any) {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleLeadApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSingleLeadEndPoint
    );


    const httpBody =
    {
      "data":
      {
        id: leadId
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSearchValue(text) {
    this.setState({ companyId: "", searchValue: text, query: text, currentPage: "" }, () => {
      this.getLeads('filter');
    })
  }


  toggleSelectAll = (e) => {
    const { leadInfo } = this.state;
    if (e.target.checked) {
      const allLeadIds = leadInfo.leads.data.map((row) => row);
      this.setState({ selectedRows: allLeadIds, selectAll: true });
    } else {
      this.setState({ selectedRows: [], selectAll: false });
    }
  };

  toggleSelectRow = (row) => {
    const { selectedRows } = this.state;
    const rowId = row;

    if (selectedRows.includes(rowId)) {
      this.setState({
        selectedRows: selectedRows.filter((id) => id !== rowId),
        selectAll: false,
      });
    } else {
      this.setState({
        selectedRows: [...selectedRows, rowId],
      });
    }

  };
  updateLead = (values) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateLeadPointApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewLeadEndPoint
    );


    const httpBody =
    {
      "data":
      {
        "id": values.id,
        "first_name": values.first_name,
        "last_name": values.last_name,
        "customer_email": values.customer_email,
        "deal_name": values.deal_name,
        "conversion_value": 1.1,
        "commission_percentage": 1.1,
        "deal_status": values.deal_status.value,
        "description": values.description,
        "source": values.lead_source,
        "deal_value": values.deal_value,
        "deal_currency": '$',
        "closed_won_at": getCloseDealDateFormat(values.deal_status.value),
      },
      "company_params": {
        "company_name": values.company_name.label,
        "city": '',
        "country": values.country?.label,
        "state": '',
        "company_website": values.company_website,
        "industry": values.industry?.value
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  confirmationDeleteModal() {
    this.setState({
      deleteModal: true
    })
  }
  closeDeleteModal() {
    this.setState({
      deleteModal: false
    })
  }
  deleteLeads() {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteLeadPointApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewLeadEndPoint
    );


    const httpBody =
    {
      "data":
      {
        "id": this.state.selectedRows.map((item: any) => {
          return item.id
        })
      }

    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSortClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ sortMenu: event.currentTarget })
    }
  };

  handleSortClose = (text: any) => {
    this.setState({ sortValue: text, sortMenu: null }, () => {
      this.getLeads('filter')
    })
  };
  handleSelectClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ selectMenu: event.currentTarget })
    }
  };

  handleSelectClose = (text: any) => {
    this.setState({ stagesValue: text, selectMenu: null, currentPage: 1 }, () => {
      this.getLeads('filter')
    });
  };
  handleMenuClose = () => {
    this.setState({ sortMenu: null, selectMenu: null });
  }
  downloadDealsCSV() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadCSVLeadID = requestMessage.messageId;

    if (this.state.companyId != "") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?page=${this.state.currentPage}&per_page=${this.state.perPage}&sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}&company_name=${this.state.query}&company_name_check=true&download_csv=true`
      );

    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createNewLeadEndPoint}?sort=${this.state.sortValue}&query=${this.state.searchValue}&status=${this.state.stagesValue}&download_csv=true`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getPageCount() {
    return this.state.leadInfo.leads.data.length
  }
  getIndeterminate() {
    return this.state.selectedRows.length > 0 &&
      this.state.selectedRows.length < this.state.companyArray.length
  }
  updateAccordionStatus = (sectionName, isOpen) => {
    this.setState((prevState) => ({
      accordionStatus: {
        ...prevState.accordionStatus,
        [sectionName]: isOpen,
      },
    }));
  };
  updateAllAccordionStatus = () => {
    this.setState({
      accordionStatus: {
        deal: true,
        customer: true,
        other: true,
        leadCustomer: true,
        leadInformation: true
      },
    })
  }
  getAccoridtionStatus = (type: any, errors: any) => {
    if (this.state.accordionStatus[type] || Object.keys(errors).length > 0) {
      return true; // Remove the period between accordionStatus and [type]
    } else {
      return false;
    }
  }
  getDealValudId(data, str1, str2) {
    return data === '' ? str1 : str2
  }
  getLeadCreatevalidation(value, str1, str2) {
    return value ? str1 : str2
  }
  openConfirmationModal() {
    this.setState({
      confirmationModal: true,
    })
  }
  openUploadCsvModal() {
    this.setState({
      uploadCsvModal: true,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {}
    })
  }
  closeConfirmationModal() {
    this.setState({
      confirmationModal: false,
      dealRegistraton: false,
      leadRegistraion: true
    })
  }
  closeUploadCsvModal() {
    this.setState({
      uploadCsvModal: false,
      isUploaded: false,
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
      uploadLeadsCount: 0,
      selectedFile: {}
    })
  }
  handleDragEnter = (e) => {
    e.preventDefault();
    this.setState({ isDragging: true });
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState({ isDragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    this.setState({
      isDragging: false,
      fileSizeError: null,
      fileTypeError: null,
    });

    const files = e.dataTransfer.files;
    const validFiles = [];

    for (const file of files) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Get the file extension

      if (fileExtension === 'csv') {
        // Check file size (max 5 MB)
        if (file.size <= 5 * 1024 * 1024) { // 5 MB in bytes
          validFiles.push(file);
        } else {
          this.setState({
            fileTypeError: configJSON.fileSizeError,
          });
        }
      } else {
        this.setState({
          fileTypeError: configJSON.fileTypeError,
        });
      }
    }

    if (validFiles.length > 0) {
      this.setState({
        selectedFile: validFiles[0]
      })
      this.handleCheckCsv(validFiles[0]);
    } else {
      this.setState({
        fileTypeError: configJSON.fileTypeError,
      });
    }
  };



  handleFileInputChange = (e) => {
    this.setState({
      fileSizeError: null,
      fileTypeError: null,
    });

    const files = e.target.files;

    for (const file of files) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Get the file extension

      if (fileExtension === 'csv') {
        // Check file size (max 5 MB)
        if (file.size <= 5 * 1024 * 1024) { // 5 MB in bytes
          // The file is both a valid .csv or .xls file and within the size limit
          this.setState({
            selectedFile: file
          })
          this.handleCheckCsv(file);
        } else {
          // File size is too large, provide feedback to the user
          this.setState({
            fileTypeError: configJSON.fileSizeError,
          });
        }
      } else {
        // Invalid file type, you can provide feedback to the user
        this.setState({
          fileTypeError: configJSON.fileTypeError,
        });
      }
    }
  };
  handleCheckCsv = async (file: any) => {
    this.setState({ isLoading: true });

    const header = {
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkCsvApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.csvCheckEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData()
    formData.append('[file]', file);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  handleUploadCsv = async () => {
    this.setState({ isLoading: true });

    const header = {
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadCsvApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.csvUploadEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData()
    formData.append('[file]', this.state.selectedFile);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  navigateToLeadDashboard = () => {
    this.props.navigation.navigate('LeadDashboard')
  }
  sampleCsvDownload = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sampleCsvDownloadApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sampleCsvDownloadEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSampleClick = () => {
    console.log('CSV link clicked!');
  }
  handleFocus = (type: any) => {
    switch (type) {
      case 'deal_status':
        this.setState({
          dealStatusFocus: true
        });
        break;
      case 'company_name':
        this.setState({
          companyNameFocus: true
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: true
        });
        break;
      case 'country':
        this.setState({
          countryFocus: true
        });
        break;
      case 'currency':
        this.setState({
          currencyFocus: true
        });
        break;
      default:
    }

  }
  handleBlur = (type: any) => {
    switch (type) {
      case 'deal_status':
        this.setState({
          dealStatusFocus: false
        });
        break;
      case 'company_name':
        this.setState({
          companyNameFocus: false
        });
        break;
      case 'industry':
        this.setState({
          industryFocus: false
        });
        break;
      case 'country':
        this.setState({
          countryFocus: false
        });
        break;
      case 'currency':
        this.setState({
          currencyFocus: false
        });
        break;
      default:
    }
  }
  getCompanyDeals = (data: any) => {
    const { id, company_name } = data.attributes.company_lead
    this.setState({
      query: company_name,
      searchValue: company_name,
      companyId: id
    }, () => {
      this.getLeads('filter');
    })
  }
  getCountries = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCountryiesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}

let successResponse = {
  data: {
    inputFields: [
      {
        errorMessage: "First Name is required",
        field_type: "Text",
        form_field_id: 1,
        isRequired: false,
        key: "First Name",
        label: "First Name",
        maxLen: 400,
        minLen: 1,
        placeholder: "First Name",
        type: "text",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
          min_length: null,
          max_length: null,
        },
      },
      {
        errorMessage: "Description is required",
        field_type: "Textarea",
        form_field_id: 2,
        isRequired: false,
        key: "Description",
        label: "Description",
        maxLen: 400,
        minLen: 1,
        placeholder: "Description",
        type: "textarea",
        validationRegex: "",
        value: "",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Favourite Sports is required",
        field_type: "Checkbox",
        form_field_id: 3,
        isRequired: false,
        key: "Favourite Sports",
        label: "Favourite Sports",
        maxLen: 400,
        minLen: 1,
        type: "checkbox",
        validationRegex: "",
        value: null,
        validations: {
          is_required: "0",
        },
        options: [
          {
            label: "Cricket",
            name: "Cricket",
            value: "Cricket",
            option_id: "Cricket",
          },
          {
            label: "Football",
            name: "Football",
            value: "Football",
            option_id: "Football",
          },
          {
            label: "Tennis",
            name: "Tennis",
            value: "Tennis",
            option_id: "Tennis",
          },
        ],
      },
      {
        errorMessage: "Gender is required",
        field_type: "Radio button",
        form_field_id: 4,
        isRequired: true,
        key: "Gender",
        label: "Gender",
        maxLen: 400,
        minLen: 1,
        type: "radio",
        validationRegex: "",
        value: [],
        validations: {
          is_required: "0",
        },
        options: [
          { label: "Male", name: "Male", value: "Male", option_id: "Male" },
          {
            label: "Female",
            name: "Female",
            value: "Female",
            option_id: "Female",
          },
          { label: "Other", name: "Other", value: "Other", option_id: "Other" },
        ],
      },
      {
        form_field_id: 5,
        field_type: "Submit button",
        value: "Submit",
        type: "submit button",
        validations: {
          is_required: "0",
        },
      },
      {
        errorMessage: "Phone Number is required",
        field_type: "Text",
        form_field_id: 6,
        isRequired: true,
        key: "Phone Number",
        label: "Phone Number",
        maxLen: 20,
        minLen: 1,
        placeholder: "Phone Number",
        type: "text",
        value: "",
        validationRegex:
          "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$",
        validations: {
          is_required: "1",
        },
      },
      {
        lable: "Gender",
        key: "gender",
        isRequired: true,
        validationRegex: "",
        value: "",
        errorMessage: "Please select gender",
        validationError: false,
        minLen: 5,
        maxLen: 16,
        type: "dropdown",
        options: [
          {
            id: 1,
            label: "Male",
          },
          {
            id: 2,
            label: "Female",
          },
          {
            id: 3,
            label: "Other",
          },
        ],
      },
      {
        action_email_id: 2,
        field_type: "Action email",
        action_email: "alex@gmail.com",
        type: "action email",
      },
    ],
  },
};
