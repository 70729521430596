import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Formik, Field, ErrorMessage } from 'formik';
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

import Select from 'react-select'
import { customer, others, accordition_icon, deals } from "./assets";
import { dealRegistrationSchema } from "../../../components/src/validations";
import { dealStatusArray, getDateFormat, getLabelByValue, getSelectLabel, getToken, getNullValues } from "../../../components/src/commonUsage";
import UpdateLeadSkeleton from "./UpdateLeadSkeleton";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class LeadManagementUpdate extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getSingleLead(getToken('leadId'))
    this.getCompanies()
    this.getIndustry()
    this.getCountries()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { id, deal_name, deal_value, deal_currency, deal_status, customer_email, first_name, last_name, source, description, conversion_value, updated_at, closed_won_at } = this.state.singleLeadInfo
    let { company_name, country, company_website, industry } = this.state.singleLeadInfo?.company_lead
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar backPage={{ name: deal_name || `${first_name} ${last_name}`, path: 'LeadDashboard' }} />
              {
                this.state.isLoading ?
                  // <Loader loading={this.state.isLoading} contentLoader={true} />
                  <UpdateLeadSkeleton />
                  :
                  <Box style={webStyles.mainContainer}>
                    <Grid>
                      <Typography className="deal-heading-main" style={{ marginBottom: '24px', display: 'flex', alignItems: 'center' }}>
                        {deal_name} <label className="deal-status-label">{getLabelByValue(deal_status)}</label>
                      </Typography>
                    </Grid>
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          id: id,
                          deal_name: deal_name,
                          deal_value: deal_value,
                          deal_currency: { value: deal_currency, label: deal_currency },
                          deal_status: { value: deal_status, label: getLabelByValue(deal_status) },
                          customer_email: customer_email,
                          company_name: { value: company_name, label: company_name },
                          first_name: first_name,
                          last_name: last_name,
                          lead_source: source,
                          description: description,
                          country: { value: country, label: country },
                          company_website: company_website,
                          industry: { value: industry, label: industry },
                        }}
                        validationSchema={dealRegistrationSchema}
                        onSubmit={(values) => {
                          this.updateLead(values)
                        }}
                      >
                        {(formik) => (
                          <form onSubmit={formik.handleSubmit}>
                            <Box className="my-store-row-wrap">
                              <Box className="my-store-form-col">
                                <Box className="deal-update-form">
                                  <Box className="deal-form-container">
                                    <Accordion data-test-id="accordionDeal" expanded={this.getAccoridtionStatus('deal', formik.errors)}
                                      onChange={(_, isOpen) =>
                                        this.updateAccordionStatus('deal', isOpen)
                                      } className="deal-form-accordition">
                                      <AccordionSummary
                                        expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={deals} /></label>Deal information</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box className="deal-form-internal-container">
                                          <Box>
                                            <TextField
                                              name="deal_name"
                                              size="small"
                                              variant="filled"
                                              label="Deal name*"
                                              fullWidth
                                              value={formik.values.deal_name}
                                              onChange={
                                                formik.handleChange
                                              }

                                              error={this.getFormikError(formik.touched.deal_name, formik.errors.deal_name)}
                                              helperText={this.getFormikHelperText(formik.touched.deal_name, formik.errors.deal_name)}
                                            />
                                          </Box>
                                          <Box className="mt-1">
                                            <Grid className="grid-even-space">
                                              <Grid item xs={12} md={12}>
                                                <Box className="input-with-adornment">
                                                  <Field name="deal_currency">
                                                    {(field: any) => (
                                                      <Select
                                                        {...field}
                                                        name="deal_currency"
                                                        styles={currencyStyles}
                                                        placeholder="$"
                                                        options={currencies}
                                                        components={{
                                                          IndicatorSeparator: () => null,
                                                          DropdownIndicator: () => null,
                                                        }}
                                                        className="currency-dropdown"
                                                        isDisabled
                                                      // onChange={(option) =>
                                                      //   formik.setFieldValue('deal_currency', option)
                                                      // }
                                                      />
                                                    )}
                                                  </Field>
                                                  <TextField
                                                    size="small"
                                                    fullWidth
                                                    name="deal_value"
                                                    id={formik.values.deal_value === '' ? "deal-value-normal-textfield" : "deal-value-selected-textfield"}
                                                    label="Deal value"
                                                    variant="filled"
                                                    value={formik.values.deal_value}
                                                    onChange={formik.handleChange}
                                                  />
                                                </Box>
                                                <ErrorMessage className="deal-error-text" name="deal_value" component="div" />
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <Box className="custom-search-dropdown-wrapper is-focused">
                                                  <Field name="deal_status">
                                                    {(field: any) => (
                                                      <>
                                                        {
                                                          getSelectLabel(field.field.value, 'select-label', 'Deal Status*')
                                                        }
                                                        <Select
                                                          {...field}
                                                          value={formik.values.deal_status}
                                                          options={dealStatusArray}
                                                          name="deal_status"
                                                          styles={selectStyle}
                                                          className="custom-search-select"
                                                          components={{
                                                            IndicatorSeparator: () => null
                                                          }}
                                                          onChange={(option) =>
                                                            formik.setFieldValue('deal_status', option)
                                                          }
                                                          label="Deal status*"
                                                        />
                                                      </>

                                                    )}
                                                  </Field>
                                                  <ErrorMessage className="deal-error-text" name="deal_status" component="div" />
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                  <Box className="deal-form-container">
                                    <Accordion data-test-id="accordionCustomer" expanded={this.getAccoridtionStatus('customer', formik.errors)}
                                      onChange={(_, isOpen) =>
                                        this.updateAccordionStatus('customer', isOpen)
                                      } className="deal-form-accordition">
                                      <AccordionSummary
                                        expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                      >
                                        <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={customer} /></label>Customer information</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box className="deal-form-internal-container">
                                          <Box>
                                            <TextField
                                              size="small"
                                              variant="filled"
                                              label="Customer email"
                                              fullWidth
                                              name='customer_email'
                                              value={formik.values.customer_email}
                                              onChange={
                                                formik.handleChange
                                              }

                                              error={this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)}
                                              helperText={this.getFormikHelperText(formik.touched.customer_email, formik.errors.customer_email)}
                                            />
                                          </Box>
                                          <Box className="mt-1">
                                            <Grid className="grid-even-space">
                                              <Grid item xs={12} md={12}>
                                                <TextField
                                                  name="first_name"
                                                  size="small"

                                                  fullWidth
                                                  id="input-with-icon-textfield"
                                                  label="First name"
                                                  variant="filled"
                                                  value={formik.values.first_name}
                                                  onChange={
                                                    formik.handleChange
                                                  }

                                                  error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                                                  helperText={this.getFormikHelperText(formik.touched.first_name, formik.errors.first_name)}
                                                />
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <TextField
                                                  name="last_name"
                                                  size="small"
                                                  fullWidth
                                                  id="input-with-icon-textfield"
                                                  label="Last name"
                                                  variant="filled"
                                                  value={formik.values.last_name}
                                                  onChange={
                                                    formik.handleChange
                                                  }

                                                  error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                                                  helperText={this.getFormikHelperText(formik.touched.last_name, formik.errors.last_name)}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Box>
                                          <Box className="mt-1">
                                            <Box className="custom-search-dropdown-wrapper is-focused">
                                              <Field name="company_name">
                                                {(field: any) => (
                                                  <>
                                                    {
                                                      getSelectLabel(field.field.value, 'update-company-label', 'Company Name')
                                                    }
                                                    <Select
                                                      {...field}
                                                      value={formik.values.company_name}
                                                      options={this.state.companyArray.map((item: any) => {
                                                        return { value: item.id, label: item.name }
                                                      })}
                                                      name="company_name"
                                                      styles={selectStyle}
                                                      components={{
                                                        IndicatorSeparator: () => null
                                                      }}
                                                      className="mt-1 custom-search-select"
                                                      label="Company name"
                                                      onChange={(option) => {
                                                        this.setState({
                                                          createNewCompany: false
                                                        })
                                                        formik.setFieldValue('company_name', option)
                                                      }
                                                      }
                                                      noOptionsMessage={({ inputValue }) => {
                                                        return (
                                                          !this.state.createNewCompany ?
                                                            <div style={{ cursor: 'pointer', textAlign: 'left', display: 'flex', alignItems: 'center' }} onClick={() => {
                                                              let data = {
                                                                value: inputValue, label: inputValue
                                                              }
                                                              formik.setFieldValue('company_name', data)

                                                              this.createNewCompany()
                                                            }}>Create : <Typography className="new-company-text">{inputValue}</Typography></div>
                                                            :
                                                            null
                                                        )
                                                      }
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </Field>
                                              <ErrorMessage className="deal-error-text" name="company_name" component="div" />
                                            </Box>
                                          </Box>
                                          {this.state.createNewCompany &&
                                            <>
                                              <Box className="mt-1">
                                                <Box className="custom-search-dropdown-wrapper is-focused">
                                                  <Field name="industry">
                                                    {(field: any) => (
                                                      <>
                                                        {
                                                          getSelectLabel(field.field.value, 'select-label', 'Industry')
                                                        }
                                                        <Select
                                                          value={formik.values.industry}
                                                          {...field}
                                                          options={this.state.industryArray.map((item: any) => {
                                                            return { value: item, label: item }
                                                          })}
                                                          name="industry"
                                                          styles={selectStyle}
                                                          components={{
                                                            IndicatorSeparator: () => null
                                                          }}
                                                          className="custom-search-select width-98"
                                                          label="Industry"
                                                          onChange={(option: any) => {
                                                            formik.setFieldValue("industry", option)
                                                          }
                                                          }
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                  <ErrorMessage className="deal-error-text" name="industry" component="div" />
                                                </Box>
                                              </Box>
                                              <Box className="mt-1">
                                                <TextField
                                                  size="small"
                                                  variant="filled"
                                                  label="Company website"
                                                  fullWidth
                                                  name='company_website'
                                                  value={formik.values.company_website}
                                                  onChange={
                                                    formik.handleChange
                                                  }

                                                  error={this.getFormikError(formik.touched.company_website, formik.errors.company_website)}
                                                  helperText={this.getFormikHelperText(formik.touched.company_website, formik.errors.company_website)}
                                                />
                                              </Box>
                                              <Box className="mt-1">
                                                <Box className="custom-search-dropdown-wrapper is-focused">
                                                  <Field name="country">
                                                    {(field: any) => (
                                                      <>
                                                        {
                                                          getSelectLabel(field.field.value, 'select-label', 'Country')
                                                        }
                                                        <Select
                                                          value={formik.values.country}
                                                          {...field}
                                                          options={this.state.countryData}
                                                          name="country"
                                                          styles={selectStyle}
                                                          components={{
                                                            IndicatorSeparator: () => null
                                                          }}
                                                          className="custom-search-select width-98"
                                                          label="Country"
                                                          onChange={(option: any) => {
                                                            formik.setFieldValue("country", option)
                                                          }
                                                          }
                                                        />
                                                      </>
                                                    )}
                                                  </Field>
                                                  <ErrorMessage className="deal-error-text" name="country" component="div" />
                                                </Box>
                                              </Box>
                                            </>
                                          }

                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                  <Box className="deal-form-container">
                                    <Accordion data-test-id="accordionOther" expanded={this.getAccoridtionStatus('other', formik.errors)}
                                      onChange={(_, isOpen) =>
                                        this.updateAccordionStatus('other', isOpen)
                                      } className="deal-form-accordition">
                                      <AccordionSummary
                                        expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                      >
                                        <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={others} /></label>Other information</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box className="deal-form-internal-container">
                                          <Box>
                                            <TextField
                                              size="small"
                                              variant="filled"
                                              label="Lead source"
                                              fullWidth
                                              name='lead_source'
                                              value={formik.values.lead_source}
                                              onChange={
                                                formik.handleChange
                                              }

                                              error={this.getFormikError(formik.touched.lead_source, formik.errors.lead_source)}
                                              helperText={this.getFormikHelperText(formik.touched.lead_source, formik.errors.lead_source)}
                                            /> </Box>
                                          <Box className="mt-1">
                                            <TextField
                                              type="textarea"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end" style={{ position: 'absolute', top: 20, right: 10 }}>
                                                    {`${formik.values.description?.length ?? 0}/100`}
                                                  </InputAdornment>
                                                ),
                                              }}
                                              inputProps={{
                                                maxLength: 100 // Use "maxLength" instead of "maxlength" for HTML attribute.
                                              }}
                                              name="description"
                                              fullWidth
                                              variant="filled"
                                              label="Description"
                                              multiline
                                              value={formik.values.description}
                                              onChange={formik.handleChange}
                                              error={this.getFormikError(formik.touched.description, formik.errors.description)}
                                              helperText={this.getFormikHelperText(formik.touched.description, formik.errors.description)}
                                            />
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                  {
                                    this.state.errorMessage !== null &&
                                    <Box>
                                      <Typography className="deal-error-text">
                                        {this.state.errorMessage}
                                      </Typography>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              <Box className="my-store-detail-col update-container">
                                <Box className="deal-info-wrap">
                                  <Box className="deal-update-save-form-1">
                                    <Box className="w-100">
                                      <Grid className="grid-button-space">
                                        <Grid item xs={12} md={12}>
                                          <Button
                                            fullWidth
                                            size="small"
                                            data-test-id={"btnBackDashboard"}
                                            variant="contained"
                                            type="button"
                                            onClick={() => this.navigateToLeadDashboard()}
                                            className="update-cancel-button">
                                            <Typography className="update-cancel-btn-text">
                                              Cancel
                                            </Typography>
                                          </Button>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Button
                                            fullWidth
                                            size="small"
                                            data-test-id={"btnViewDeal"}
                                            variant="contained"
                                            type="submit"
                                            className="update-save-changes">
                                            <Typography className="save-changes-btn-text">
                                              Save Changes
                                            </Typography>
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                  <Box className="deal-update-save-form-2">
                                    <Box className="deal-title-block">
                                      <Box>
                                        <Typography className="update-heading">{deal_name} <label className="deal-status-label">{getLabelByValue(deal_status)}</label></Typography>
                                      </Box>
                                      <Grid className="update-deal-value-container">
                                        <Grid container className="deal-row">
                                          <Grid item xs={5} md={5} className="left-col">
                                            <Typography className="update-heading-deal-value">Deal Value</Typography>
                                            <Typography className="update-heading-deal-price">{deal_currency}{deal_value}</Typography>
                                          </Grid>
                                          <Grid item xs={7} md={7} className="right-col">
                                            <Typography className="update-heading-deal-value">Commission earned</Typography>
                                            <Typography className="update-heading-deal-price">{conversion_value}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="info-content-row" style={{ borderTop: 'none', paddingTop: '0' }}>
                                      <Box className="update-deal-value-container">
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Last Updated</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{getDateFormat(updated_at)}</Typography>
                                          </Box>
                                        </Box>
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Closed date</Typography>
                                          </Box>
                                          <Box className="value">
                                            {console.log(closed_won_at)}
                                            <Typography className="update-heading-deal-info">{getNullValues(getDateFormat(closed_won_at))}</Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="info-content-row">
                                      <Box className="update-deal-value-container">
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Customer</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{first_name} {last_name}</Typography>
                                          </Box>
                                        </Box>
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Customer Email</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{customer_email}</Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="info-content-row">
                                      <Box className="update-deal-value-container">
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Company</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{company_name}</Typography>
                                          </Box>
                                        </Box>
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Website</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{company_website}</Typography>
                                          </Box>
                                        </Box>
                                        <Box className="inner-content-row">
                                          <Box className="title">
                                            <Typography className="update-heading-deal-value">Country</Typography>
                                          </Box>
                                          <Box className="value">
                                            <Typography className="update-heading-deal-info">{country}</Typography>
                                          </Box>
                                        </Box>
                                        {/* <Box className="inner-content-row">
                                    <Box className="title">
                                      <Typography className="update-heading-deal-value">City</Typography>
                                    </Box>
                                    <Box className="value">
                                      <Typography className="update-heading-deal-info"></Typography>
                                    </Box>
                                  </Box> */}
                                      </Box>
                                    </Box>
                                    <Box className="info-content-row description-row">
                                      <Box className="inner-content-row">
                                        <Box className="title">
                                          <Typography className="update-heading-deal-value">Description</Typography>
                                        </Box>
                                        <Box className="value">
                                          <Typography className="update-description">
                                            {description}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </Box>
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
};
const currencyStyles = {
  control: (styles: any) => ({
    ...styles,
    width: '66px',
    boxShadow: 'none',
    height: 60,
    borderRadius: '3px 0px 0px 3px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
    backgroundColor: "#F8F9FA",
  })
};
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 61,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};
// Customizable Area End
