import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getDashboardName, getMemberErrorMessages, getStartAndEndDates, getToken, getYearMonthDate, removeAllToken, setToken } from "../../../components/src/commonUsage";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  emptyState: boolean;
  calendarMenu: any;
  calanderValue: any;
  dateRangeValue: any;
  openCalendar: any;
  addMemberModal: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: {
        "partner": {
          name: "",
          partner_image: {
            url: ""
          }
        },
        "total_deals": [
          {
            "name": "total leads",
            "count": 0
          }
        ],
        "leads_graph": [
          {
            "name": "closed won deals",
            "count": 0
          },
          {
            "name": "total prospecting",
            "count": 0
          },
          {
            "name": "total engagement",
            "count": 0
          },
          {
            "name": "closed lost total",
            "count": 0
          },
          {
            "name": "in progress total",
            "count": 0
          }
        ],
        "conversion_graph": [
        ]
      },
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      emptyState: true,
      calendarMenu: null,
      calanderValue: 'All Time',
      dateRangeValue: [new Date(), new Date()],
      openCalendar: false,
      addMemberModal: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    if (getToken('partner_role') === 'member') {
      if (getToken('modal_open') === "false") {
        this.setState({
          addMemberModal: true,
        })
        setToken('modal_open', "false")
      }
    }
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({ loading: true })
    const webHeader = {
      token: getToken('authToken')
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (getMemberErrorMessages(webResponseJson)) {
        removeAllToken();
        this.props.navigation.navigate('EmailAccountLoginBlock');
      } else if (!webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson,
            loading: false,
            openCalendar: false,
            emptyState: webResponseJson.empty_state,
          });
          setToken('profile_name', getDashboardName(webResponseJson))
        }
      } else {
        this.setState({
          emptyState: true,
          loading: false
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCalendarClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ calendarMenu: event.currentTarget });
    }
  };
  handleCalendarClose = () => {
    this.setState({ calendarMenu: null });
  }
  handleCalendarSelect = (text: any) => {
    if (text === "Custom...") {
      this.setState({
        openCalendar: true,
        calanderValue: text,
        calendarMenu: null,
        dateRangeValue: [new Date(), new Date()],
      })
    } else if (text === "All Time") {
      this.setState({ calanderValue: text, calendarMenu: null, openCalendar: false }, () => {
        this.getDashboardData()
      });
    } else {
      this.setState({ calanderValue: text, calendarMenu: null, dateRangeValue: getStartAndEndDates(text), openCalendar: false }, () => {
        this.getFilteredData()
      });
    }
  };
  handleDateChange = (item: any) => {
    this.setState({ dateRangeValue: item });
  };
  getFilteredData = () => {
    this.setState({ loading: true })
    const webHeader = {
      token: getToken('authToken')
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.dashboardGetUrl}?start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  closeCalander = () => {
    this.setState({
      openCalendar: false
    })
  }
  navigateToLeadManagement = () => {
    this.props.navigation.navigate(
      "LeadManagementWeb"
    )
  }
  navigateToLeadDashboard = () => {
    this.props.navigation.navigate('LeadDashboard')
  }
  navigateToCommissionDashboard = () => {
    this.props.navigation.navigate('Commissionreports2')
  }
  openWelcomeModal() {
    this.setState({
      addMemberModal: true
    })
  }
  closeModal() {
    this.setState({
      addMemberModal: false,

    })
  }
  // Customizable Area End
}
