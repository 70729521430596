import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { searchIcon, plusIcon, downArrow, closeIcon, enterIcon, closeGrayIcon, sendIcon, infoIcon, emptyImg, infoPurpleIcon } from "./assets";
import { Formik } from 'formik';
import { emailValidationSchema } from "../../../components/src/validations";
import { capitalizeFirstLetter, getIntitals, getToken } from "../../../components/src/commonUsage";
// Customizable Area End

import Contractmanagement2Controller, {
  Props
} from "./Contractmanagement2Controller";
import TableSkeleton from "../../../components/src/TableSkeleton";

export default class MyTeams extends Contractmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getMembers();
  }
  getSearchText = () => {
    return this.state.searchQuery !== null ?
      <Typography className="search-results-text">{this.state.membersData.partner_member.data.length} results found for "{this.state.searchQuery}"</Typography>
      :
      <Typography className="search-results-text">{this.state.membersData.partner_member.data.length} member(s)</Typography>;
  }
  renderMemberName = (item: any, type: any) => {
    let fullName = "";

    if (type === 'initials') {
      const firstName = item.first_name !== null ? item.first_name : "";
      const lastName = item.last_name !== null ? item.last_name : "";
      fullName = `${firstName} ${lastName}`;
    } else {
      fullName = item.name !== null ? item.name : "-";
    }

    return capitalizeFirstLetter(fullName);
  }
  // Customizable Area End

  render() {
    const { memberMenu } = this.state;
    const memberOpen = Boolean(memberMenu);
    const { inviteMenu } = this.state;
    const inviteOpen = Boolean(inviteMenu);
    return (
      // Customizable Area Start
      <Box>
        <Box className="dashboard-nav-container">
          <Box className="dashboard-heading">
            <Box>
              <Typography className="company-heading-main">
                My Team Members
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid className="filter-block-wrapper">
          <Box className="table-search-text filter-inner-wrap">
            <Box className="filter-left-block">
              {this.getSearchText()}
            </Box>
            <Box className="filter-right-block" >
              <Box className="right-inner-wrap">
                <Box className="search-block-wrapper">
                  <TextField
                    data-test-id="search-input"
                    className="search-input"
                    fullWidth
                    size="small"
                    placeholder="Search Members"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={searchIcon} className="ml-10" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => this.handleSearch(e.target.value)}
                  />
                </Box>
                <Box className="add-btn-wrap">
                  <Button data-test-id="open-modal" className="custom-btn light-btn btn-sm text-sm" onClick={() => this.openMemberModal()}><img src={plusIcon} alt="plus" />Add members</Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="table-outer-wrapper">
            {
              this.state.isLoading ?
                // <Loader loading={this.state.isLoading} contentLoader={true} />
                <>
                  <TableSkeleton />
                </>
                :
                <>
                  {
                    this.state.membersData.partner_member.data.length !== 0 ?
                      <Box>
                        <TableContainer className="lead-table member-table">
                          <Table aria-label="simple table">
                            <TableHead style={{ background: '#f8f9fa' }}>
                              <TableRow style={{ height: '40px' }}>
                                <TableCell className="lead-table-heading first-col" style={{ width: '332px' }}>FULL NAME</TableCell>
                                <TableCell className="lead-table-heading" style={{ width: '420px' }}>EMAIL</TableCell>
                                <TableCell className="lead-table-heading"></TableCell>
                                <TableCell className="lead-table-heading right-align" style={{ width: '150px' }}>
                                  <div className="head-title">
                                    USER ACCESS
                                    <Tooltip title="Update the team member role or remove the access." placement="top" arrow>
                                      <i>
                                        <img src={infoIcon} />
                                      </i>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                this.state.membersData.partner_member.data.map((item: any, index: any) => {
                                  return <TableRow className="row-container" key={index}>
                                    <TableCell className="lead-table-data-row first-col flex-row">
                                      <Avatar className="avtar-block avtar-md gray-bg" src={`${item.attributes.partner_image.url}?${Date.now()}`}>{getIntitals(this.renderMemberName(item.attributes, 'initials'))}</Avatar>
                                      <Typography>
                                        {this.renderMemberName(item.attributes, 'name')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="lead-table-data-row">
                                      {item.attributes.email}
                                    </TableCell>
                                    <TableCell className="lead-table-data-row">
                                      {
                                        item.attributes.status === false &&
                                        <div>
                                          <Button
                                            className="invite-button"
                                            id="member-button"
                                            aria-controls={this.getControlOptions(inviteOpen, 'sort-menu')}
                                            aria-haspopup="true"
                                            aria-expanded={this.getControlOptions(inviteOpen, 'true')}
                                            onClick={(event) => {
                                              this.handleInviteClick(event, item.attributes.id)
                                            }}
                                          >
                                            <Typography
                                              className="text-transform sort-value-text">Invited
                                              <label className="dropdown-arrow"> <img src={downArrow} /></label>
                                            </Typography>
                                          </Button>
                                          <Menu
                                            id="invite-menu"
                                            aria-labelledby="invite-button"
                                            anchorEl={inviteMenu}
                                            open={inviteOpen}
                                            onClose={this.handleMenuClose}
                                            className="invite-menu-dropdown"
                                            style={{
                                              top: '35px',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left',
                                            }}
                                          >
                                            <MenuItem data-test-id="resend-invite" onClick={() => this.handleResendInvite()} className="menu-item-text"><img src={sendIcon} alt="send" />Resend Invite</MenuItem>
                                          </Menu>
                                        </div>
                                      }
                                    </TableCell>
                                    <TableCell className="lead-table-data-row right-align">

                                      <div>
                                        <Button
                                          className="member-role-button"
                                          id="member-button"
                                          aria-controls={this.getControlOptions(memberOpen, 'sort-menu')}
                                          aria-haspopup="true"
                                          aria-expanded={this.getControlOptions(memberOpen, 'true')}
                                          onClick={(event) => {
                                            this.handleMemberClick(event, item)
                                          }}
                                          disabled={getToken('partner_id') !== 'null' && getToken('partner_role') === 'admin' && item.id === getToken('user_id')}
                                        >
                                          {
                                            getToken('partner_id') !== 'null' ?
                                              <Typography
                                                className="text-transform sort-value-text">{item.attributes.role.name}
                                                {
                                                  getToken('partner_role') === 'admin' && item.id !== getToken('user_id') &&
                                                  <label className="dropdown-arrow"> <img src={downArrow} /></label>
                                                }
                                              </Typography>
                                              :
                                              <Typography
                                                className="text-transform sort-value-text">{item.attributes.role.name}
                                                <label className="dropdown-arrow"> <img src={downArrow} /></label>
                                              </Typography>
                                          }
                                        </Button>
                                        <Menu
                                          id="sort-menu"
                                          aria-labelledby="sort-button"
                                          anchorEl={memberMenu}
                                          open={memberOpen}
                                          onClose={this.handleMenuClose}
                                          className="role-menu-dropdown"
                                          style={{
                                            top: '35px',
                                          }}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                        >
                                          <MenuItem id='changeMember' className="menu-item-text" onClick={() => this.changeRole('member')}>Member</MenuItem>
                                          <MenuItem id='changeAdmin' className="menu-item-text" onClick={() => this.changeRole('admin')}>Admin</MenuItem>
                                          <MenuItem id='removeMember' className="menu-item-text" onClick={() => {
                                            this.confirmationDeleteModal()

                                          }}>Remove</MenuItem>
                                        </Menu>
                                      </div>



                                    </TableCell>
                                  </TableRow>
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      :
                      <Box className="empty-data-block">
                        <Box className="empty-content">
                          <Box className="image-block">
                            <img src={emptyImg} alt="empty-data" />
                          </Box>
                          <Box className="content">
                            <Typography className="title">No Member found!</Typography>
                          </Box>
                        </Box>
                      </Box>
                  }
                </>
            }
          </Box>
        </Grid>
        <Modal
          open={this.state.addMemberModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm add-member-modal"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id="btn-close-invite-modal1" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Box className="title-block">
                <Typography variant="h4">
                  Add Members
                </Typography>
                <Typography>You can add upto 25 members at once</Typography>
              </Box>
            </Box>
            <Box>
              <form>
                <Box className="form-inner-wrap">
                  <Box className="form-block-wrap">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        email: ''
                      }}
                      validationSchema={emailValidationSchema}
                      onSubmit={(values, { resetForm }) => {
                        this.handleAddEmail(values.email);
                        resetForm();
                      }}
                    >
                      {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                          <TextField
                            name="email"
                            size="small"
                            variant="filled"
                            data-test-id="txtInputEmail"
                            type="text"
                            label="Invite people or Emails"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            fullWidth={true}
                            disabled={this.state.emailArray.length >= 25}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment

                                  className="share-pointer"
                                  position="end"
                                  onClick={() => this.state.emailArray.length < 25 && formik.handleSubmit()}

                                >
                                  <img src={enterIcon} alt="Enter Icon" /> <span className="text">Enter</span>
                                </InputAdornment>
                              ),
                            }}
                            error={this.getFormikError(formik.touched.email, formik.errors.email) || this.state.errorMessage}
                            helperText={this.getFormikHelperText(formik.touched.email, formik.errors.email)}
                          />
                          {
                            this.state.errorMessage !== null &&
                            <Box>
                              <Typography className="error-text">
                                {this.state.errorMessage}
                              </Typography>
                            </Box>
                          }
                        </form>
                      )}
                    </Formik>
                  </Box>
                  {this.state.emailArray.length >= 25 &&
                    <Box className="member-adding-info">
                      <Box className="info-inner-wrap">
                        <Box className="icon-block">
                          <img src={infoPurpleIcon} alt="info-icon"></img>
                        </Box>
                        <Box className="content-block">
                          <Typography><span>25 members were added,</span> to include additional members, please try adding them in groups of 25 at a time.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  <Box className="member-listing-block">
                    <Typography className="listing-title">{`Members to be added (${this.state.emailArray.length}/25)`}</Typography>
                    {
                      this.state.emailArray.length === 0 ?
                        <Typography className="no-data-text">No one selected yet.</Typography>
                        :
                        <Box className="member-list-wrap">
                          <ul className="member-list">
                            {/* <li><p>ganya.sopha@siammakro.com<span>Member</span></p><IconButton><img src={closeGrayIcon}></img></IconButton></li> */}
                            {
                              this.state.emailArray.map((item: any) => {
                                return <li><p>{item.email}</p><IconButton onClick={() => this.removeEmail(item.email)}><img src={closeGrayIcon}></img></IconButton></li>
                              })
                            }
                          </ul>
                        </Box>
                    }
                  </Box>
                  <Box className="btn-wrap">
                    <Button
                      data-test-id="btn-close-invite-modal2"
                      onClick={() => this.closeModal()}
                      fullWidth
                      size="small"
                      variant="contained"
                      type="button"
                      className="custom-btn">Cancel</Button>
                    <Button
                      disabled={this.state.emailArray.length === 0}
                      data-test-id="btn-invite-member"
                      onClick={() => this.inviteMembers()}
                      fullWidth
                      size="small"
                      variant="contained"
                      type="button"
                      className="custom-btn green-btn">
                      Invite members
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.deleteModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-xsm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id="btn-close-invite-modal3" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Typography variant="h4">
                Are you sure you would like to remove?
              </Typography>
              <Typography>This will revoke all the access of {this.state.memberName} from this account. You can always invite them back if needed.</Typography>
            </Box>
            <Box className="mt-1 btn-wrap">

              <Button
                data-test-id="btn-close-invite-modal4"
                onClick={() => {
                  this.closeModal()
                  this.handleMenuClose()
                }}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn">
                Cancel
              </Button>
              <Button
                onClick={() => this.changeRole('remove')}
                data-test-id="btnRemoveDeals"
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn red-btn">
                Remove
              </Button>
            </Box>
          </Box >
        </Modal >
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

};
// Customizable Area End
