export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchIcon = require("../assets/search.svg");
export const plusIcon = require("../assets/plus.svg");
export const closeIcon = require("../assets/close.svg");
export const closeGrayIcon = require("../assets/close_gray.svg");
export const downArrow = require("../assets/down_arrow.svg");
export const enterIcon = require("../assets/enter_ic.svg");
export const sendIcon = require("../assets/send_ic.svg");
export const infoIcon = require("../assets/info_ic.svg");
export const emptyImg = require("../assets/empty_data.svg");
export const infoPurpleIcon = require("../assets/info_ic_purple.svg")
export const bankDetailIcon = require("../assets/bank_detail.svg")
export const settingsIcon = require("../assets/settings_ic.svg")
export const plusLgIcon = require("../assets/plus-lg.svg")
export const emptyContract = require("../assets/empty_contract.svg")
export const down_fall = require('../assets/down_fall.svg')
