import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getErrorResponse, getMemberErrorMessages, getToken, removeAllToken } from "../../../components/src/commonUsage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  memberMenu: any;
  selectInviteMenu: any;
  inviteMenu: any;
  selectMenu: any;
  addMemberModal: boolean;
  deleteModal: any;
  emailArray: any;
  errorMessage: any;
  isLoading: any;
  membersData: any;
  selectedMemberId: any;
  memberName: any;
  profileData: any;
  searchQuery: any;
  addAccountModal: any;
  editAccountModal: any;
  deleteAccountModal: any;
  currencyDetails: boolean;
  countryData: any;
  currencyData: any;
  currencySelectData: any;
  account_type: any;
  selectedBankData: any;
  bank_details: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Contractmanagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiInviteMemebrCallID: string = ""
  apiGetMemebrCallID: string = ""
  apiResendEmailCallID: string = ""
  apiChangeRoleCallID: string = ""
  profileApiCallId: string = "";
  countryApiCallId: string = "";
  saveBankDetailsApiCallId: string = "";
  getBankApiCallId: string = "";
  updateBankDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      memberMenu: null,
      selectInviteMenu: null,
      inviteMenu: null,
      selectMenu: null,
      addMemberModal: false,
      deleteModal: false,
      emailArray: [],
      errorMessage: null,
      isLoading: false,
      membersData: {
        partner_member: {
          data: []
        }
      },
      selectedMemberId: null,
      memberName: null,
      profileData: {
        "partner": {
          "data": {
            "id": "",
            "type": "",
            "attributes": {
              "id": 0,
              "name": "",
              "email": "",
              "department": "",
              "website": "",
              "partner_image": {
                "url": ""
              },
              "country": "",
              "location": "",
              "partner_contract": {
                "commission": "",
                "commencement_date": "",
                "partnership_type": ""
              },
              "contract_link": {
                "url": ""
              }
            }
          }
        }
      },
      searchQuery: null,
      addAccountModal: false,
      editAccountModal: false,
      deleteAccountModal: false,
      currencyDetails: false,
      countryData: [],
      currencyData: [],
      currencySelectData: null,
      account_type: 'savings',
      selectedBankData: {},
      bank_details: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.setState({
      isLoading: false,
    });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson) === true) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.apiInviteMemebrCallID:
            this.handleApiResponse()
            break;
          case this.apiGetMemebrCallID:
            this.handleResponse(responseJson, false, '', 'membersData')
            break;
          case this.apiResendEmailCallID:
            this.handleApiResponse()
            break;
          case this.apiChangeRoleCallID:
            this.handleApiResponse()
            break;
          case this.profileApiCallId:
            this.handleResponse(responseJson, false, '', 'profileData');
            break;
          case this.countryApiCallId:
            this.setState({
              countryData: responseJson.map((item: any) => ({
                label: item.name,
                value: item.name,
                ...item
              })),
              currencyData: responseJson.filter((item: any) => item.currency_label !== undefined).map((item: any) => ({
                label: item.currency_label,
                value: item.currency_label,
                ...item
              }))
            })
            break;
          case this.saveBankDetailsApiCallId:
          case this.updateBankDetailsApiCallId:
            if (!responseJson.errors) {
              this.closeModal()
              this.getBankDetails()
            } else {
              this.setState({
                errorMessage: getErrorResponse(responseJson)
              });
            }
            break;
          case this.getBankApiCallId:
            this.setState({ bank_details: responseJson.bank_details })
            break;
          default:
            // Handle the default case here
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleApiResponse = () => {
    this.getMembers()
    this.closeModal()
  }
  handleResponse(responseJson: any, navigaion: any, path: any, statedata: any) {
    if (!responseJson.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState);
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });

    }
  }
  handleMemberClick = (event: any, item: any) => {
    if (event) {
      event.persist();
      const memberName =
        `${item.attributes.first_name ? item.attributes.first_name : ''} ${item.attributes.last_name ? item.attributes.last_name : ''}`.trim() ||
        item.attributes.email;
      this.setState({ memberMenu: event.currentTarget, selectedMemberId: item.attributes.id, memberName: memberName })

    }
  };
  handleMenuClose = () => {
    this.setState({ memberMenu: null, inviteMenu: null });
  }
  handleInviteClick = (event: any, id: any) => {
    if (event) {
      event.persist();
      this.setState({ inviteMenu: event.currentTarget, selectedMemberId: id })
    }
  };
  openMemberModal() {
    this.setState({
      addMemberModal: true,
      emailArray: []
    })
  }
  closeModal() {
    this.setState({
      addMemberModal: false,
      deleteModal: false,
      selectedMemberId: null,
      addAccountModal: false,
      editAccountModal: false,
      deleteAccountModal: false,
      currencyDetails: false,
      selectedBankData: {},
      errorMessage: null
    })
  }
  confirmationDeleteModal() {
    this.setState({
      deleteModal: true,

    })
  }
  handleAddEmail(email: any) {
    //@ts-ignore
    this.setState((prevState) => {
      const updatedEmailArray = [...prevState.emailArray];
      const emailExists = updatedEmailArray.some((entry) => entry.email === email);

      if (emailExists) {
        const errorMessage = "Email is already taken";
        return { errorMessage };
      }

      if (updatedEmailArray.length < 25) {
        updatedEmailArray.push({ email });
      }
      const errorMessage = '';
      return { emailArray: updatedEmailArray, errorMessage };
    });
  }
  getFormikError(error: any, erros: any) {
    return error && Boolean(erros)
  }
  getFormikHelperText(error: any, erros: any) {
    return error && erros
  }
  removeEmail = (email: any) => {
    this.setState((prevState) => {
      const updatedEmailArray = [...prevState.emailArray];
      const existingIndex = updatedEmailArray.findIndex((entry) => entry.email === email);

      if (existingIndex !== -1) {
        updatedEmailArray.splice(existingIndex, 1);
      }
      return { emailArray: updatedEmailArray };
    });
  };
  inviteMembers(): boolean {
    this.setState({
      isLoading: true
    })
    this.closeModal()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken('authToken')
    };

    const httpBody = {
      data: this.state.emailArray,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInviteMemebrCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteEmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getMembers(): boolean {
    this.setState({
      isLoading: true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken('authToken')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMemebrCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMembersApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleResendInvite(): boolean {
    this.setState({
      isLoading: true,
      inviteMenu: false
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken('authToken')
    };

    const httpBody = {
      data: {
        id: this.state.selectedMemberId
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResendEmailCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendEmailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  changeRole(type: any): boolean {
    this.setState({ memberMenu: false, isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken('authToken')
    };

    const httpBody = {
      "role_partner": type,
      "member_id": this.state.selectedMemberId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeRoleCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeRoleApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getControlOptions = (type: any, value: any): any => {
    return type ? value : undefined;
  };
  handleSearch(value: any) {
    this.setState({
      isLoading: true,
      searchQuery: value.length === 0 ? null : value
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken('authToken')
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMemebrCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMembersApiEndPoint}?search=${value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }
  getProfileInfo = () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.parnterShowEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addAccountModal() {
    this.setState({
      currencyDetails: true
    })
  }
  editAccountModal(data: any) {
    this.setState({
      selectedBankData: data,
      editAccountModal: true,
      account_type: data.account_type
    })
  }
  deleteAccountModal(data: any) {
    this.setState({
      deleteAccountModal: true,
      selectedBankData: data
    })
  }
  getCountries = async () => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleAccountsType = (value: any) => {
    this.setState({
      account_type: value
    })
  }
  saveAccountDetails = (values: any) => {

    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      token: getToken('authToken'),
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveBankDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveBankDetailsEndPoint
    );
    const formData = {
      "data":
      {
        "country_region": this.state.currencySelectData.country.value,
        "currency_type": this.state.currencySelectData.currency.label,
        "account_holder_name": values.account_holder_name,
        "ifsc_code": values.ifsc_code,
        "account_number": values.account_number,
        "account_type": this.state.account_type.toLowerCase(),
        "status": "pending",
        "bank_name": values.bank_name,
        "ifsc_type": values.ifsc_type.value
      }
    }

    console.log(formData)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getBankDetails = async () => {
    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBankApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBankDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  deleteBankAccount = () => {
    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      token: getToken('authToken'),
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBankDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBankDeatailsEndPoint
    );
    const formData = {

      "id": this.state.selectedBankData.id,
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateAccountDetails = (values: any, status: any) => {

    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      token: getToken('authToken'),
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBankDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBankDeatailsEndPoint
    );
    const inactiveData = {
      "data":
      {
        "bank_details_id": values.id,
        "account_holder_name": values.account_holder_name,
        "ifsc_code": values.ifsc_code,
        "account_type": this.state.account_type,
        "account_number": values.account_number,
        "bank_name": values.bank_name,
        "ifsc_type": values.ifsc_type.value
      }
    }

    const activeData = {
      "data":
      {
        "bank_details_id": values.id,
        "account_holder_name": values.account_holder_name,
        "ifsc_code": values.ifsc_code,
        "account_type": this.state.account_type,
        "bank_name": values.bank_name,
        "ifsc_type": values.ifsc_type.value
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(status === 'active' ? activeData : inactiveData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setDefaultAccount = (values: any) => {

    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      token: getToken('authToken'),
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBankDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBankDeatailsEndPoint
    );
    const formData = {
      "data":
      {
        "bank_details_id": values.id,
        "status": 'active'
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
