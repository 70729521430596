import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField, InputAdornment, Modal, Menu, MenuItem, IconButton, Tooltip, Avatar } from "@material-ui/core";
import { conversion, leads, up_arrow } from "../../dashboard/src/assets";
import { closeIcon, dealsMain, deleteIcon, downloadIcon, emptySvg, leadsGraphBars, searchIcon } from "./assets";
import { downArrow } from "../../assetsdashboard/src/assets";
import { capitalizeFirstLetter, getCheckBoxIcon, getColorsForStatus, getCurrencyValue, getDateFormat, getFullName, getIntitals, getLabelByValue, getNullValues, getToken } from "../../../components/src/commonUsage";
import Pagination from '@material-ui/lab/Pagination';
import { CSVLink } from "react-csv";
import BoxSkeleton from "../../../components/src/BoxSkeleton";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class LeadDashboard extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if (getToken('company_id') !== null) {
      this.setState({
        query: getToken('company_name'),
        searchValue: getToken('company_name'),
        companyId: getToken('company_id')
      }, () => {
        this.getLeads('filter');
      })
    } else {
      this.getLeads('normal')
    }
  }
  renderLeadInfo = () => {
    return <> {
      this.state.leadInfo.total_deals > 0 ?
        <Box>
          {
            this.state.leadInfo.leads.data.length > 0 ?
              <>
                <TableContainer className="lead-table" component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead style={{ background: '#f8f9fa' }}>
                      <TableRow>
                        <TableCell style={{ width: '48px' }} className="lead-table-heading checkbox-cell">
                          <Checkbox
                            data-test-id={"btnCheckAllLeads"}
                            size="small"
                            indeterminate={
                              this.getIndeterminate()
                            }
                            icon={getCheckBoxIcon(false)}
                            checkedIcon={getCheckBoxIcon(true)}
                            checked={this.state.selectAll}
                            onChange={(e) => this.toggleSelectAll(e)}
                          />
                        </TableCell>
                        <TableCell style={{ width: '200px' }} className="lead-table-heading">LEAD NAME</TableCell>
                        <TableCell style={{ width: '180px' }} className="lead-table-heading">COMPANY</TableCell>
                        {
                          getToken('partner_role') === 'admin' &&
                          <TableCell style={{ width: '180px' }} className="lead-table-heading">CREATED BY</TableCell>
                        }
                        <TableCell style={{ width: '164px' }} className="lead-table-heading">STAGE</TableCell>
                        <TableCell style={{ width: '120px' }} className="lead-table-heading">VALUE</TableCell>
                        <TableCell style={{ width: '146px' }} className="lead-table-heading">LAST UPDATED</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.leadInfo.leads.data.map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell className="lead-table-data-company checkbox-cell">
                            <Checkbox
                              data-test-id={`selectSingleDeal${index}`}
                              size="small"
                              icon={getCheckBoxIcon(false)}
                              checkedIcon={getCheckBoxIcon(true)}
                              onClick={() => this.toggleSelectRow(row)}
                              checked={this.state.selectedRows.includes(row)}
                            />
                          </TableCell>
                          <TableCell className="lead-table-data-company">
                            <span className="purple-text" onClick={() => this.selectSingleLead(row.id)}>{getFullName(row.attributes.first_name, row.attributes.last_name)}</span>
                          </TableCell>
                          <TableCell className="lead-table-data-company"><span className="purple-text" onClick={() => this.getCompanyDeals(row)}>{getNullValues(capitalizeFirstLetter(row.attributes.company_lead?.company_name))}</span></TableCell>
                          {
                            getToken('partner_role') === 'admin' &&
                            <TableCell className="lead-table-data-row flex-row">{row.attributes.partner_image_url.name !== null ? <><Avatar src={row.attributes.partner_image_url.url} className="avtar-block purple-bg">{getIntitals(row.attributes.partner_image_url.name)}</Avatar>{capitalizeFirstLetter(row.attributes.partner_image_url.name)}</> : "-"}</TableCell>
                          }
                          <TableCell className="lead-table-data-row">
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                display: 'inline-flex',
                                padding: '2px 8px 1px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '32px',
                                border: `1px solid ${getColorsForStatus(row.attributes.deal_status).border}`,
                                background: getColorsForStatus(row.attributes.deal_status).surface,
                                width: 'fit-content',
                              }}
                            ><label
                              style={{
                                color: getColorsForStatus(row.attributes.deal_status).main,
                                textAlign: 'center',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '15px',
                                letterSpacing: '0.12px',
                                whiteSpace: 'nowrap'
                              }}>{getColorsForStatus(row.attributes.deal_status).label}</label></Typography>
                          </TableCell>
                          <TableCell className="lead-table-data-row">{getCurrencyValue(row.attributes.deal_value)}</TableCell>
                          <TableCell className="lead-table-data-row">{getDateFormat(row.attributes.updated_at)}</TableCell>
                       </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {this.state.leadInfo.total_deals > 10 &&
                  <Box className="table-page-info-block">
                    <Box className="pagination-label-wrapper">
                      <Typography className="pagination-text">
                        Showing {this.getPageCount()} out of {this.state.leadInfo.meta.total_count} results
                      </Typography>
                    </Box>
                    <Box className="pagination-wrapper">
                      <Pagination count={this.state.leadInfo.meta.total_pages} onChange={(event, page) => this.handlePageChange(page)} page={this.state.currentPage || 1} shape="rounded" />
                    </Box>
                  </Box>
                }
              </>
              :
              <Box className="">
                <Typography className="no-data-found">No deals found</Typography>
              </Box>
          }
        </Box>
        :
        <Box className="empty-table-wrapper">
          <Table>
            <TableHead style={{ background: '#f8f9fa' }}>
              <TableRow>
                <TableCell className="lead-table-heading" style={{ width: '48px' }}>
                  <Checkbox
                    size="small"
                    icon={getCheckBoxIcon(false)}
                    checkedIcon={getCheckBoxIcon(true)}
                  />
                </TableCell>
                <TableCell style={{ width: '200px' }} className="lead-table-heading">LEAD NAME</TableCell>
                <TableCell style={{ width: '180px' }} className="lead-table-heading">COMPANY</TableCell>
                {
                  getToken('partner_role') === 'admin' &&
                  <TableCell style={{ width: '180px' }} className="lead-table-heading">CREATED BY</TableCell>
                }
                <TableCell style={{ width: '164px' }} className="lead-table-heading">STAGE</TableCell>
                <TableCell style={{ width: '120px' }} className="lead-table-heading">VALUE</TableCell>
                <TableCell style={{ width: '146px' }} className="lead-table-heading">LAST UPDATED</TableCell>
             </TableRow>
            </TableHead>
          </Table>
          <Box className="empty-data-block">
            <Box className="empty-content">
              <Box className="empty-img">
                <img src={emptySvg} />
              </Box>
              <Box className="content">
                <Typography className="title-sm">You haven't earned any certifications yet.</Typography>
                <Typography className="empty-sub-text">Get started by adding your first deal to this dashboard.</Typography>
                <Box className="empty-btn">
                  <Button data-test-id={"btnEmptyNavigateDeal"} onClick={() => this.navigateToNewDeal()} className="custom-btn gray-btn btn-sm">
                    <Typography className="empty-button-text">
                      Register new deal
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    }
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortMenu, selectMenu } = this.state;
    const sortDealOpen = Boolean(sortMenu);
    const selectDealOpen = Boolean(selectMenu);
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Deals and leads', path: 'LeadDashboard' }} />
              <Box style={webStyles.mainContainer}>
                <Box className="lead-dashboard-heading dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="deal-heading-main">
                        Manage Deals
                      </Typography>
                      <Typography className="deal-heading-sub">
                        Your Deals and Leads are listed in the dashboard below.
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={webStyles.rightAlign}>
                    <Button
                      data-test-id={"btnRegisteredDealLead"}
                      variant="contained"
                      color="primary"
                      className="heading-button"
                      onClick={() => this.navigateToNewDeal()}
                    >
                      <Typography className="deal-lead-btn-text">

                        Register Deal/Lead
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                {
                  this.state.leadInfo.total_deals > 0 ?
                    <Grid className="lead-section-one">
                      <Grid item xs={12} md={12} container>
                        <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                          <Box>
                            <Typography className="grid-text-heading">Open Deals</Typography>
                            <Box className="grid-counts">
                              <Grid item xs={12} md={12} container>
                                <Grid item className="image-block">
                                  <img src={dealsMain} alt="Deals" />
                                </Grid>

                                <Grid item className="grid-count-wrap">
                                  <Typography className="grid-count-text">
                                    {this.state.leadInfo.open_deals}
                                    {/* <label className="grid-up-text">10%</label><label><img src={up_arrow} /></label> */}
                                  </Typography>
                                  <Typography className="grid-count-sub-text">
                                    Last 28 days
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                          <Box>
                            <Typography className="grid-text-heading">Total Leads Gathered</Typography>
                          </Box>
                          <Box className="grid-counts">
                            <Grid item xs={12} md={12} container>
                              <Grid item className="image-block">
                                <img src={leads} alt="Deals" />
                              </Grid>
                              <Grid item className="grid-count-wrap">
                                <Typography className="grid-count-text">
                                  {this.state.leadInfo.total_deals}
                                  {/* <label className="grid-up-text">10%</label><label><img src={up_arrow} /></label> */}
                                </Typography>
                                <Typography className="grid-count-sub-text">
                                  Last 28 days
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                          <Box>
                            <Typography className="grid-text-heading">Conversion Value</Typography>
                          </Box>
                          <Box className="grid-counts">
                            <Grid item xs={12} md={12} container>
                              <Grid item className="image-block">
                                <img src={conversion} alt="Deals" />
                              </Grid>
                              <Grid item className="grid-count-wrap">
                                <Typography className="grid-count-text">
                                  $200K
                                  {/* <label className="grid-up-text">10%</label><label><img src={up_arrow} /></label> */}
                                </Typography>
                                <Typography className="grid-count-sub-text">
                                  Last 28 days
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    :
                    <>
                      {
                        this.state.isLoading ?
                          <>
                            <BoxSkeleton></BoxSkeleton>
                          </>
                          :
                          <Grid className="lead-section-one">
                            <Grid item xs={12} md={12} container className="empty-lead-block">
                              <Grid className="lead-section-one-grid-empty-one" item xs={6} md={6}>
                                <Box>
                                  <Typography className="grid-text-empty-heading">Get started by adding deals</Typography>
                                </Box>
                                <Box>
                                  <Typography className="grid-text-empty-sub-heading">Add more deals to this dashboad and soon the data will be generated for you to view</Typography>
                                </Box>
                              </Grid>
                              <Grid className="lead-section-one-grid-empty-two" item xs={6} md={6}>
                                <img src={leadsGraphBars} />
                              </Grid>
                            </Grid>
                          </Grid>
                      }
                    </>
                }
                <Grid>
                  <Box className="filter-block-wrapper">
                    {
                      this.state.selectedRows.length > 0 ?
                        <Box style={webStyles.centerAlign} className="filter-inner-wrap" >
                          <Box className="filter-left-block">
                            <Typography className="searchbar-text">{this.state.selectedRows.length} Deals are selected</Typography>
                          </Box>
                          <Box className="filter-right-block">
                            <Box className="delete-icon" onClick={() => this.confirmationDeleteModal()}>
                              <Button disableRipple className="remove-deals-btn" data-test-id={"openDeleteModal"} onClick={() => this.confirmationDeleteModal()}>
                                <Typography className="delete-deal-text">
                                  <img className="mr-8" src={deleteIcon} /> <label style={webStyles.click}>Remove {this.state.selectedRows.length} Deals</label>
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box style={webStyles.centerAlign} className="filter-inner-wrap">
                          <Box className="filter-left-block">
                            {
                              this.state.query.length > 0 ?
                                <>
                                  <Typography className="search-results-text">{`Search results for "${this.state.query}"`}</Typography>

                                </>
                                :
                                <>
                                  <div className="display-flex">
                                    <label>
                                      <Typography className="open-deals-sub-text">
                                        {this.state.leadInfo.open_deals} Open Deal(s)
                                      </Typography>
                                    </label>
                                    <Button
                                      data-test-id={"btnSelectStagesFiter"}
                                      className="select-deal-button"
                                      id="select-button"
                                      aria-haspopup="true"
                                      onClick={(event) => this.handleSelectClick(event)}
                                    >
                                      <Typography className="select-stage-text">
                                        Stages:
                                      </Typography>
                                      <Typography className="asset-select-option-text stages-filter-text">
                                        {getLabelByValue(this.state.stagesValue)}<label className="dropdown-arrow"> <img src={downArrow} /></label>
                                      </Typography>
                                    </Button>
                                    <Menu
                                      style={{
                                        top: '52px',
                                        borderRadius: '8px',
                                      }}
                                      id="select-menu"
                                      aria-labelledby="select-button"
                                      anchorEl={selectMenu}
                                      open={selectDealOpen}
                                      onClose={this.handleMenuClose}
                                    >
                                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('All')}>All</MenuItem>
                                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('In progress')}>In progress</MenuItem>
                                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('Closed won')}>Closed won</MenuItem>
                                      <MenuItem className="menu-item-text" onClick={() => this.handleSelectClose('Closed lost')}>Closed lost</MenuItem>
                                    </Menu>
                                  </div>
                                </>
                            }
                          </Box>
                          <Box className="filter-right-block">
                            <Grid container style={webStyles.rightAlign}>
                              <Grid className="sortby-wrapper">
                                {
                                  this.state.query.length > 0 ?
                                    <>
                                    </>
                                    :
                                    <div>
                                      <Button
                                        data-test-id={"selectSortMenu"}
                                        type="button"
                                        className="sort-deal-button"
                                        id="sort-button"
                                        aria-haspopup="true"
                                        disableRipple
                                        onClick={(event) => this.handleSortClick(event)}
                                      >
                                        <Typography className="select-stage-text">
                                          Sort By
                                        </Typography>
                                        <Typography className="text-transform sort-value-text">
                                          {this.state.sortValue}
                                          <label className="dropdown-arrow">
                                            <img src={downArrow} /></label>
                                        </Typography>
                                      </Button>
                                      <Menu
                                        style={{
                                          top: '52px',
                                          borderRadius: '8px',
                                        }}
                                        id="sort-menu"
                                        aria-labelledby="sort-button"
                                        anchorEl={sortMenu}
                                        open={sortDealOpen}
                                        onClose={this.handleMenuClose}
                                        className="sort-popover"
                                      >
                                        <MenuItem id="sort-1" className="menu-item-text" onClick={() => this.handleSortClose("Latest")}>Latest</MenuItem>
                                        <MenuItem id="sort-2" className="menu-item-text" onClick={() => this.handleSortClose('Oldest')}>Oldest</MenuItem>
                                        <MenuItem id="sort-3" className="menu-item-text" onClick={() => this.handleSortClose('A-Z')}>A-Z</MenuItem>
                                        <MenuItem id="sort-4" className="menu-item-text" onClick={() => this.handleSortClose('Z-A')}>Z-A</MenuItem>
                                      </Menu>
                                    </div>
                                }
                              </Grid>
                              <Grid className="mr-16">
                                <div>
                                  <TextField
                                    data-test-id={"searchLeadText"}
                                    className="search-input"
                                    fullWidth
                                    size="small"
                                    placeholder="Search Deals"
                                    variant="outlined"
                                    value={this.state.searchValue}
                                    onChange={(e) => this.handleSearchValue(e.target.value)}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img src={searchIcon} className="ml-10" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid>
                                <Box>
                                  <Tooltip title="Download CSV" placement="top" arrow>
                                    <CSVLink className="download-icon" filename="leads" data={this.state.csvData.data} headers={this.state.csvData.headers.map((item: any) => {
                                      return { label: item.label, key: item.label }
                                    })}>
                                      <img src={downloadIcon} />
                                    </CSVLink>
                                  </Tooltip>
                                </Box >
                              </Grid >
                            </Grid >
                          </Box >
                        </Box >
                    }
                  </Box>
                  {
                    this.state.isLoading ?
                      // <Loader loading={this.state.isLoading} contentLoader={true} />
                      <>
                        <Box className="skeleton-wrapper">

                          <TableFilterSkeleton></TableFilterSkeleton>
                          <TableSkeleton></TableSkeleton>
                        </Box>
                      </>
                      :
                      <>
                        {this.renderLeadInfo()}
                      </>
                  }
                </Grid >
              </Box >
            </Grid >
          </Grid >
        </Grid >
        <Modal
          open={this.state.deleteModal}
          onClose={this.closeDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton data-test-id={"closeDeleteModal"} disableRipple className="close-btn" onClick={() => this.closeDeleteModal()}><img src={closeIcon} alt="close" /></IconButton>
              <Typography variant="h4">
                Are you sure you would like to remove {this.state.selectedRows.length}
                {" "}deals from the table?
              </Typography>
             </Box>
            <Box className="mt-1 btn-wrap">

              <Button
                data-test-id="btnCloseDeleteModal"
                onClick={() => this.closeDeleteModal()}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn">
                Cancel
              </Button>
              <Button
                data-test-id="btnRemoveDeals"
                onClick={() => this.deleteLeads()}
                fullWidth
                size="small"
                variant="contained"
                type="button"
                className="custom-btn red-btn">
                Remove
              </Button>
            </Box>
          </Box >
        </Modal >
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
  tableContainer: {
    marginTop: 16,
    marginBottom: 16
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  click: {
    cursor: 'pointer'
  }
};
const DeleteModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 3,
  p: 4,
};
// Customizable Area End
