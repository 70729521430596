// Customizable Area Start

import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface myProps {
  hasRightBlock ? : boolean;
  hasProfileIcon ? :boolean;
}

export default function TopNavSkeleton(props: myProps) {  
  return (
    <Box className="header-skeleton" style={{paddingBottom:'16px'}}>
      <Box className="header-inner-wrap-skeleton" style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Skeleton height={16} width={105} />
        <Box className="right-block" style={{display:'flex', alignItems:'center'}}>
          <Skeleton height={36} width={36} style={{marginRight:'21px'}} />
          <Skeleton height={26} width={26} />
        </Box>
      </Box>
    </Box>
  )
}
// Customizable Area End