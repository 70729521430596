import { ScriptableContext } from "chart.js";
import moment from "moment";
import React from 'react';
const unCheckIcon = require('./un-check.svg');
const checkIcon = require('./check.svg');
// error response
export const getErrorResponse = (responseJson: any) => {
    if (!responseJson || !responseJson.errors) {
        return;
    }
    const errors: [] = responseJson.errors

    let allerrors = '';
    errors?.map((object: any) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
            if (value.length > 0) {
                if (allerrors.length <= 0) {
                    allerrors = value;
                } else {
                    allerrors = `${allerrors}{\n}${value}`;
                }
            }
        });
    });
    return allerrors
}

export const getDateFormat = (date: any) => {
    if (date === "") {
        return null
    } else if (date === null) {
        return null
    }
    else {
        return moment(date).format('DD/MM/YYYY')
    }
}
export const getCloseDealDateFormat = (value: any) => {
    if (value.toLowerCase() === 'Closed won'.toLowerCase()) {
        return moment().format('YYYY-MM-DD')
    } else {
        return ""
    }
}

export const getYearMonthDate = (value: any) => {
    return moment(value).format('YYYY-MM-DD')
}

export const downloadFile = (fileUrl: any, fileName: any) => {
    // Make a GET request to the file URL
    fetch(fileUrl, {
        method: 'GET'
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create a URL for the blob data
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; // Set the desired file name
            a.style.display = 'none';

            // Add the anchor element to the DOM and trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up by revoking the object URL
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
        });
};
export const copyToClipboard = (text: any) => {
    const clipboard = navigator.clipboard;

    // Write the text to the clipboard
    clipboard.writeText(text)
        .then(() => {
            console.log("Text copied to clipboard: " + text);
        })
        .catch((err) => {
            console.error("Failed to copy text: ", err);
        });
}
export const getMonthDateFormat = (date: any) => {
    return moment(date).format('ll')
}
export const getCroppedImg = (image: any, crop: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        const errorMessage = 'Unable to obtain canvas context';
        console.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
    }

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    const errorMessage = 'Canvas is empty';
                    console.error(errorMessage);
                    reject(new Error(errorMessage));
                    return;
                }
                var imageFile = new File([blob], "profile_pic");
                resolve(imageFile);
            },
            'image/jpeg',
            1 // Quality (optional, 1 is maximum)
        );
    });
};
const colorsBasedonStatus = () => {
    return [
        {
            value: 'Prospecting',
            colors: {
                label: 'Prospecting',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Engagement',
            colors: {
                label: 'Engagement',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Closed Lost',
            colors: {
                label: 'Closed Lost',
                border: "#FFD7D7",
                main: "#E00000",
                surface: "#FFECEC"
            }
        },
        {
            value: 'Closed Won',
            colors: {
                label: 'Closed Won',
                border: "#B3DAC7",
                main: "#008243",
                surface: "#EBF5F0"
            }
        },
        {
            value: 'Open',
            colors: {
                label: 'Open',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Working',
            colors: {
                label: 'Working',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Qualified',
            colors: {
                label: 'Qualified',
                border: "#B3DAC7",
                main: "#008243",
                surface: "#EBF5F0"
            }
        },
        {
            value: 'Demo',
            colors: {
                label: 'Demo',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Disqualified',
            colors: {
                label: 'Disqualified',
                border: "#FFD7D7",
                main: "#E00000",
                surface: "#FFECEC"
            }
        },
        {
            value: 'In progress',
            colors: {
                label: 'In progress',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Processing',
            colors: {
                label: 'Processing',
                border: "#B6CFF3",
                main: "#0B5CD7",
                surface: "#ECF2FC"
            }
        },
        {
            value: 'Invoice pending',
            colors: {
                label: 'Invoice pending',
                border: "#FFCA99",
                main: "#653508",
                surface: "#FFF2E7"
            }
        },
        {
            value: 'Declined',
            colors: {
                label: 'Declined',
                border: "#FFD7D7",
                main: "#E00000",
                surface: "#FFECEC"
            }
        },
        {
            value: 'Paid',
            colors: {
                label: 'Paid',
                border: "#B3DAC7",
                main: "#008243",
                surface: "#EBF5F0"
            }
        }
    ]
}

export const dealStatusArray = [
    {
        value: 'Open',
        label: 'Open',
    },
    {
        value: 'In progress',
        label: 'In progress'
    },
    {
        value: 'Closed won',
        label: 'Closed won',
    },
    {
        value: 'Closed lost',
        label: 'Closed lost',
    },
    {
        value: 'Disqualified',
        label: 'Disqualified',
    },
    {
        value: 'Invoice pending',
        label: 'Invoice pending',
    },
    {
        value: 'Processing',
        label: 'Processing'
    },
    {
        value: 'Declined',
        label: 'Declined',
    },
    {
        value: 'Paid',
        label: 'Paid',
    },
    {
        value: 'Disqualified',
        label: 'Disqualified',
    }
];

export const getLabelByValue = (value: any) => {
    if (value === 'All') {
        return 'All';
    }

    const statusObject = dealStatusArray.find((item) => item.value === value);

    if (statusObject) {
        return statusObject.label;
    } else {
        return ''; // Handle the case where the value is not found
    }
}

// Function to get colors based on status value
export const getColorsForStatus = (statusValue: any) => {
    if (!statusValue) {
        // Return default colors if statusValue is null
        return {
            border: "#B6CFF3",
            main: "#0B5CD7",
            surface: "#ECF2FC",
            label: 'In progress'
        };
    }

    const colorsArray = colorsBasedonStatus(); // Assuming this function returns an array of objects
    const statusObject = colorsArray.find((item) => item.value.toLowerCase() === statusValue.toLowerCase());

    if (statusObject) {
        return statusObject.colors;
    } else {
        // Default colors if the status is not found
        return {
            border: "#000000",
            main: "#000000",
            surface: "#000000",
            label: 'In Progress'
        };
    }
};

export const getSelectLabel = (value: any, classData: any, label: any) => {
    if (value !== null) {
        return <label className={classData}>{label}</label>
    } else {
        return null
    }
}
export const getHideUnHidePassword = (value: any, hide: any, unhide: any) => {
    if (value) {
        return hide
    } else {
        return unhide
    }
}
export const getCheckBoxIcon = (value: any) => {
    if (value) {
        return <img src={checkIcon} />
    } else {
        return <img src={unCheckIcon} />
    }
}
export const getDashboardValues = (state: any, value: any) => {
    return state.find((item: any) => item.name === value)?.count;
}
export const donughtData = (values: any) => {
    const colors = [
        { color: "#00C853", key: "Closed won", value: "Closed won" },
        { color: "#6200EA", key: "Open", value: "Open" },
        { color: "#7C4DFF", key: "Disqualified", value: "Disqualified" },
        { color: "#FF8A80", key: "Closed lost", value: "Closed lost" },
        { color: "#8C9EFF", key: "In progress", value: "In progress" },
        // Add more colors as needed
    ];

    const labels = values.map((item: any) => item.name);
    const data = values.map((item: any) => item.count);

    // Map the keys to the respective colors
    const backgroundColors = labels.map((_: any, index: any) => {
        const valueKey = values[index].name;
        const colorObj = colors.find((color) => color.key === valueKey);
        return colorObj ? colorObj.color : "#000"; // Default to black if no match is found
    });

    return {
        labels: labels,
        datasets: [
            {
                label: '',
                data: data,
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                borderWidth: 1,
            },
        ]
    };
};
export const lineData = (values: any) => {

    const result = fillMissingDatesWithZeroCount(values)

    const labels = result.map((item: any) => item.date);
    const data = result.map((item: any) => item.count);

    return {
        labels: labels,
        datasets: [
            {
                // label: 'Dataset 1',
                data: data,
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
                    gradient.addColorStop(0, "#B388FF");
                    gradient.addColorStop(1, "rgba(179, 136, 255, 0.00)");
                    return gradient;
                },
                borderColor: "#6200EA",
                borderWidth: 1
            }
        ],
    };
};



export const calendarFilterArray = [
    {
        value: 'All Time',
        label: 'All Time',
    },
    {
        value: 'Last 30 days',
        label: 'Last 30 days',
    },
    {
        value: 'Last 60 days',
        label: 'Last 60 days',
    },

    {
        value: 'Last 90 days',
        label: 'Last 90 days',
    },
    {
        value: 'This week',
        label: 'This week',
    },
    {
        value: 'This year',
        label: 'This year'
    },
    {
        value: 'Custom...',
        label: 'Custom...',
    }
];

export const calendarComissionFilterArray = [
    {
        value: 'Last 3 months',
        label: 'Last 3 months',
    },
    {
        value: 'Last 6 months',
        label: 'Last 6 months',
    },
    {
        value: 'This month',
        label: 'This month',
    },
    {
        value: 'This year',
        label: 'This year',
    },
    {
        value: 'Last year',
        label: 'Last year',
    },
    {
        value: 'All Time',
        label: 'All Time',
    },
    {
        value: 'Custom...',
        label: 'Custom...',
    }
];

export const getCalendarByValue = (value: any) => {
    if (value === 'All') {
        return 'All';
    }

    const statusObject = calendarFilterArray.find((item) => item.value === value);

    if (statusObject) {
        return statusObject.label;
    } else {
        return ''; // Handle the case where the value is not found
    }
}

export const getCalendarByComissionValue = (value: any) => {
    if (value === 'All') {
        return 'All';
    }

    const statusObject = calendarComissionFilterArray.find((item) => item.value === value);

    if (statusObject) {
        return statusObject.label;
    } else {
        return ''; // Handle the case where the value is not found
    }
}

export const getStartAndEndDates = (timeFrame: any) => {
    const today = moment();
    let start, end;

    switch (timeFrame.toLowerCase()) {
        case "this year":
            start = today.clone().startOf("year");
            end = today.clone().endOf("year");
            break;
        case "last year":
            start = today.clone().subtract(1, 'year').startOf('year');
            end = today.clone().subtract(1, 'year').endOf('year');
            break;
        case "this week":
            start = today.clone().startOf("week");
            end = today.clone().endOf("week");
            break;
        case "this month":
            start = today.clone().startOf("month");
            end = today.clone().endOf("month");
            break;
        case "last 3 months":
            start = today.clone().subtract(3, "months");
            end = today.clone();
            break;
        case "last 6 months":
            start = today.clone().subtract(6, "months");
            end = today.clone();
            break;
        case "last 30 days":
            start = today.clone().subtract(30, "days").startOf("day");
            end = today.clone().endOf("day");
            break;
        case "last 60 days":
            start = today.clone().subtract(60, "days").startOf("day");
            end = today.clone().endOf("day");
            break;
        case "last 90 days":
            start = today.clone().subtract(90, "days").startOf("day");
            end = today.clone().endOf("day");
            break;
        default:
            start = start;
            end = end;
    }


    if (start && end) {
        return [
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD"),
        ];
    } else {
        return null; // Invalid time frame
    }
}

export const getCheckMarkIcon = (type: any, img: any, value: any) => {
    if (value.toLowerCase() === type.toLowerCase()) {
        return <img src={img} style={{ position: 'absolute', right: '16px' }} />
    }
}

export const getToken = (type: any) => {
    return typeof window !== 'undefined' ? localStorage.getItem(type) : null;
}
export const setToken = (type: any, value: any) => {
    localStorage.removeItem(type)
    localStorage.setItem(type, value)
}
export const getSanitizedValues = (value: any) => {
    return value === null ? "" : value
}
export const removeAllToken = () => {
    localStorage.clear()
}

export const removeToken = (value: any) => {
    localStorage.removeItem(value)
}

export const getWrapperClassName = (className: any, focusClassName: any, value: any, isFocused: any) => {
    if (isFocused || value) {
        className += " " + focusClassName;
    }
    else {
        className += '';
    }
    return className;
};

export const getCategoryName = (categoryValue: any, filterArray: any) => {
    for (const item of filterArray) {
        if (item.values.some((value: any) => value.value_name === categoryValue)) {
            return item.category_name;
        }
    }
    return null;
}

export const getCategoryValue = (categoryValue: any, filterArray: any) => {
    for (const item of filterArray) {
        if (item.values.some((value: any) => value.value_name === categoryValue)) {
            return item.category_value;
        }
    }
    return null;
}

export const mapValues = (arr1: any, arr2: any) => {
    const result: any = [];

    arr2.forEach((item: any) => {
        const [categoryKey, values]: any = Object.entries(item)[0];
        const categoryItem = arr1.find((category: any) => category.category_value === categoryKey);

        if (categoryItem) {
            const category_id = categoryItem.category_id;
            const value_ids = categoryItem.values
                .filter((value: any) => values.includes(value.value_name))
                .map((value: any) => value.value_id);

            result.push({ category_id, value_ids });
        }
    });

    return result;
}

export const generateFilterObject = (arr: any) => {
    return arr.map((item: any) => {
        const resultItem: any = {};

        if (typeof item.category_id === 'string' || typeof item.category_id === 'number') {
            // Check if it's "file_type"
            if (item.category_id === 'file_type') {
                resultItem[item.category_id] = {
                    [item.category_id]: item.value_ids.map((value_id: any) => value_id)
                };
            } else {
                resultItem.category_filter = {
                    [String(item.category_id)]: item.value_ids.map((value_id: any) => value_id)
                };
            }
        }

        return resultItem;
    });
}

export const getDashboardName = (data: any) => {
    if (data && data.partner) {
        if (data.partner.name) {
            return data.partner.name;
        } else if (data.partner.first_name && data.partner.last_name) {
            return `${data.partner.first_name} ${data.partner.last_name}`;
        }
    }

    return '';
};

export const getProfileName = (data: any) => {
    if (data) {
        if (data.name) {
            return data.name;
        } else if (data.first_name && data.last_name) {
            return `${data.first_name} ${data.last_name}`;
        }
    }

    return '';
};

const generateDateArray = (startDate: any, endDate: any) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
};

const fillMissingDatesWithZeroCount = (data: any) => {
    if (!data || data.length === 0) {
        return [];
    }

    // Find min and max dates
    const dates = data.map((entry: any) => new Date(entry.date));
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    // Generate all dates between min and max
    const allDates = generateDateArray(minDate, maxDate);

    // Fill in missing dates with count 0
    const result = allDates.map(date => {
        const existingEntry = data.find((entry: any) => entry.date === date);
        return existingEntry ? existingEntry : { date, count: 0 };
    });

    return result;
};

export const getIsChecked = (value: any, arr: any) => {
    // Loop through each object in arr1
    for (let i = 0; i < arr.length; i++) {
        // Get the keys (e.g., "region", "file_type", "language")
        const keys = Object.keys(arr[i]);

        // Loop through the values of the current key
        for (let j = 0; j < keys.length; j++) {
            // Check if the given valueToCheck is present in the array
            if (arr[i][keys[j]].includes(value)) {
                return true; // If found, return true
            }
        }
    }

    return false;
}

export const getLeadFormActiveClassNameLead = (value: any) => {
    return `custom-btn btn-md ${value ? 'active' : ''}`
}
export const getLeadFormActiveClassNameDeal = (value: any) => {
    return `custom-btn btn-md ${value ? 'active' : ''}`
}

export const getMemberErrorMessages = (responseJson: any) => {
    if (
        responseJson &&
        (((responseJson?.error || responseJson?.errors &&
            (responseJson?.error === "The partner admin changed the role of the member" ||
                responseJson?.error === "Account does not exist or has been deleted"))) ||
            (responseJson?.errors?.length > 0 &&
                (responseJson?.errors[0]?.account_error === "Account does not exist or has been deleted")))
    ) {
        return true;
    }
    return false; // Add a default return value if the condition is not met
};

export const getCurrencyValue = (value: any) => {
    if (value !== null) {
        return `$${value}`
    } else {
        return "-"
    }
}

export const getIntitals = (value: any) => {
    if (value === null) {
        return "";
    }

    const words = value?.split(' ');
    const initials = words?.map((word: any) => word.charAt(0)).slice(0, 2).join('');

    return initials?.toUpperCase();
}


export const getTrueValidataions = (value: any, str1: any, str2: any) => {
    return value ? str1 : str2
}

export const getActualValue = (value: any, symbol: any) => {
    if (value === undefined) {
        return "";
    }

    if (symbol === '%') {
        return value !== null ? `${value}${symbol}` : "";
    } else {
        return value !== null ? `${symbol}${value}` : "";
    }
};

export const getDateRangeText = (calendarValue: any, dateRange: any) => {
    if (calendarValue === 'Custom...') {
        return `For ${moment(dateRange[0]).format('MMM D')} - ${moment(dateRange[1]).format('MMM D YYYY')}`
    } else {
        return calendarValue
    }
}

export const getNullValues = (value: any) => {
    return value !== null && value !== "" && value !== undefined ? value : "-";
}

export const getSavingsActiveClass = (value: any, className: any) => {
    return value === 'savings' ? `${className} active` : className;
}
export const getCurrentActiveClass = (value: any, className: any) => {
    return value === 'current' ? `${className} active` : className;
}
export const capitalizeFirstLetter = (str: String) => {

    if (str === null || str === "" || str === undefined) {
        return ""; // Return an empty string for null or empty input
    }

    if (str.charAt(0) === ' ') {
        // If the first character is a space, return the string without capitalizing it
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getFullName = (firstName: String, lastName: String) => {
    // Check for null values
    if (!firstName && !lastName) {
        return null;
    } else if (!firstName) {
        return capitalizeFirstLetter(lastName);
    } else if (!lastName) {
        return capitalizeFirstLetter(firstName);
    } else {
        return capitalizeFirstLetter(firstName) + ' ' + capitalizeFirstLetter(lastName);
    }
}

export const getCommaValue = (value: any) => {
    if (value !== undefined && value !== null) {
        return value.replace(/;/g, ',');
    } else {
        return "";
    }
}


export const getContractInfo = (value: any) => {
    if (value !== undefined) {
        return true
    } else {
        return false
    }
}

export const truncateFileName = (fileName: any, maxLength: any) => {
    if (fileName.length > maxLength) {
        const extensionIndex = fileName.lastIndexOf('.');
        const extension = fileName.substring(extensionIndex);
        const fileNameWithoutExtension = fileName.substring(0, extensionIndex);
        const truncatedFileName = fileNameWithoutExtension.substring(0, maxLength - extension.length - 3) + '...' + extension;
        return truncatedFileName;
    }
    return fileName;
}
export const getAmountValue = (string: any) => {
    // Check if the input string is null or undefined
    if (string === null || string === undefined) {
        return 0; // or handle it in any other appropriate way
    }

    // Replace the "$" sign and parse the string
    return parseFloat(string.replace("$", ""));
}

export const getInvoiceLabel = (value: any, status: any) => {
    if (status === 'Declined') {
        return value
    }
    if (status === 'Complete') {
        return value
    }
    return ""
}