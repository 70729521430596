import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import { emptyImg } from "../../contractmanagement2/src/assets";
import { siteLogo } from "../../email-account-registration/src/assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props extends RouteComponentProps {}

interface S {}

interface SS {}

class InfoPageBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box className="error-page-wrapper">
        <Box className="error-inner-wraper">
          <Box className="logo-block">
            <img src={siteLogo} alt="site logo"></img>
          </Box>
          <Box className="error-text-wrapper">
            <Box className="error-content-innerwrap">
              <Box className="bg-404-text">404</Box>
              <img src={emptyImg} alt="" className="error-img"></img>
              <Typography className="error-title">
                Hmm...that page doesn’t exist
              </Typography>
              <Typography className="error-detail">
                Some links might be broken here and there. Sorry for the
                inconvenience!
              </Typography>
              <Button
                type="button"
                onClick={() => this.props.history.push("/DashboardWeb")}
                className="custom-btn purple-btn"
              >
                Go to Home
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withRouter(InfoPageBlock);
