import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import './topnav.css';
import { Avatar, Grid, Menu, MenuItem, Typography } from "@material-ui/core";
import { getIntitals, getToken, removeAllToken } from "./commonUsage";
const notification = require('./notification.svg')
const side_arrow = require('./side_arrow.svg')
const side_arrow_color = require('./side_arrow_color.svg')
// const notification_active = require('./notification_active.svg')

const TopNavBar = ({ history, currentPage, subPage, backPage }: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const image_url = getToken('profile_image') || ""
    const profile_name = getToken('profile_name') || ""

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        removeAllToken()
        history.push('/')
    }

    useEffect(() => {

        if (getToken('authToken') === null) {
            removeAllToken()
            history.push('/')
        }
    }, [getToken('authToken')])

    return (
        <Grid className="top-nav-container" container>
            <Grid item xs={10} md={10}>
                <Typography className="bredcrumb-text">
                    {
                        backPage ?
                            <>
                                <label style={{ cursor: 'pointer' }} onClick={() => history.push(`/${backPage.path}`)} className={"text-color display-flex"}><img className="side-arrow-img" src={side_arrow_color} />{backPage?.name}</label>
                            </>
                            :
                            <>
                                <label style={{ cursor: 'pointer' }} onClick={() => history.push(`/${currentPage.path}`)} className={subPage?.name !== undefined ? "text-color" : ""}>{currentPage?.name}</label> <label className="display-flex">{subPage?.name !== undefined ? <><img className="side-arrow-img" src={side_arrow} />{subPage?.name}</> : null}</label>
                            </>
                    }
                </Typography>
            </Grid>
            <Grid item xs={2} md={2} className="notification-container">
                <Menu
                    style={{
                        top: '52px',
                        borderRadius: '8px'
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem className="menu-item-text" onClick={() => history.push('/UserProfileBasicBlockWeb')}>Profile</MenuItem>
                    <MenuItem className="menu-item-text" onClick={() => handleLogout()}>Logout</MenuItem>
                </Menu>
                <div className="notification-wrap">
                    <i>
                        <img src={notification} className="top-notifcations" alt="Notifications" style={{ width: 26, height: 26, cursor: 'pointer' }} />
                        {/* <span className="dot"></span> */}
                    </i>
                </div>
                <Avatar className="avtar-block purple-bg" onClick={handleClick} src={`${image_url}`} alt="" style={{ width: 34, height: 34, marginLeft: 8,marginRight:5, cursor: 'pointer' }} >{getIntitals(profile_name)}</Avatar>
            </Grid>
        </Grid>
    );
};
export default withRouter(TopNavBar);
