import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Formik, Field, ErrorMessage } from 'formik';

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];


import Select from 'react-select'
import { customer, others, accordition_icon, deals, closeIcon, uploadIcon, filesUploaded, uploadFile, dealSuccess, rightCheck } from "./assets";
import { dealCompanyregistrationSchema, dealRegistrationSchema, leadCompanyregistrationSchema, leadRegistrationSchema } from "../../../components/src/validations";
import { CSVLink } from "react-csv";
import { dealStatusArray, getLeadFormActiveClassNameDeal, getLeadFormActiveClassNameLead, getWrapperClassName } from "../../../components/src/commonUsage";
import RegisterLeadSkeleton from "./RegisterLeadSkeleton";
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'

export default class LeadManagement extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.fileInputNewRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getCompanies()
    this.getIndustry()
    this.sampleCsvDownload()
    this.getCountries()
  }
  openFileInput = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  openNewFileInput = () => {
    if (this.fileInputNewRef.current) {
      this.fileInputNewRef.current.click();
    }
  };
  renderDealForm = (formik: any) => {
    return (<form className="deal-form" onSubmit={formik.handleSubmit}>
      <Box className="deal-form">
        <Box className="deal-form-container">
          <Accordion data-test-id="accordionDeal" expanded={this.getAccoridtionStatus('deal', formik.errors)}
            onChange={(_, isOpen) =>
              this.updateAccordionStatus('deal', isOpen)
            } className="deal-form-accordition">
            <AccordionSummary
              expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={deals} /></label>Deal Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deal-form-internal-container">
                <Box>
                  <TextField
                    data-test-id="deal_name"
                    name="deal_name"
                    size="small"
                    variant="filled"
                    label="Deal name*"
                    fullWidth
                    value={formik.values.deal_name}
                    onChange={
                      formik.handleChange
                    }
                    error={this.getFormikError(formik.touched.deal_name, formik.errors.deal_name)}
                    helperText={this.getFormikHelperText(formik.touched.deal_name, formik.errors.deal_name)}
                  />
                </Box>

                <Box className="mt-1">
                  <Grid className="grid-even-space">
                    <Grid item xs={12} md={12}>
                      <div className="input-with-adornment">
                        <Field name="deal_currency">
                          {(field: any) => (
                            <Select
                              data-test-id="selectDealCurrency"
                              {...field}
                              name="deal_currency"
                              styles={currencyStyles}
                              placeholder="$"
                              options={currencies}
                              className="currency-dropdown"
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                              }}
                              isDisabled
                            />
                          )}
                        </Field>
                        <TextField
                          data-test-id="deal_value"
                          name="deal_value"
                          size="small"
                          fullWidth
                          id={this.getDealValudId(formik.values.deal_value, "deal-value-normal-textfield", "deal-value-selected-textfield")}
                          label="Deal value"
                          variant="filled"
                          value={formik.values.deal_value}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <ErrorMessage className="deal-error-text" name="deal_value" component="div" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.deal_status, this.state.dealStatusFocus)}>
                        <Field name="deal_status">
                          {(field: any) => (
                            <>
                              <label className="select-label">Deal Status*</label>
                              <Select
                                {...field}
                                data-test-id="selectDealStatus"
                                options={dealStatusArray}
                                name="deal_status"
                                styles={selectStyle}
                                className="custom-search-select"  //add class "has-error"  when error occurs to show border-color red
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                onChange={(option) =>
                                  formik.setFieldValue('deal_status', option)
                                }
                                placeholder=""
                                onFocus={() => this.handleFocus('deal_status')}
                                onBlur={() => this.handleBlur('deal_status')}
                              />

                            </>
                          )}
                        </Field>
                        <ErrorMessage className="deal-error-text" name="deal_status" component="div" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="deal-form-container">
          <Accordion data-test-id="accordionCustomer" expanded={this.getAccoridtionStatus('customer', formik.errors)}
            onChange={(_, isOpen) =>
              this.updateAccordionStatus('customer', isOpen)
            } className="deal-form-accordition">
            <AccordionSummary
              expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={customer} /></label>Customer Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deal-form-internal-container">
                <Box>
                  <TextField
                    size="small"
                    variant="filled"
                    label="Customer email"
                    fullWidth
                    name='customer_email'
                    value={formik.values.customer_email}
                    onChange={
                      formik.handleChange
                    }
                    error={this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)}
                    helperText={this.getFormikHelperText(formik.touched.customer_email, formik.errors.customer_email)}
                  />
                </Box>
                <Box className="mt-1">
                  <Grid className="grid-even-space">
                    <Grid item xs={12} md={12}>
                      <TextField
                        name="first_name"
                        size="small"
                        fullWidth
                        id="input-with-icon-textfield"
                        label="First name"
                        variant="filled"
                        value={formik.values.first_name}
                        onChange={
                          formik.handleChange
                        }
                        error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                        helperText={this.getFormikHelperText(formik.touched.first_name, formik.errors.first_name)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        name="last_name"
                        size="small"
                        fullWidth
                        id="input-with-icon-textfield"
                        label="Last name"
                        variant="filled"
                        value={formik.values.last_name}
                        onChange={
                          formik.handleChange
                        }
                        error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                        helperText={this.getFormikHelperText(formik.touched.last_name, formik.errors.last_name)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className="mt-1">
                  <Typography className="company-info-text">COMPANY INFORMATION</Typography>
                  <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.company_name, this.state.companyNameFocus)}>
                    <Field name="company_name">
                      {(field: any) => (
                        <>
                          <label className="select-label">Company name</label>
                          <Select
                            {...field}
                            value={formik.values.company_name}
                            options={this.state.companyArray.map((item: any) => {
                              return { value: item.id, label: item.name }
                            })}
                            name="company_name"
                            styles={selectStyle}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            className="mt-1 custom-search-select"
                            placeholder=""
                            onChange={(option) => {
                              this.setState({
                                createNewCompany: false
                              })
                              formik.setFieldValue('company_name', option)
                            }
                            }
                            noOptionsMessage={({ inputValue }) => {
                              return (
                                !this.state.createNewCompany ?
                                  <div style={{ cursor: 'pointer', textAlign: 'left', display: 'flex', alignItems: 'center' }} onClick={() => {
                                    let data = {
                                      value: inputValue, label: inputValue
                                    }
                                    formik.setFieldValue('company_name', data)
                                    this.createNewCompany()
                                  }}>Create : <Typography className="new-company-text">{inputValue}</Typography></div>
                                  :
                                  null
                              )
                            }
                            }
                            onFocus={() => this.handleFocus('company_name')}
                            onBlur={() => this.handleBlur('company_name')}
                          />

                        </>
                      )}
                    </Field>
                    <ErrorMessage className="deal-error-text" name="company_name" component="div" />
                  </Box>
                </Box>
                {this.state.createNewCompany &&
                  <>
                    <Box className="mt-1">
                      <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.industry, this.state.industryFocus)}>
                        <Field name="industry">
                          {(field: any) => (
                            <>
                              <label className="select-label">Industry</label>
                              <Select
                                {...field}
                                options={this.state.industryArray.map((item: any) => {
                                  return { value: item, label: item }
                                })}
                                name="industry"
                                styles={selectStyle}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                placeholder=" "
                                className="custom-search-select"
                                onChange={(option: any) => {
                                  formik.setFieldValue("industry", option)
                                }
                                }
                                onFocus={() => this.handleFocus('industry')}
                                onBlur={() => this.handleBlur('industry')}
                              />
                            </>
                          )}
                        </Field>
                        <ErrorMessage className="deal-error-text" name="industry" component="div" />
                      </Box>
                    </Box>
                    <Box className="mt-1">
                      <TextField
                        size="small"
                        variant="filled"
                        label="Company website"
                        fullWidth
                        name='company_website'
                        value={formik.values.company_website}
                        onChange={
                          formik.handleChange
                        }
                        error={this.getFormikError(formik.touched.company_website, formik.errors.company_website)}
                        helperText={this.getFormikHelperText(formik.touched.company_website, formik.errors.company_website)}
                      />
                    </Box>
                    <Box className="mt-1">
                      <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.country, this.state.countryFocus)}>
                        <Field name="country">
                          {(field: any) => (
                            <>
                              <label className="select-label">Country</label>
                              <Select
                                {...field}
                                options={this.state.countryData}
                                name="country"
                                styles={selectStyle}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                placeholder=" "
                                className="custom-search-select"
                                onChange={(option: any) => {
                                  formik.setFieldValue("country", option)
                                }
                                }
                                onFocus={() => this.handleFocus('country')}
                                onBlur={() => this.handleBlur('country')}
                              />
                            </>
                          )}
                        </Field>
                        <ErrorMessage className="deal-error-text" name="country" component="div" />
                      </Box>
                    </Box>
                  </>
                }

              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="deal-form-container">
          <Accordion data-test-id="accordionOther" expanded={this.getAccoridtionStatus('other', formik.errors)}
            onChange={(_, isOpen) =>
              this.updateAccordionStatus('other', isOpen)
            } className="deal-form-accordition">
            <AccordionSummary
              expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={others} /></label>Other Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deal-form-internal-container">
                <Box>
                  <TextField
                    size="small"
                    variant="filled"
                    label="Lead source"
                    fullWidth
                    name='lead_source'
                    value={formik.values.lead_source}
                    onChange={
                      formik.handleChange
                    }
                    error={this.getFormikError(formik.touched.lead_source, formik.errors.lead_source)}
                    helperText={this.getFormikHelperText(formik.touched.lead_source, formik.errors.lead_source)}
                  />
                </Box>
                <Box className="mt-1">
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ position: 'absolute', top: 20, right: 10 }}>
                          <Typography className="description-text">
                            {`${formik.values.description.length}/500`}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 500 // Use "maxLength" instead of "maxlength" for HTML attribute.
                    }}
                    name="description"
                    fullWidth
                    variant="filled"
                    label="Description"
                    multiline
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={this.getFormikError(formik.touched.description, formik.errors.description)}
                    helperText={this.getFormikHelperText(formik.touched.description, formik.errors.description)}
                  />

                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {
          this.state.errorMessage !== null &&
          <Box>
            <Typography className="deal-error-text">
              {this.state.errorMessage}
            </Typography>
          </Box>
        }
        <Box className="deal-button-container">
          <Box className="deal-button-accordition">
            <Button className="custom-btn green-btn" variant="contained" type="submit">
              <Typography className="register-button-text">
                Register New Deal
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>)
  }
  renderLeadForm = (formik: any) => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box className="deal-form-lead">
          <Box className="deal-form-container">
            <Typography className="deal-form-heading"><label className="deal-form-heading-icon"><img src={customer} /></label>Lead Information</Typography>
            <Box className="lead-form-internal-container">
              <Box>
                <Grid className="grid-even-space">
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="first_name"
                      size="small"
                      fullWidth
                      id="input-with-icon-textfield"
                      label="First name"
                      variant="filled"
                      value={formik.values.first_name}
                      onChange={
                        formik.handleChange
                      }
                      error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                      helperText={this.getFormikHelperText(formik.touched.first_name, formik.errors.first_name)}

                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="last_name"
                      size="small"
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Last name"
                      variant="filled"
                      onChange={
                        formik.handleChange
                      }
                      error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                      helperText={this.getFormikHelperText(formik.touched.last_name, formik.errors.last_name)}

                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-1">
                <Grid className="grid-even-space">
                  <Grid item xs={12} md={12}>
                    <TextField
                      size="small"
                      variant="filled"
                      label="Email"
                      fullWidth
                      name='customer_email'
                      type="email"
                      onChange={
                        formik.handleChange
                      }
                      error={this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)}
                      helperText={this.getFormikHelperText(formik.touched.customer_email, formik.errors.customer_email)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid item xs={12} md={12}>
                      <div className="input-with-adornment">
                        <Field name="lead_country_code">
                          {(field: any) => (
                            <Select
                              data-test-id="dealCountryCode"
                              {...field}
                              name="lead_country_code"
                              styles={countryStyles}
                              options={this.state.countryCodes.map((item: any) => {
                                return {
                                  value: `+${item.value} ${item.name}`, label: <><span className={`fi fi-${item.code.toLowerCase()}`}></span>{" "}{item.name}{" "}<label className="country-code-color">{`+${item.value}`}</label> </>,
                                  countryValue: `+${item.value}`, countryLabel: <><span className={`fi fi-${item.code.toLowerCase()}`}></span>{" "}{`+${item.value}`} </>
                                }
                              })}
                              className="currency-dropdown country-dropdown"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(option) => {
                                formik.setFieldValue('lead_country_code', option)
                              }
                              }
                              value={{ label: formik.values.lead_country_code.countryLabel }}
                            />
                          )}
                        </Field>
                        <TextField
                          data-test-id="phone_number"
                          name="phone_number"
                          size="small"
                          fullWidth
                          label="Phone number"
                          variant="filled"
                          value={formik.values.phone_number}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <ErrorMessage className="deal-error-text" name="lead_country_code" component="div" />
                      <ErrorMessage className="deal-error-text" name="phone_number" component="div" />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-1">
                <Grid className="grid-even-space">
                  <Grid item xs={12} md={12}>
                    <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.currency, this.state.currencyFocus)}>
                      <Field name="currency">
                        {(field: any) => (
                          <>
                            <label className="select-label">Currency</label>
                            <Select
                              {...field}
                              options={this.state.currencyData}
                              name="currency"
                              styles={selectStyle}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              placeholder=" "
                              className="custom-search-select"
                              onChange={(option: any) => {
                                formik.setFieldValue("currency", option)
                              }
                              }
                              onFocus={() => this.handleFocus('currency')}
                              onBlur={() => this.handleBlur('currency')}
                            />
                          </>
                        )}
                      </Field>
                      <ErrorMessage className="deal-error-text" name="currency" component="div" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-1">
                <Typography className="deal-form-heading">Company Information</Typography>
                <Box>
                  <Grid className="grid-even-space">
                    <Grid item xs={12} md={12}>
                      <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.company_name, this.state.companyNameFocus)}>
                        <Field name="company_name">
                          {(field: any) => (
                            <>
                              <label className="select-label">Company name</label>
                              <Select
                                {...field}
                                value={formik.values.company_name}
                                options={this.state.companyArray.map((item: any) => {
                                  return { value: item.id, label: item.name }
                                })}
                                name="company_name"
                                styles={selectStyle}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                className="mt-1 custom-search-select"
                                placeholder=""
                                onChange={(option) => {
                                  this.setState({
                                    createNewCompany: false
                                  })
                                  formik.setFieldValue('company_name', option)
                                }
                                }
                                noOptionsMessage={({ inputValue }) => {
                                  return (
                                    !this.state.createNewCompany ?
                                      <div style={{ cursor: 'pointer', textAlign: 'left', display: 'flex', alignItems: 'center' }} onClick={() => {
                                        let data = {
                                          value: inputValue, label: inputValue
                                        }
                                        formik.setFieldValue('company_name', data)
                                        this.createNewCompany()
                                      }}>Create : <Typography className="new-company-text">{inputValue}</Typography></div>
                                      :
                                      null
                                  )
                                }
                                }
                                onFocus={() => this.handleFocus('company_name')}
                                onBlur={() => this.handleBlur('company_name')}
                              />

                            </>
                          )}
                        </Field>
                        <ErrorMessage className="deal-error-text" name="company_name" component="div" />
                      </Box>
                    </Grid>
                    {this.state.createNewCompany &&
                      <Grid item xs={12} md={12}>
                        <Box style={{ marginTop: '16px' }} className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.industry, this.state.industryFocus)}>
                          <Field name="industry">
                            {(field: any) => (
                              <>
                                <label className="select-label">Industry</label>
                                <Select
                                  {...field}
                                  options={this.state.industryArray.map((item: any) => {
                                    return { value: item, label: item }
                                  })}
                                  name="industry"
                                  styles={selectStyle}
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  placeholder=" "
                                  className="custom-search-select"
                                  onChange={(option: any) => {
                                    formik.setFieldValue("industry", option)
                                  }
                                  }
                                  onFocus={() => this.handleFocus('industry')}
                                  onBlur={() => this.handleBlur('industry')}
                                />
                              </>
                            )}
                          </Field>
                          <ErrorMessage className="deal-error-text" name="industry" component="div" />
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Box>
                {this.state.createNewCompany &&
                  <Box className="mt-1">
                    <Grid className="grid-even-space">
                      <Grid item xs={12} md={12}>
                        <TextField
                          size="small"
                          variant="filled"
                          label="Company website"
                          fullWidth
                          name='company_website'
                          value={formik.values.company_website}
                          onChange={
                            formik.handleChange
                          }
                          error={this.getFormikError(formik.touched.company_website, formik.errors.company_website)}
                          helperText={this.getFormikHelperText(formik.touched.company_website, formik.errors.company_website)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box className={getWrapperClassName("custom-search-dropdown-wrapper", "is-focused", formik.values.country, this.state.countryFocus)}>
                          <Field name="country">
                            {(field: any) => (
                              <>
                                <label className="select-label">Country</label>
                                <Select
                                  {...field}
                                  options={this.state.countryData}
                                  name="country"
                                  styles={selectStyle}
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  placeholder=" "
                                  className="custom-search-select"
                                  onChange={(option: any) => {
                                    formik.setFieldValue("country", option)
                                  }
                                  }
                                  onFocus={() => this.handleFocus('country')}
                                  onBlur={() => this.handleBlur('country')}
                                />
                              </>
                            )}
                          </Field>
                          <ErrorMessage className="deal-error-text" name="country" component="div" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                }
              </Box>
            </Box>
            {
              this.state.errorMessage !== null &&
              <Box>
                <Typography className="error-text">
                  {this.state.errorMessage}
                </Typography>
              </Box>
            }
            <Box className="deal-button-container">
              <Box className="lead-button-accordition">
                <Button className="custom-btn green-btn" variant="contained" type="submit">
                  <Typography className="register-button-text">
                    Register new lead
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box >
      </form >
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      //@ts-ignore
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Deals and leads', path: 'LeadDashboard' }} subPage={{ name: 'Register New Deal or Lead' }} />
              {
                this.state.isLoading ?
                  // <Loader loading={this.state.isLoading} contentLoader={true} />
                  <RegisterLeadSkeleton />
                  :
                  <Box style={webStyles.mainContainer}>
                    <div className="page-title-block">
                      <div className="left-block">
                        <Typography className="deal-heading-main">
                          Register New Deal or Lead
                        </Typography>
                        <Typography className="deal-heading-sub">
                          Enter the information required in order to register a new lead or deal.
                        </Typography>
                      </div>
                    </div>
                    <Box className="deal-btn-outerwrap" style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'end'
                    }}>
                      <Box className="deal-lead-btn-wrap">
                        <Button data-test-id="select-lead" onClick={() => this.handleForms('lead')} disableRipple className={getLeadFormActiveClassNameLead(this.state.leadRegistraion)}>Register Lead</Button>
                        <Button data-test-id="select-deal" onClick={() => this.handleForms('deal')} disableRipple className={getLeadFormActiveClassNameDeal(this.state.dealRegistraton)}>Register Deal</Button>
                      </Box>
                      {
                        this.state.leadRegistraion &&
                        <div className="right-block">
                          <Button data-test-id="openUploadCsvModal" disableRipple className="upload-csv-btn text-btn" onClick={() => this.openUploadCsvModal()}>Upload lead by CSV</Button>
                        </div>
                      }
                    </Box>

                    <div>
                      {
                        this.state.dealRegistraton &&
                        <Formik
                          enableReinitialize
                          initialValues={{
                            deal_name: '',
                            deal_value: '',
                            deal_status: null,
                            customer_email: '',
                            company_name: null,
                            first_name: '',
                            last_name: '',
                            lead_source: '',
                            description: '',
                            country: null,
                            company_website: '',
                            industry: null,
                          }}
                          validationSchema={this.getLeadCreatevalidation(this.state.createNewCompany, dealCompanyregistrationSchema, dealRegistrationSchema)}
                          onSubmit={(values) => {
                            this.submitNewLead(values)
                          }}
                        >
                          {(formik) => (
                            this.renderDealForm(formik)
                          )}
                        </Formik>
                      }
                      {
                        this.state.leadRegistraion &&
                        <Formik
                          enableReinitialize
                          initialValues={{
                            deal_name: '',
                            first_name: '',
                            last_name: '',
                            customer_email: '',
                            lead_source: '',
                            description: '',
                            country: null,
                            company_website: '',
                            company_name: null,
                            industry: null,
                            phone_number: '',
                            lead_country_code:
                            {
                              value: `+${1}`, label: <><span className="fi fi-us"></span>{" "}+1</>,
                              countryValue: `+${1}`, countryLabel: <><span className="fi fi-us"></span>{" "}+1</>,
                            },
                            currency: null,
                          }}
                          validationSchema={this.getLeadCreatevalidation(this.state.createNewCompany, leadCompanyregistrationSchema, leadRegistrationSchema)}
                          onSubmit={(values) => {
                            this.submitNewLead(values)
                          }}
                        >
                          {(formik) => (
                            this.renderLeadForm(formik)
                          )}
                        </Formik>
                      }
                    </div>
                  </Box>
              }
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={this.state.uploadCsvModal}
          onClose={this.closeUploadCsvModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="upload-csv-modal custom-modal"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box className="modal-header">
              <IconButton data-test-id="btnCloseUploadModal1" disableRipple className="close-btn" onClick={() => this.closeUploadCsvModal()}><img src={closeIcon} alt="close" /></IconButton>
            </Box>
            <Box className="modal-content">
              <Box className="title-block">
                <Box className="left-block">
                  <Typography variant="h4">Add leads with CSV</Typography>
                </Box>
                <Box className="right-block">
                  <CSVLink
                    filename="sample_leads.csv"
                    data={this.state.sampleCsvData.data} headers={this.state.sampleCsvData.headers.map((item: any) => {
                      return { label: item, key: item }
                    })}>
                    <Button disableRipple className="text-btn text-sm">Download sample CSV</Button>
                  </CSVLink>
                </Box>
              </Box>
              <Typography>Upload a CSV or Excel file with the needed details for the leads including information such as first and last name, lead source, company etc. We will help you map the file to our data structure in the next step. </Typography>
              <Typography className="text-sm">Sample preview</Typography>
              <Box className="table-wrapper-block">
                <TableContainer className="lead-table secondary-table">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '116px' }} className="lead-table-heading">first name</TableCell>
                        <TableCell style={{ width: '150px' }} className="lead-table-heading">Company</TableCell>
                        <TableCell style={{ width: '150px' }} className="lead-table-heading">Website</TableCell>
                        <TableCell style={{ width: '140px' }} className="lead-table-heading">industry</TableCell>
                        <TableCell style={{ width: '160px' }} className="lead-table-heading">country</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="lead-table-data-row" >Regeneration app</TableCell>
                        <TableCell className="lead-table-data-row">Allemco</TableCell>
                        <TableCell className="lead-table-data-row">Allemco.com</TableCell>
                        <TableCell className="lead-table-data-row">Textile</TableCell>
                        <TableCell className="lead-table-data-row">France</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {
                this.state.isUploaded &&
                <Box className="upload-new-csv-block">
                  <input
                    type="file"
                    id="file-input2"
                    accept=".csv"
                    onChange={this.handleFileInputChange}
                    style={{ display: 'none' }}
                    ref={this.fileInputNewRef}
                  />
                  <Button data-test-id="open-new-file-input" onClick={this.openNewFileInput} disableRipple className="text-btn text-sm"><img src={uploadIcon}></img>Upload new CSV file</Button>
                </Box>
              }

              {
                this.state.fileSizeError != null &&
                <Alert severity="error" icon={false}>
                  {this.state.fileSizeError}
                </Alert>
              }
              {
                this.state.fileTypeError != null &&
                <Alert severity="error" icon={false}>
                  {this.state.fileTypeError}
                </Alert>
              }
              <Box className="upload-cvs-block">
                {/* add click functionality here */}
                <Box className="upload-csv-inner-wrap"
                  onDragEnter={this.handleDragEnter}
                  onDragOver={this.handleDragEnter}
                  onDragLeave={this.handleDragLeave}
                  onDrop={this.handleDrop}
                >

                  {/* loading */}
                  {this.state.isLoading ?
                    <Box className="upload-content-wrap">
                      <Box className="image-loader-block">
                        <CircularProgress disableShrink value={80} />
                      </Box>
                      <Box className="content-wrap">
                        <Typography className="title">Uploading your data...</Typography>
                      </Box>
                    </Box>
                    :
                    <>
                      {
                        this.state.isUploaded ?
                          < Box className="upload-content-wrap">
                            <Box className="image-loader-block">
                              <img src={filesUploaded} alt=""></img>
                            </Box>
                            <Box className="content-wrap">
                              <Typography className="title">{this.state.uploadLeadsCount}</Typography>
                            </Box>
                          </Box>
                          :
                          < Box className="upload-content-wrap">
                            <Box className="image-loader-block">
                              <img src={uploadFile} alt=""></img>
                            </Box>
                            <Box className="content-wrap"

                            >
                              <Typography className="title">Upload leads</Typography>
                              <Typography className="content">Drag & drop a .csv file to upload</Typography>
                              <input
                                type="file"
                                id="file-input"
                                accept=".csv"
                                onChange={this.handleFileInputChange}
                                style={{ display: 'none' }}
                                ref={this.fileInputRef}
                              />
                              <Button data-test-id="open-file-input" onClick={this.openFileInput} disableRipple className="underline-link">Browse files</Button>
                            </Box>
                          </Box>
                      }
                    </>
                  }
                </Box>
              </Box>
              <Box className="btn-wrap">
                <Button data-test-id="btnCloseUploadModal2" className="custom-btn gray-btn" style={{ textTransform: 'none' }} onClick={() => this.closeUploadCsvModal()}>Cancel</Button>
                <Button data-test-id="handleUploadCsv" className="custom-btn green-btn" style={{ textTransform: 'none' }} onClick={() => this.handleUploadCsv()} disabled={!this.state.isUploaded}>Add Leads</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* deal / lead registered successfully modal  */}
        <Modal
          open={this.state.confirmationModal}
          onClose={this.closeConfirmationModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="confirmation-modal custom-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <IconButton data-test-id="btnCloseConfirmationModal" disableRipple className="close-btn" onClick={() => this.closeConfirmationModal()}><img src={closeIcon} alt="close" /></IconButton>
            <Box className="modal-content">
              <Box className="image-block">
                <img src={dealSuccess} alt="success" />
              </Box>
              <Box className="content-wrap">
                <Typography variant="h4">You {this.state.dealRegistraton ? 'deal' : 'lead'} has been added <img src={rightCheck} alt="green check" /></Typography>
                <Typography>Details entered have been recorded, and added to the database</Typography>
              </Box>
              <Box className="btn-wrap">
                <Button data-test-id="navigate-lead-dashboard" onClick={() => this.navigateToLeadDashboard()} className="custom-btn light-btn">View {this.state.dealRegistraton ? 'deal' : 'lead'}</Button>
                <Button data-test-id="close-confirmation-model" onClick={() => this.closeConfirmationModal()} className="custom-btn secondary-border-btn" variant="outlined">Register new deal or lead</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflowX: "hidden"
  },
  mainContainer: {
    padding: '16px 0'
  },
};
const currencyStyles = {
  control: (styles: any) => ({
    ...styles,
    width: '66px',
    boxShadow: 'none',
    height: 60,
    borderRadius: '3px 0px 0px 3px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
    backgroundColor: "#F8F9FA",
  })
};
const countryStyles = {
  control: (styles: any) => ({
    ...styles,
    width: '112px',
    boxShadow: 'none',
    height: 60,
    borderRadius: '3px 0px 0px 3px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
    backgroundColor: "#F8F9FA",
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0,
    width: '420px'

  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
}
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 61,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};

// Customizable Area End
