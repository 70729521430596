import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./css/style.css";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Formik } from 'formik';
import { registrationInviteSchema } from "../../../components/src/validations";
import Loader from "../../../components/src/Loader.web";
import { builderPartner, openEye, visiblityOff } from "../../email-account-login/src/assets";
import { getHideUnHidePassword } from "../../../components/src/commonUsage";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistrationInvite extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {
          this.state.loader ?
            <Loader loading={this.state.loader} />
            :
            <Grid className="container-box">
              <Container maxWidth="xs" className="container">
                <Formik
                  enableReinitialize
                  initialValues={{ password: '', reTypePassword: '' }}
                  validationSchema={registrationInviteSchema}
                  onSubmit={(values) => {
                    this.setState({
                      errorMessage: null
                    })
                    this.createPasswordInvite(values)
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          // padding: "10px 0px",
                        }}
                      >
                        <div style={{ textAlign: 'center' }}>
                          <img src={builderPartner} className="login-partner-logo" />
                          <div className="partner-text-container">
                            <Typography className="partner-text">PARTNER DASHBOARD</Typography>
                          </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                          <Typography className="registration-text">
                            Welcome Partner!
                          </Typography>
                        </div>
                        <Typography className="registration-sub-text">
                          Thanks for accepting the invite <b>{this.state.email}.</b><br /> Set password to get started
                        </Typography>
                        <Box sx={{ width: "600px", paddingTop: "10px" }}>
                          <Box sx={{ padding: "10px 0px" }}>
                            <TextField
                              name="password"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputPassword"
                              type={this.getTextPassword(this.state.enablePasswordField, "password", "text")}
                              label={"Set Password"}
                              fullWidth={true}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              error={this.getFormikError(formik.touched.password, formik.errors.password)}
                              helperText={this.getFormikHelperText(formik.touched.password, formik.errors.password)}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {
                                        getHideUnHidePassword(this.state.enablePasswordField, <img src={openEye} />, <img src={visiblityOff} />)
                                      }
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            />
                          </Box>
                          <Box sx={{ padding: "10px 0px" }}>
                            <TextField
                              name="reTypePassword"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputRePassword"
                              type={this.getTextPassword(this.state.enableReTypePasswordField, "password", "text")}
                              label={"Confirm Password"}
                              fullWidth={true}
                              value={formik.values.reTypePassword}
                              onChange={
                                formik.handleChange
                              }
                              error={this.getFormikError(formik.touched.reTypePassword, formik.errors.reTypePassword)}
                              helperText={this.getFormikHelperText(formik.touched.reTypePassword, formik.errors.reTypePassword)}
                              InputProps={{
                                endAdornment:
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickReTypeShowPassword}
                                      edge="end"
                                    >
                                      {
                                        getHideUnHidePassword(this.state.enableReTypePasswordField, <img src={openEye} />, <img src={visiblityOff} />)
                                      }
                                    </IconButton>
                                  </InputAdornment>
                              }}
                            />
                          </Box>
                          {
                            this.state.errorMessage !== null &&
                            <Box>
                              <Typography className="error-text">
                                {this.state.errorMessage}
                              </Typography>
                            </Box>
                          }
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: "10px 0px",
                            }}
                          >
                            <Button
                              data-test-id={"btnEmailLogIn"}
                              variant="contained"
                              color="primary"
                              className="registration-button"
                              fullWidth
                              type="submit"
                            >
                              <Typography className="btn-signin-text">
                                Create Password {/*UI Engine::From Sketch*/}
                              </Typography>
                            </Button>
                          </Box>
                        </Box>

                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Grid>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
