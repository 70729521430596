import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  Select,
  MenuItem,
  Menu,
  Modal,
  Tooltip,
  Paper,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Popper,
  List,
  ListItem
} from "@material-ui/core";

import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { checkIcon, closeIconBold, copyIcon, defaultAsset, downArrow, downArrowSmall, filterIcon, searchIcon, unCheckIcon } from "./assets";
import { closeIcon } from "../../leadmanagement/src/assets";
import './css/styles.css'
import { Document, Page } from 'react-pdf';
import Loader from "../../../components/src/Loader.web";
import Carousel from 'react-material-ui-carousel'
import AssetDashboardSkeleton from "./AssetDashboardSkeleton";
import { capitalizeFirstLetter, copyToClipboard, downloadFile, getCategoryName, getCategoryValue, getIsChecked, getMonthDateFormat } from "../../../components/src/commonUsage";
// Customizable Area End

import AssetsDashboardController, {
  Props,
  configJSON,
} from "./Assetsdashboardcontroller";


export default class AssetsDashboardWeb extends AssetsDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    // Customizable Area End
  }

  // Customizable Area Start   
  async componentDidMount() {
    await this.getFilters()
    await this.getAssets('normal')
  }
  // Customizable Area End

  render() {
    // Customizable Area Start        
    const { sortMenu, filterPopover, suggestionPopover } = this.state;
    const sortOpen = Boolean(sortMenu);
    const filterOpen = Boolean(filterPopover);
    const suggestionOpen = Boolean(suggestionPopover);
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Asset library', path: 'AssetsDashboardWeb' }} />
              <Box style={webStyles.mainContainer}>
                <Box className="dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="asset-heading-main">
                        Assets Library
                      </Typography>
                      <Typography className="asset-heading-sub">
                        Find assets to help potential leads to know more about Builder.ai here.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="filter-block-wrapper top-spacing-lg">
                  <Box className="table-search-text filter-inner-wrap">
                    <Box className="filter-left-block">
                      {
                        this.state.searchQuery.length > 0 ?
                          <Typography className="searchbar-text">{`Search results for "${this.state.searchQuery}"`}</Typography>
                          :
                          <Typography className="assets-count-text">Assets {this.state.assetsData.user_library?.meta.total_count > 0 ? `(${this.state.assetsData.user_library?.meta.total_count})` : ""}</Typography>
                      }
                    </Box>
                    <Box className="filter-right-block">
                      <Box className="right-inner-wrap">
                        <Box className="sortby-wrapper">
                          <div>
                            <Button
                              className="sort-deal-button"
                              id="sort-button"
                              data-test-id="sort-button"
                              aria-controls={sortOpen ? 'sort-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={sortOpen ? 'true' : undefined}
                              onClick={(event) => this.handleSortClick(event)}
                            >
                              <Typography className="asset-sort-text">
                                Sort by
                              </Typography>
                              <Typography
                                className="text-transform sort-value-text">{this.state.sortValue}
                                <label className="dropdown-arrow"> <img src={downArrow} /></label>
                              </Typography>
                            </Button>
                            <Menu
                              style={{
                                top: '52px',
                                borderRadius: '8px'
                              }}
                              id="sort-menu"
                              aria-labelledby="sort-button"
                              anchorEl={sortMenu}
                              open={sortOpen}
                              onClose={this.handleMenuClose}
                            >
                              <MenuItem id="sort-1" className="menu-item-text" onClick={() => this.handleSortClose("Latest")}>Latest</MenuItem>
                              <MenuItem id="sort-2" className="menu-item-text" onClick={() => this.handleSortClose('Oldest')}>Oldest</MenuItem>
                              <MenuItem id="sort-3" className="menu-item-text" onClick={() => this.handleSortClose('A-Z')}>A-Z</MenuItem>
                              <MenuItem id="sort-4" className="menu-item-text" onClick={() => this.handleSortClose('Z-A')}>Z-A</MenuItem>
                            </Menu>
                          </div>
                        </Box>
                        <Box className="filter-popup-wrapper">
                          <Button aria-describedby="filter-popup" className={filterOpen ? "filter-popup-button open-popover" : "filter-popup-button"} onClick={(event) => this.handleFilterClick(event)}>
                            <img src={filterIcon} alt="filter" className="filter-ic" />
                            Filters {this.state.checkedFilterItems.length > 0 && <span className="filter-indicator"></span>}
                          </Button>
                          <Popover
                            id="filter-popup"
                            open={filterOpen}
                            anchorEl={filterPopover}
                            style={{
                              top: '8px',
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handleFilterClose}
                            PaperProps={{
                              className: 'filter-menu-popover'
                            }}
                          >
                            <Box className="filter-option-wrapper">
                              <Box className="filter-header">
                                <Box className="left-block">
                                  <Typography className="title">Filters <span>{this.state.checkedFilterItems.length > 0 && `(${this.state.checkedFilterItems.length})`}</span></Typography>
                                </Box>
                                <Box className="right-block">
                                  <label onClick={() => this.clearFilters()} className="clear-link">clear all</label>
                                  <IconButton className="close-btn" onClick={this.handleFilterClose}><img src={closeIcon} alt="close" /></IconButton>
                                </Box>
                              </Box>
                              <Box className="filter-category-wrap">
                                {this.state.filterArray?.map((item: any, index: any) => {
                                  const filterKey = item.category_value;

                                  return (
                                    <Accordion
                                      key={index}
                                      expanded={this.state.expanded === `panel${index + 1}`}
                                      onChange={this.handleFilterCategoryChange(`panel${index + 1}`)}
                                    >
                                      <AccordionSummary
                                        expandIcon={<img className="arrow-img" src={downArrowSmall} alt="Expand Icon" />}
                                        aria-controls={`panel${index + 1}bh-content`}
                                        id={`panel${index + 1}bh-header`}
                                      >
                                        <Typography style={{ textTransform: 'capitalize' }}>{item.category_name}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <ul className="checkbox-group">
                                          {item.values?.map((value: any, index2: any) => {
                                            return (
                                              <li key={index2}>
                                                <FormControlLabel
                                                  style={{ textTransform: 'capitalize' }}
                                                  control={
                                                    <Checkbox
                                                      checked={getIsChecked(value.value_name, this.state.checkedFilterItems) || false}
                                                      icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                                                      checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                                                      name={value.value_name}
                                                      onChange={(e) => this.handleChangeFilter(value.value_name, e.target.checked, filterKey)}
                                                    />
                                                  }
                                                  label={value.value_name}
                                                />
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                })}
                              </Box>
                            </Box>
                          </Popover>
                        </Box>
                        <Box >
                          <Box>
                            <TextField
                              data-test-id="search-input"
                              className="search-input"
                              fullWidth
                              size="small"
                              placeholder="Search for asset"
                              variant="outlined"
                              value={this.state.searchQuery}
                              onChange={(e) => this.handleSearchValue(e.target.value)}
                              // onClick={this.handleSuggestionClick}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={searchIcon} className="ml-10" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {/* <Popover
                              id="filter-popup"
                              open={suggestionOpen}
                              anchorEl={suggestionPopover}
                              style={{
                                top: '8px',
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={this.handleSuggestionClose}
                              PaperProps={{
                                className: 'filter-menu-popover'
                              }}
                            >
                              <div>
                                <List className="custom-popover-list suggestion-list">
                                  <ListItem className="list-item-text">
                                    <Typography className="title">Builder studio_For</Typography>
                                    <Typography className="subtext">Document</Typography>
                                  </ListItem>
                                  <ListItem className="list-item-text">
                                    <Typography className="title">Builder 360</Typography>
                                    <Typography className="subtext">Image</Typography>
                                  </ListItem>
                                </List>
                              </div>
                            </Popover> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="filter-chips-listing-wrap">
                  <ul className="custom-chip-list">
                    {this.state.checkedFilterItems.map((item: any, index: any) => (
                      <li key={index} className="chip-item" style={{ textTransform: 'capitalize' }}>
                        {Object.keys(item).map((key) => (
                          <React.Fragment key={key}>
                            {item[key].length === 1 ? (
                              `${item[key][0]}`
                            ) : (
                              `${getCategoryName(item[key][0], this.state.filterArray)} (${item[key].length})`
                            )}
                            <i className="delete-ic">
                              <img src={closeIconBold} alt="delete" onClick={() => this.removeFilters(getCategoryValue(item[key][0], this.state.filterArray))} />
                            </i>
                          </React.Fragment>
                        ))}
                      </li>
                    ))}
                  </ul>
                </Box>
                {
                  this.state.isLoading ?
                    // <Loader loading={this.state.isLoading} contentLoader={true} />
                    <AssetDashboardSkeleton />
                    :
                    <Grid className="card-listing-wrap">
                      <div
                        onScroll={this.onScroll}
                        ref={this.listInnerRef}
                        className="custom-scrollbar"
                        style={{ height: "100vh", overflowY: "auto", overflowX: "hidden", maxHeight: 'calc(100vh - 263px)', padding: '2px' }}
                      >
                        <Grid container className="grid-even-asset">
                          {this.state.assetsData.user_library?.data?.attributes?.length === 0 ?
                            <Box className="">
                              <Typography className="error-message-text">No assets found</Typography>
                            </Box>
                            :
                            this.state.assetsData.user_library?.data?.attributes?.map((item: any) => {
                              return (
                                <Grid item xs={6} md={3} key={item.id} className="card-wrap">
                                  <Card className={item.small_image ? "small-thumb asset-card" : "asset-card"}>
                                    <div data-item-id="open-preview-modal" className="cursor-pointer" onClick={() => {
                                      if (item.file_type === 'links') {
                                        this.viewFile(item.url_file)
                                      } else {
                                        this.openPreviewModal(item.id)
                                      }
                                    }}>
                                      <Box
                                        style={{ height: '164px' }}
                                        // image={item.banner_image !== null ? item.banner_image : defaultAsset}
                                        // title="Builder.ai"
                                        className={item.file_type == "documents" || item.file_type == "links" ? "has-light-bg asset-image" : "asset-image"}
                                      >
                                        {/* <img src={item.banner_image !== null ? item.banner_image : defaultAsset} /> */}
                                        <img src={item.file_type !== "images" ? item.banner_image : item.library_file_url}></img>

                                      </Box>
                                      <CardContent
                                        className="card-content-wrap"
                                      >
                                        <div className="asset-title-layout">
                                          <Typography className="asset-title">
                                            {capitalizeFirstLetter(item.name)}
                                          </Typography>
                                        </div>
                                        <Typography className="asset-type">
                                          {capitalizeFirstLetter(item.file_type)}
                                        </Typography>
                                      </CardContent>
                                    </div>
                                    <CardActions className="asset-actions">
                                      {/* {
                                        item.library_download ===  true && */}
                                      <Box>
                                        {
                                          item.file_type === 'links' ?
                                            <Button data-test-id="download-from-list" onClick={() => this.viewFile(item.url_file)} className="custom-btn light-btn btn-sm download-btn" variant="contained">
                                              <Typography className="asset-download-text">
                                                View
                                              </Typography>
                                            </Button>
                                            :

                                            <Button data-test-id="download-from-list" onClick={() => downloadFile(item.library_file_url, item.name)} disabled={!item.library_download} className="custom-btn light-btn btn-sm download-btn" variant="contained">
                                              <Typography className="asset-download-text">
                                                Download
                                              </Typography>
                                            </Button>
                                        }
                                      </Box>
                                      {/* } */}
                                    </CardActions>
                                  </Card>
                                </Grid>
                              )
                            })
                          }
                          {
                            this.state.errorMessage !== null &&
                            <Box className="">
                              <Typography className="error-message-text">{this.state.errorMessage}</Typography>
                            </Box>
                          }
                        </Grid>
                      </div>
                    </Grid>
                }
                <Modal
                  style={{ background: "#f8f9fa", zIndex: 99 }}
                  open={this.state.previewModal}
                  onClose={this.closePreviewModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="asset-modal"
                  BackdropProps={{
                    className: "custom-modal-backdrop"
                  }}
                >
                  <Box className="modal-body">
                    <Box className="modal-header">
                      <IconButton data-test-id="btnCloseUploadModal1" disableRipple className="close-btn" onClick={() => this.closePreviewModal()}><img src={closeIcon} alt="close" /></IconButton>
                    </Box>
                    <Box className="modal-content">
                      <Grid container className="asset-main-row">
                        <Grid xs={9} md={9} className="asset-preview-col">
                          <Box className="preview-main-container">
                            <Carousel
                              className="Carousel"
                              navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                style: {
                                  boxShadow: '0px 5.33333px 21.33334px 0px rgba(0, 0, 0, 0.15)',
                                  backgroundColor: '#FFFFFF',
                                  color: '#000000',
                                  borderRadius: '66.667px',
                                  opacity: 0.6
                                }
                              }}
                              autoPlay={false}
                              navButtonsAlwaysVisible={true}
                              indicators={false}
                              onChange={(index: any) => {
                                let arrayData = this.state.assetsData.user_library?.data?.attributes.filter((item: any) => {
                                  return item.file_type !== 'links'
                                })
                                this.handleCarousel(arrayData?.[index].id)
                              }}
                            >
                              {
                                this.getAssetArray()?.filter((item: any) => {
                                  return item.file_type !== 'links'
                                })?.map((item: any, i: any) => {
                                  return (
                                    <Paper
                                      key={i}
                                      className="slide-inner-wrapper"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        // position: 'absolute',
                                        padding: item.file_type === "documents" ? '30' : '60',
                                        backgroundColor: 'transparent',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        // top:"50%",
                                        // transform:'translateY(-50%)'
                                      }}>
                                      {
                                        item.file_type === "documents" &&
                                        <Document
                                          loading={<Loader loading={true} />}
                                          options={{ workerSrc: `/pdf.worker.js` }}
                                          file={item.library_file_url} onLoadSuccess={this.onDocumentLoadSuccess}>
                                          <Page pageNumber={this.state.pageNumber} />
                                        </Document>
                                      }
                                      {
                                        item.file_type === "images" &&
                                        <Box className="image-wrap">
                                          <img src={item.library_file_url} />
                                        </Box>
                                      }
                                      {
                                        item.file_type === "videos" &&
                                        <div className="video-wrap">
                                          <video controls autoPlay>
                                            <source src={item.library_file_url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                        </div>
                                      }
                                    </Paper>
                                  )
                                })
                              }
                            </Carousel>
                          </Box>
                        </Grid>
                        <Grid xs={3} md={3} className="asset-info-col">
                          <Box className="preview-side-container">
                            {
                              this.state.singleAssetData.user_library.data?.attributes?.map((item: any, i: any) => {
                                return (
                                  <Box className="side-content-wrap">
                                    <Box className="side-content-title-block">
                                      <Typography className="side-container-heading">
                                        {capitalizeFirstLetter(item.name)}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Box className="file-info-list">
                                        <Typography className="file-info-heading" >File Info</Typography>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>File type</Typography></Box>
                                          <Box className="file-value-text"><Typography>{item.document_type}</Typography></Box>
                                        </Box>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>Date Added</Typography></Box>
                                          <Box className="file-value-text"><Typography>{getMonthDateFormat(item.date_added)}</Typography></Box>
                                        </Box>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>Size</Typography></Box>
                                          <Box className="file-value-text"><Typography>{item.document_size}</Typography></Box>
                                        </Box>
                                        {item.file_type === "documents" &&
                                          <Box className="file-info-item">
                                            <Box className="file-info-text"><Typography>Pages</Typography></Box>
                                            <Box className="file-value-text"><Typography>{item.pdf_pages}</Typography></Box>
                                          </Box>
                                        }
                                        {item.file_type === "videos" &&
                                          <>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Duration</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.duration}</Typography></Box>
                                            </Box>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Resolution</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.resolution}</Typography></Box>
                                            </Box>
                                          </>
                                        }
                                        {item.file_type === "images" &&
                                          <>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Resolution</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.resolution}</Typography></Box>
                                            </Box>
                                          </>
                                        }
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography className="file-info-text">Language</Typography></Box>
                                          <Box className="file-value-text"><Typography className="file-value-text">English</Typography></Box>
                                        </Box>
                                        {
                                          item.library_download === true &&
                                          <Box style={{ paddingTop: '10px', borderTop: '1px solid #DFE0E6' }}>
                                            <Typography className="share-text mt-16 mb-5" >Share</Typography>
                                            <Tooltip open={this.state.copiedText} title="Link copied to clipboard">
                                              <TextField
                                                id="share-input-height"
                                                value={item.library_file_url}
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                className="share-input"
                                                InputProps={{
                                                  style: { color: '#83889E', height: 60 },
                                                  readOnly: true,
                                                  endAdornment: <InputAdornment data-test-id="copy-test-id" onClick={() => {
                                                    this.openCopyText()
                                                    copyToClipboard(item.library_file_url)
                                                  }} className="share-pointer" position="end"><img src={copyIcon} /></InputAdornment>,
                                                }}
                                              />
                                            </Tooltip>
                                          </Box>
                                        }
                                      </Box>
                                      {
                                        item.library_download === true &&
                                        <Box className="a-download-button">
                                          <Button type="button" onClick={() => downloadFile(item.library_file_url, item.name)} className="custom-btn green-btn btn-sm">
                                            <Typography className="asset-download-text">
                                              Download
                                            </Typography>
                                          </Button>
                                        </Box>
                                      }
                                    </Box>
                                  </Box>
                                )
                              })
                            }
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Grid>
          </Grid>
        </Grid >
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
const previewModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1344px',
  bgcolor: 'background.paper',
  boxShadow: 3
};
// Customizable Area End
