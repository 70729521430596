import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Paper,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  IconButton,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];
import { comissionEarned, conversation, pdfView, upArrow, valuePipeline } from "./assets";
import Pagination from "@material-ui/lab/Pagination";
import { emptyData } from "../../companycontactpage2/src/assets";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import PageHeadingSkeleton from "../../../components/src/PageHeadingSkeleton";
import BoxSkeleton from "../../../components/src/BoxSkeleton";
import TableFilterSkeleton from "../../../components/src/TableFilterSkeleton";
import TableSkeleton from "../../../components/src/TableSkeleton";
import { getAmountValue, getColorsForStatus, getDateRangeText, getInvoiceLabel, getNullValues, getToken, truncateFileName } from "../../../components/src/commonUsage";
import { closeIcon, payment_pending } from "../../dashboard/src/assets";
import { ErrorMessage, Field, Formik } from "formik";
import Select from "react-select";
import { filesUploaded } from "../../leadmanagement/src/assets";
import Alert from "@material-ui/lab/Alert";
import { raiseInvoiceValidationSchema } from "../../../components/src/validations";
import { down_fall } from "../../contractmanagement2/src/assets";
import './css/style.css';
// Customizable Area End

import Commissionreports2Controller, {
  Props,
} from "./Commissionreports2Controller";

export default class Payout extends Commissionreports2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.fileInputRefNew = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getPayoutsData()
    if (getToken('partner_role') !== 'admin' && getToken('partner_id') !== "null") {
      this.navigateToComission()
    }
  }
  openFileInput = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  openFileInputNew = () => {
    if (this.fileInputRefNew.current) {
      this.fileInputRefNew.current.click();
    }
  };
  renderError(error: any) {
    if (error != null) {
      return (
        <Alert severity="error" icon={false}>
          {error}
        </Alert>
      );
    }
    return null;
  }
  conversionValue(value: number) {
    if (value === 0 || value === null) {
      return null;
    }
    const isPositive = value > 0; // Adjusted to consider 0 as neither positive nor negative
    const labelTextClass = isPositive ? "grid-up-text" : "grid-down-text";
    const arrowImage = isPositive ? upArrow : down_fall;
    const arrowAltText = isPositive ? "Up Arrow" : "Down Arrow";

    return (
      <>
        <label className={labelTextClass}>{value}%</label>
        <label><img src={arrowImage} alt={arrowAltText} /></label>
      </>
    );
  }
  renderPayoutData() {
    return <Box className="commission-tabbing-wrap">
      <Box className="custom-tabbing-wrap">
        <Tabs value={1}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#6200EA"
            }
          }}
          onChange={(event, newValue) => {
            this.handleTabChange(newValue)
          }}
          aria-label="basic tabs example" className="custom-tab-list">
          <Tab disableRipple label="Commission details" />
          <Tab disableRipple label="Payouts" />
        </Tabs>
      </Box>
      <Box className="custom-tab-content-wrap">
        <Box className="payout-detail-wrapper">
          <Box className="filter-block-wrapper ">
            <Box className="table-search-text filter-inner-wrap">
              <Box className="filter-left-block">
                <Box className="filter-left-inner-wrap">
                  <Typography className="search-results-text">{this.state.payoutsData.payout.data.length} item(s)</Typography>
                </Box>
              </Box>
              <Box className="filter-right-block">
              </Box>
            </Box>
          </Box>
          <Box className="commission-grid-wrapper">
            {
              this.state.payoutsData.empty_state === false ?
                <Box>
                  {
                    this.state.payoutsData.payout.data.length > 0 ?
                      <Box>
                        <TableContainer className="lead-table" component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead style={{ background: '#f8f9fa' }}>
                              <TableRow>
                                <TableCell style={{ width: '200px' }} className="lead-table-heading">Payouts</TableCell>
                                <TableCell style={{ width: '164px' }} className="lead-table-heading">sale value</TableCell>
                                <TableCell style={{ width: '160px' }} className="lead-table-heading">commission</TableCell>
                                <TableCell style={{ width: '160px' }} className="lead-table-heading">status</TableCell>
                                <TableCell style={{ width: '410px' }} className="lead-table-heading">commission payed</TableCell>
                                <TableCell style={{ width: '146px' }} className="lead-table-heading">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                this.state.payoutsData.payout.data.map((item: any, index: any) => {
                                  return <TableRow key={index}>
                                    <TableCell className="lead-table-data-company">
                                      {item.attributes.cycle_date}
                                    </TableCell>
                                    <TableCell className="lead-table-data-row">{item.attributes.sale}</TableCell>
                                    <TableCell className="lead-table-data-row">{item.attributes.commission}</TableCell>
                                    <TableCell className="lead-table-data-row">
                                      <Typography
                                        style={{
                                          textTransform: 'capitalize',
                                          display: 'inline-flex',
                                          padding: '2px 8px 1px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '32px',
                                          border: `1px solid ${getColorsForStatus(item.attributes.status).border}`,
                                          background: getColorsForStatus(item.attributes.status).surface,
                                          width: 'fit-content',
                                        }}
                                      ><label
                                        style={{
                                          color: getColorsForStatus(item.attributes.status).main,
                                          textAlign: 'center',
                                          fontSize: '12px',
                                          fontStyle: 'normal',
                                          fontWeight: 500,
                                          lineHeight: '15px',
                                          letterSpacing: '0.12px',
                                          whiteSpace: 'nowrap'
                                        }}>{getColorsForStatus(item.attributes.status).label}</label></Typography>

                                    </TableCell>
                                    <TableCell className="lead-table-data-row">{getNullValues(item.attributes.paid)}</TableCell>
                                    {
                                      item.attributes.status === 'Invoice pending' ?
                                        <TableCell className="lead-table-data-row">
                                          <Button
                                            onClick={() => this.openRaiseInvoiceModal(item)}
                                            data-test-id={"btnRaiseInvoice"}
                                            variant="contained"
                                            color="primary"
                                            className="heading-button"
                                          >Raise Invoice</Button>
                                        </TableCell>
                                        :
                                        <TableCell className="lead-table-data-row"><span className="view-btn" onClick={() => this.viewInvoiceModal(item)}>View invoice</span></TableCell>
                                    }
                                  </TableRow>
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box className="table-page-info-block">
                          <Box className="pagination-label-wrapper">
                            <Typography className="pagination-text">
                              Showing {this.getPayoutPageCount()} out of {this.state.payoutsData.meta.total_count} results
                            </Typography>
                          </Box>
                          <Box className="pagination-wrapper">
                            <Pagination count={this.state.payoutsData.meta.total_pages} onChange={(event, page) => this.handlePageChange(page)} page={this.state.currentPage || 1} shape="rounded" />
                          </Box>
                        </Box>
                      </Box>
                      :
                      <Box className="">
                        <Typography className="no-data-found">No payouts found</Typography>
                      </Box>
                  }
                </Box>
                :
                <Box className="empty-data-block height-lg">
                  <Box className="empty-content">
                    <Box className="empty-img">
                      <img src={emptyData} />
                    </Box>
                    <Box className="content">
                      <Typography className="title-sm">No commissions yet</Typography>
                      <Typography className="empty-sub-text">Add more deals to this dashboard and soon this data shall be generated</Typography>
                      <Box className="empty-btn">
                        <Button type="button" className="custom-btn gray-btn btn-sm" onClick={() => this.navigateToLeadManagement()}>
                          <Typography className="empty-button-text">
                            Register new deal
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { status, sale, cycle_date } = this.state.selectedInvoiceData.attributes
    const cData = getToken('comissionData') || null
    //@ts-ignore
    const commisionData = JSON.parse(cData)
    return (
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Commission Report', path: 'Commissionreports2' }} />
              <Box style={webStyles.mainContainer}>
                {this.state.isLoading ?
                  <Box className="skeleton-wrapper">
                    <PageHeadingSkeleton />
                    <BoxSkeleton hasFourCols={true}></BoxSkeleton>
                    <TableFilterSkeleton hasTabbing={true}></TableFilterSkeleton>
                    <TableSkeleton></TableSkeleton>
                  </Box>
                  :
                  <>
                    <Box className="lead-dashboard-heading dashboard-nav-container">
                      <Box className="dashboard-heading">
                        <Box>
                          <Typography className="deal-heading-main">
                            Commission Report
                          </Typography>
                          <Typography className="deal-heading-sub">
                            Commissions payed and remainder for this month below
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {
                      commisionData.empty_state === false ?
                        <Box className="lead-section-one">
                          <Grid item xs={12} md={12} container>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Total converted value</Typography>
                                <Box className="grid-counts">
                                  <Grid item xs={12} md={12} container>
                                    <Grid item className="image-block">
                                      <img src={conversation} alt="conversation" />
                                    </Grid>
                                    <Grid item className="grid-count-wrap">
                                      <Typography className="grid-count-text">
                                        {commisionData.total_conversion}
                                        {
                                          this.conversionValue(commisionData.previous_conversion)
                                        }
                                      </Typography>
                                      <Typography className="grid-count-sub-text">
                                        {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Commission earned</Typography>
                              </Box>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container>
                                  <Grid item className="image-block">
                                    <img src={comissionEarned} alt="Deals" />
                                  </Grid>
                                  <Grid item className="grid-count-wrap">
                                    <Typography className="grid-count-text">
                                      {commisionData.commission_earned}
                                      {
                                        this.conversionValue(commisionData.previous_commission)
                                      }
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid small-grid" item xs={4} md={4}>
                              <Box>
                                <Typography className="grid-text-heading">Value in pipeline</Typography>
                              </Box>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container>
                                  <Grid item className="image-block">
                                    <img src={valuePipeline} alt="Minimum commitement" />
                                  </Grid>
                                  <Grid item className="grid-count-wrap">
                                    <Typography className="grid-count-text">
                                      {commisionData.value_in_pipeline}
                                      {
                                        this.conversionValue(commisionData.previous_value_in_pipeline)
                                      }
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        :
                        <Box className="lead-section-one">
                          <Grid item xs={12} md={12} container className="empty-lead-block">
                            <Grid className="lead-section-one-grid-empty-one" item xs={6} md={6}>
                              <Box>
                                <Typography className="grid-text-empty-heading">No commissions yet</Typography>
                              </Box>
                              <Box>
                                <Typography className="grid-text-empty-sub-heading">Add more deals to this dashboad and soon the data will be generated for you to view</Typography>
                              </Box>
                            </Grid>
                            <Grid className="lead-section-one-grid-empty-two" item xs={6} md={6}>
                              <img src={payment_pending} style={{ maxWidth: '118px', marginTop: '-40px' }} />
                            </Grid>
                          </Grid>
                        </Box>
                    }
                    {this.renderPayoutData()}
                  </>
                }
                <Modal
                  open={this.state.addInvoiceModal}
                  onClose={this.closeModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="custom-modal confirmation-modal modal-sm add-member-modal"
                  BackdropProps={{
                    className: "custom-modal-backdrop"
                  }}
                >
                  <Box className="modal-body">
                    <Box>
                      <IconButton data-test-id="btn-close-invite-modal1" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                      <Box className="title-block">
                        <Typography variant="h4">
                          Raise invoice
                        </Typography>
                        <Typography>Upload PDF, to create invoice and receive payments.</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="form-inner-wrap">
                        <Box className="form-block-wrap">
                        </Box>
                        <Box>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              invoice_amount: '',
                              additional_details: '',
                              comission_amount: getAmountValue(sale),
                            }}
                            validationSchema={raiseInvoiceValidationSchema}
                            onSubmit={(values) => {
                              if (this.state.isUploaded) {
                                this.createInvoce(values);
                              } else {
                                this.setState({
                                  fileError: 'Invoice file required'
                                });
                              }
                            }}
                          >
                            {(formik) => (
                              <form onSubmit={formik.handleSubmit}>
                                <Box>
                                  <Box>
                                    <Grid className="grid-even-space">
                                      <Grid item xs={12} md={12}>
                                        <Typography className="invoice-modal-payout-label">PAYOUT PERIOD</Typography>
                                        <Typography className="invoice-modal-payout-value">{cycle_date}</Typography>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Typography className="invoice-modal-payout-label">PAYOUT AMOUNT</Typography>
                                        <Typography className="invoice-modal-payout-value">{sale}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>

                                  <Box className="mt-1">
                                    <div className="input-with-adornment">
                                      <Field name="deal_currency">
                                        {(field: any) => (
                                          <Select
                                            data-test-id="selectDealCurrency"
                                            {...field}
                                            name="deal_currency"
                                            styles={currencyStyles}
                                            placeholder="$"
                                            options={currencies}
                                            className="currency-dropdown"
                                            components={{
                                              IndicatorSeparator: () => null,
                                              DropdownIndicator: () => null,
                                            }}
                                            isDisabled
                                          />
                                        )}
                                      </Field>
                                      <TextField
                                        data-test-id="invoice_amount"
                                        name="invoice_amount"
                                        size="small"
                                        fullWidth
                                        label="Invoice Amount"
                                        variant="filled"
                                        value={formik.values.invoice_amount}
                                        onChange={formik.handleChange}
                                      />
                                    </div>
                                    <ErrorMessage className="deal-error-text" name="invoice_amount" component="div" />
                                  </Box>
                                  <Box className="mt-1">
                                    <TextField
                                      name="additional_details"
                                      fullWidth
                                      variant="filled"
                                      label="Additional details"
                                      multiline
                                      value={formik.values.additional_details}
                                      onChange={formik.handleChange}
                                      error={this.getFormikError(formik.touched.additional_details, formik.errors.additional_details)}
                                      helperText={this.getFormikHelperText(formik.touched.additional_details, formik.errors.additional_details)}
                                    />
                                  </Box>
                                  <Box className="mt-1">
                                    <Box className="upload-cvs-block">
                                      {/* add click functionality here */}
                                      <Box className="upload-csv-inner-wrap"
                                        onDragEnter={this.handleDragEnter}
                                        onDragOver={this.handleDragEnter}
                                        onDragLeave={this.handleDragLeave}
                                        onDrop={this.handleDrop}
                                      >
                                        <>
                                          {
                                            this.state.isUploaded ?
                                              < Box className="upload-content-wrap">
                                                <Box className="image-loader-block">
                                                  <img src={filesUploaded} alt=""></img>
                                                </Box>
                                                <Box>
                                                  <Typography className="selected-file-name">
                                                    {truncateFileName(this.state.selectedFile.name, 20)}
                                                  </Typography>
                                                </Box>
                                                < Box className="upload-content-wrap">
                                                  <Box className="content-wrap">
                                                    <input
                                                      type="file"
                                                      id="file-input"
                                                      accept=".pdf"
                                                      onChange={this.handleFileInputChange}
                                                      style={{ display: 'none' }}
                                                      ref={this.fileInputRefNew}
                                                    />
                                                    <Button data-test-id="open-file-input" onClick={this.openFileInputNew} disableRipple className="underline-link">Add new invoice</Button>
                                                  </Box>
                                                </Box>
                                              </Box>
                                              :
                                              < Box className="upload-content-wrap">
                                                <Box className="content-wrap"

                                                >
                                                  <Typography className="title">Upload invoice</Typography>
                                                  <Typography className="content">Drag & drop a pdf file to upload</Typography>
                                                  <input
                                                    type="file"
                                                    id="file-input"
                                                    accept=".pdf"
                                                    onChange={this.handleFileInputChange}
                                                    style={{ display: 'none' }}
                                                    ref={this.fileInputRef}
                                                  />
                                                  <Button data-test-id="open-file-input" onClick={this.openFileInput} disableRipple className="underline-link">Browse files</Button>
                                                </Box>

                                              </Box>
                                          }
                                        </>
                                      </Box>
                                    </Box>
                                    <Box>
                                      {this.state.fileError && <div className="deal-error-text">{this.state.fileError}</div>}

                                      {this.renderError(this.state.fileSizeError)}

                                      {this.renderError(this.state.fileTypeError)}
                                    </Box>
                                  </Box>

                                </Box>
                                <Box className="btn-wrap">
                                  <Button
                                    data-test-id="btn-close-raise-invoce"
                                    onClick={() => this.closeModal()}
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="button"
                                    className="custom-btn">Cancel</Button>
                                  <Button
                                    disabled={!(this.state.isUploaded && formik.values.invoice_amount !== '')}
                                    data-test-id="btn-invite-raise-invoce"
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    className="custom-btn green-btn">
                                    Submit
                                  </Button>
                                </Box>
                              </form>
                            )}
                          </Formik>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                <Modal
                  open={this.state.viewInvoiceModal}
                  onClose={this.closeModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="custom-modal confirmation-modal modal-sm add-member-modal"
                  BackdropProps={{
                    className: "custom-modal-backdrop"
                  }}
                >
                  <Box className="modal-body">
                    <Box>
                      <IconButton data-test-id="btn-close-invite-modal1" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                      <Box className="title-block">
                        <Typography variant="h4">
                          Payout details
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="form-inner-wrap">
                        <Box className="form-block-wrap">
                        </Box>
                        <Box>
                          <Box>
                            <Grid className="grid-even-space">
                              <Grid item xs={12} md={12}>
                                <Typography className="invoice-modal-view-label">Payout for {cycle_date}</Typography>
                                <Typography className="invoice-modal-view-value">{sale}</Typography>
                              </Grid>
                              <Grid item xs={12} md={12} style={{ justifyContent: 'right', display: 'flex' }}>
                                <Box className="invoice-pdf-view">
                                  <img src={pdfView} />
                                  <Typography className="invoice-pdf-name">Invoice.pdf</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="mt-1" style={{
                              borderBottom: '1px solid #DFE0E6'
                            }} />
                            <Box className="mt-1">
                              <Typography
                                style={{
                                  textTransform: 'capitalize',
                                  display: 'inline-flex',
                                  padding: '2px 8px 1px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '32px',
                                  border: `1px solid ${getColorsForStatus(status).border}`,
                                  background: getColorsForStatus(status).surface,
                                  width: 'fit-content',
                                }}
                              ><label
                                style={{
                                  color: getColorsForStatus(status).main,
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  lineHeight: '15px',
                                  letterSpacing: '0.12px',
                                  whiteSpace: 'nowrap'
                                }}>Payment {getColorsForStatus(status).label}</label></Typography>
                            </Box>
                            <Box>
                              <Typography>{getInvoiceLabel(status, "Failed due to incorrect invoice value")}</Typography>
                              <Typography>{getInvoiceLabel(status, "Transaction ID  TTCNI02200080059")}</Typography>
                            </Box>
                            {
                              status === 'Declined' &&
                              <Box className="mt-1">
                                <Button
                                  data-test-id="btn-raise-invoce"
                                  onClick={() => this.openReInvoiceModal()}
                                  fullWidth
                                  size="small"
                                  variant="contained"
                                  type="button"
                                  className="invoice-re-btn">
                                  <Typography className="invoice-re-btn-label">
                                    Raise invoice again
                                  </Typography>
                                </Button>
                              </Box>
                            }
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Grid>
          </Grid >
        </Grid >
      </Box >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '20px 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  clickEdit: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
const currencyStyles = {
  control: (styles: any) => ({
    ...styles,
    width: '66px',
    boxShadow: 'none',
    height: 60,
    borderRadius: '3px 0px 0px 3px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
    backgroundColor: "#F8F9FA",
  })
};
// Customizable Area End
