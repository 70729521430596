import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { generateFilterObject, getErrorResponse, getMemberErrorMessages, getToken, mapValues, removeAllToken } from "../../../components/src/commonUsage";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  assetsData: any;
  selectMenu: any;
  sortMenu: any;
  sortValue: any;
  selectValue: any;
  isLoading: any;
  errorMessage: any;
  currentPage: any;
  perPage: any;
  searchQuery: any;
  previewModal: any;
  copiedText: any;
  numPages: any;
  pageNumber: any;
  singleAssetData: any;
  selectedAssetId: any;
  lastList: any;
  prevPage: any;
  filterPopover: any;
  expanded: any;
  filterArray: any;
  checkedFilterItems: any,
  mappedArray: any,
  suggestionPopover: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssetsDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAssetsApiCallId: string = "";
  getSingleAssetApiCallId: string = "";
  listInnerRef: any = createRef();
  getAssetsSortSearchApiCallId: string = "";
  getFiltersApiCallId: string = "";
  getAssetsFilterApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      assetsData: {
        "user_library": {
          "data": {
            attributes: []
          },
          "meta": {
            "total_pages": 0,
            "total_count": 0,
            "current_page": 0,
            "next_page": 0,
            "previous_page": null
          }
        }
      },
      selectMenu: null,
      sortMenu: null,
      sortValue: "Latest",
      selectValue: 'all',
      isLoading: false,
      errorMessage: null,
      currentPage: 1,
      perPage: 12,
      searchQuery: '',
      previewModal: false,
      copiedText: false,
      numPages: null,
      pageNumber: 1,
      filterPopover: null,
      expanded: false,
      singleAssetData: {
        "user_library": {
          "data": {
            attributes: []
          },
          "meta": {
            "total_pages": 0,
            "total_count": 0,
            "current_page": 0,
            "next_page": 0,
            "previous_page": null
          }
        }
      },
      selectedAssetId: 0,
      lastList: false,
      prevPage: 0,
      filterArray: [
        {
          "category_value": "file_type",
          "category_name": "file type",
          "category_id": "file_type",
          "values": [
            {
              "value_name": "images",
              "value_id": "images"
            },
            {
              "value_name": "videos",
              "value_id": "videos"
            },
            {
              "value_name": "documents",
              "value_id": "documents"
            }
          ]
        }
      ],
      checkedFilterItems: [],
      mappedArray: [],
      suggestionPopover: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (getMemberErrorMessages(responseJson)) {
        removeAllToken()
        this.props.navigation.navigate('EmailAccountLoginBlock')
      } else {
        switch (apiRequestCallId) {
          case this.getAssetsApiCallId:
            if (!responseJson.errors && responseJson.user_library.meta.next_page === null) {
              this.setState({
                lastList: true
              })
            }
            this.setState((prevState) => ({
              isLoading: false,
              prevPage: prevState.assetsData.user_library.meta.current_page,
              assetsData: {
                user_library: {
                  data: {
                    attributes: [...prevState.assetsData.user_library.data.attributes, ...responseJson.user_library.data.attributes],
                  },
                  meta: {
                    ...prevState.assetsData.user_library.meta,
                    ...responseJson.user_library.meta,
                  },
                },
              },
            }));
            break;
          case this.getAssetsSortSearchApiCallId:
          case this.getAssetsFilterApiCallId:
            const emptyAssetsData = {
              "user_library": {
                "data": {
                  attributes: []
                },
                "meta": {
                  "total_pages": 0,
                  "total_count": 0,
                  "current_page": 0,
                  "next_page": 0,
                  "previous_page": null
                }
              }
            };

            if (responseJson.errors) {
              this.setState({
                errorMessage: responseJson.errors[0].not_found_error,
                assetsData: emptyAssetsData,
                lastList: false
              });
            } else {
              this.setState({
                isLoading: false,
                assetsData: responseJson.user_library || emptyAssetsData, // Assuming user_library is the correct property
                currentPage: responseJson.user_library.meta.current_page,
                lastList: responseJson.user_library.meta.next_page === null
              }, () => {
                this.handleResponse(responseJson, false, '', 'assetsData');
              });
            }
            break; // Don't forget to add a break statement to end the case block
          case this.getSingleAssetApiCallId:
            this.setState({
              isLoading: false,
            })
            this.handleResponse(responseJson, false, '', 'singleAssetData')
            break;
          case this.getFiltersApiCallId:
            this.handleResponse(responseJson, false, '', 'filterArray')
          default:
            // Handle the default case here
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponse(responseJson: any, navigaion: any, path: any, statedata: any) {
    if (!responseJson.errors) {
      if (navigaion) {
        this.props.navigation.navigate(path);
      } else {
        const updatedState = { ...this.state, [statedata]: responseJson };
        this.setState(updatedState);
      }
    } else {
      // Check Error Response
      this.setState({
        errorMessage: getErrorResponse(responseJson)
      });
    }
  }

  handleSearchValue = (text: string) => {
    this.setState(
      {
        searchQuery: text,
        checkedFilterItems: []
      },
      () => {
        this.setState({
          currentPage: 1,
          lastList: false
        }, () => {
          this.getAssets('sortSearch');
        });
      }
    );
  };


  handleSortClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ sortMenu: event.currentTarget })
    }
  };

  handleSortClose = (text: any) => {
    this.setState({ sortValue: text, sortMenu: null }, () => {
      if (this.state.checkedFilterItems.length > 0) {
        this.getAssets('filter')
      } else {
        this.getAssets('sortSearch')
      }
    })
  };
  handleSelectClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ selectMenu: event.currentTarget })
    }
  };

  handleSelectClose = (text: any) => {
    this.setState({ selectValue: text, selectMenu: null }, () => {
      this.getAssets('sortSearch')
    });
  };
  handleMenuClose = () => {
    this.setState({ sortMenu: null, selectMenu: null });
  }
  getAssets = async (type: any) => {
    if (this.state.currentPage > 1) {
      this.setState({ errorMessage: null });
    } else {
      this.setState({ isLoading: true, errorMessage: null });
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    if (type === 'normal') {
      this.getAssetsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAssetsEndPoint}?filter=false&page=${this.state.currentPage}&per_page=${this.state.perPage}&search_query=${this.state.searchQuery}&sort=${this.state.sortValue}`
      );
    }
    if (type === 'sortSearch') {
      this.getAssetsSortSearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAssetsEndPoint}?filter=false&search_query=${this.state.searchQuery}&sort=${this.state.sortValue}`
      );
    }
    if (type === 'filter') {

      const httpBody = generateFilterObject(this.state.mappedArray)

      this.getAssetsFilterApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAssetsEndPoint}?filter=true&sort=${this.state.sortValue}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  openPreviewModal(id: any) {
    this.getSingleAsset(id)
    this.setState({
      previewModal: true,
      selectedAssetId: id
    })
  }
  closePreviewModal() {
    this.setState({
      previewModal: false
    })
  }
  openCopyText() {
    this.setState({
      copiedText: true
    })
    setTimeout(() => {
      this.setState({
        copiedText: false
      })
    }, 3000)
  }
  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
  };
  getSingleAsset = (assetId: any) => {
    this.setState({ isLoading: true, errorMessage: null });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const httpBody =
    {
      "data":
      {
        id: assetId
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleAssetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSingleAssetEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCarousel(index: any) {
    this.openPreviewModal(index)
    this.setState({
      selectedAssetId: 0
    })
  }
  getAssetArray() {
    if (this.state.selectedAssetId !== 0) {
      return this.state.assetsData.user_library?.data?.attributes?.filter((data: any) => {
        return data.id === this.state.selectedAssetId
      })
    } else {
      return this.state.assetsData.user_library?.data?.attributes?.map((data: any) => {
        return data
      })
    }
  }
  onScroll = () => {
    if (!this.state.isLoading) {
      if (this.listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = this.listInnerRef.current;
        if (Math.round(scrollTop + clientHeight) === scrollHeight) {
          if (!this.state.lastList) {
            this.setState((prevState) => ({ currentPage: prevState.currentPage + 1 }), () => {
              if (!this.state.lastList && this.state.prevPage !== this.state.currentPage) {
                this.getAssets('normal');
                this.setState({ lastList: false })
              }
            });
          }
        }
      }
    }
  };

  getFilters = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getFiltersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFiltersEndPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFilterClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ filterPopover: event.currentTarget })
    }
  };
  handleFilterClose = () => {
    this.setState({ filterPopover: null }, () => {
    })
  };
  handleFilterCategoryChange = (panel: string) => (event: any, isExpanded: boolean) => {

    this.setState({ expanded: isExpanded ? panel : false }, () => {
    })

  };
  handleChangeFilter = (name: any, checked: any, filter: any,) => {
    this.setState({
      searchQuery: ''
    })
    this.setState((prevState) => {
      const { checkedFilterItems } = prevState;
      const filterIndex = checkedFilterItems.findIndex((item: any) => item[filter] !== undefined);

      if (checked) {
        const updatedFilterItems = filterIndex !== -1
          ? checkedFilterItems.map((item: any, index: any) =>
            index === filterIndex
              ? { ...item, [filter]: Array.from(new Set([...item[filter], name])) }
              : item
          )
          : [...checkedFilterItems, { [filter]: [name] }];

        return { checkedFilterItems: updatedFilterItems };
      } else {


        const updatedFilterItems = filterIndex !== -1 ? checkedFilterItems
          .map((item: any, index: any) =>
            index === filterIndex
              ? { ...item, [filter]: item[filter].filter((itemName: any) => itemName !== name) }
              : item
          ) :
          checkedFilterItems

        const filteredData = updatedFilterItems.filter((item: any) => Object.values(item).some((property: any) => property.length > 0))

        const isEmpty = filteredData.length === 0;

        if (isEmpty) {
          this.clearFilters()
        }

        return { checkedFilterItems: filteredData };
      }
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.checkedFilterItems !== this.state.checkedFilterItems) {
      if (this.state.checkedFilterItems.length > 0) {
        let mappedArray = mapValues(this.state.filterArray, this.state.checkedFilterItems)
        this.setState({ mappedArray: mappedArray }, () => {
          this.getAssets('filter')
        })
      }
    }
  }

  clearFilters = () => {
    const emptyAssetsData = {
      "user_library": {
        "data": {
          attributes: []
        },
        "meta": {
          "total_pages": 0,
          "total_count": 0,
          "current_page": 0,
          "next_page": 0,
          "previous_page": null
        }
      }
    };
    this.setState({
      checkedFilterItems: [],
      currentPage: 1,
      lastList: false,
      assetsData: emptyAssetsData
    }, () => {
      this.handleFilterClose()
      this.getAssets('normal')
    })
  }

  removeFilters = (value: any) => {
    let newArray = this.state.checkedFilterItems.filter((item: any) => !item.hasOwnProperty(value));
    if (newArray.length === 0) {
      this.clearFilters()
    } else {
      this.setState({
        checkedFilterItems: newArray,
        currentPage: 1,
        lastList: false,
        assetsData: {
          "user_library": {
            "data": {
              attributes: []
            },
            "meta": {
              "total_pages": 0,
              "total_count": 0,
              "current_page": 0,
              "next_page": 0,
              "previous_page": null
            }
          }
        },
      }, () => {
        this.handleFilterClose()
      })
    }
  }

  viewFile = (value: any) => {
    window.open(value, '_blank');
  }


  handleSuggestionClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ suggestionPopover: event.currentTarget })
    }
  };
  handleSuggestionClose = () => {
    this.setState({ suggestionPopover: null }, () => {
    })
  };
  // Customizable Area End
}
