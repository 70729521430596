import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./css/style.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import Loader from "../../../components/src/Loader.web";
import { siteLogo } from "./assets";
//@ts-ignore
import loginBg from '../assets/login_background.png';
import { Formik } from 'formik';
import { memeberInviteSchema } from "../../../components/src/validations";
import { getHideUnHidePassword } from "../../../components/src/commonUsage";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { openEye, visiblityOff } from "../../email-account-login/src/assets";

export default class MemberInvite extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {
          this.state.loader ?
            <Loader loading={this.state.loader} />
            :
            <Box>
              <div className="member-invite-block"
                style={{
                  background: `url(${loginBg})`
                }}
              >
                <Box className="invite-inner-wrap">
                  <Box className="invite-content-block">
                    <Box className="logo-wrap">
                      <img src={siteLogo} alt="logo"></img>
                    </Box>
                    <Box className="form-title-block">
                      <Typography variant="h3" className="form-title">Welcome partner!</Typography>
                      <Typography>Set your password for <b>{this.state.email}</b> to get started.</Typography>
                    </Box>
                    <Box className="invite-form-wrapper">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          password: "",
                        }}
                        validationSchema={memeberInviteSchema}
                        onSubmit={(values) => {
                          this.memberPasswordInvite(values);
                        }}
                      >
                        {(formik) => (
                          <form onSubmit={formik.handleSubmit}>
                            <Grid container className="form-row has-two-col">
                              <Grid item md={6} xs={6} className="form-col">
                                <TextField
                                  name="first_name"
                                  size="small"
                                  variant="filled"
                                  type="text"
                                  label={"First Name"}
                                  fullWidth={true}
                                  error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                                  helperText={this.getFormikHelperText(formik.touched.first_name, formik.errors.first_name)}
                                  onChange={
                                    formik.handleChange
                                  }
                                />
                              </Grid>
                              <Grid item md={6} xs={6} className="form-col">
                                <TextField
                                  name="last_name"
                                  size="small"
                                  variant="filled"
                                  type="text"
                                  label={"Last Name"}
                                  fullWidth={true}
                                  error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                                  helperText={this.getFormikHelperText(formik.touched.last_name, formik.errors.last_name)}
                                  onChange={
                                    formik.handleChange
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container className="form-row">
                              <Grid item xs={12} md={12}>
                                <TextField
                                  name="password"
                                  size="small"
                                  variant="filled"
                                  data-test-id="txtInputCurrentPassword"
                                  label={"Set Password"}
                                  type={this.getTextPassword(this.state.enablePasswordField, "password", "text")}
                                  fullWidth={true}
                                  InputProps={{
                                    endAdornment: <InputAdornment
                                      onClick={this.handleClickShowPassword}
                                      className="share-pointer" position="end">
                                      <IconButton>
                                        {
                                          getHideUnHidePassword(this.state.enablePasswordField, <img src={openEye} />, <img src={visiblityOff} />)
                                        }
                                      </IconButton>
                                    </InputAdornment>
                                  }}
                                  error={this.getFormikError(formik.touched.password, formik.errors.password)}
                                  helperText={this.getFormikHelperText(formik.touched.password, formik.errors.password)}
                                  onChange={
                                    formik.handleChange
                                  }
                                />
                              </Grid>
                              {
                                this.state.errorMessage !== null &&
                                <Box>
                                  <Typography className="error-text">
                                    {this.state.errorMessage}
                                  </Typography>
                                </Box>
                              }
                            </Grid>
                            <Box className="btn-wrap">
                              <Button type="submit" className="custom-btn green-btn full-width">Sign up</Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
