export const deals = require("../assets/deals.svg");
export const leads = require("../assets/leads.svg");
export const conversion = require("../assets/conversion.svg");
export const up_arrow = require("../assets/up_arrow.svg");
export const left_arrow = require("../assets/left_arrow.svg");
export const down_arrow = require("../assets/down_arrow.svg");
export const dollar = require("../assets/dollar.svg");
export const payment_pending = require("../assets/payment_pending.svg");
export const empty_graph = require("../assets/empty_graph.svg");
export const empty_conversion = require("../assets/empty_conversion.svg");
export const empty_deals = require("../assets/empty_deals.svg");
export const empty_bars = require("../assets/group_bars.svg");
export const prospecting_img = require("../assets/prospecting.svg");
export const tracking_img = require("../assets/tracking.svg");
export const engagement_img = require("../assets/engagement.svg");
export const closed_won_img = require("../assets/closed_won.svg");
export const closed_lost_img = require("../assets/closed_lost.svg");
export const closeIcon = require("../assets/close.svg");
export const site_logo = require("../assets/site_logo.svg");
export const temp_graph = require("../assets/temp-graphs.png");