import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Menu,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Modal,
  Tooltip,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { downArrow, searchIcon } from "../../assetsdashboard/src/assets";
import { closeIcon, downloadIcon } from "../../leadmanagement/src/assets";
import { checkMark, editIcon, emptyData } from "./assets";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { ErrorMessage, Field, Formik } from "formik";
import Select from "react-select"
import { contactUpdateSchema } from "../../../components/src/validations";
import { capitalizeFirstLetter, getCheckMarkIcon } from "../../../components/src/commonUsage";
import { CSVLink } from "react-csv";
// Customizable Area End

import Companycontactpage2Controller, {
  Props,
} from "./Companycontactpage2Controller";
import TableSkeleton from "../../../components/src/TableSkeleton";

export default class ContactWeb extends Companycontactpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getListingData('contact', 'normal')
    await this.getSelectCompanies()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortMenu } = this.state;
    const sortOpen = Boolean(sortMenu);
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>

        <Grid className="filter-block-wrapper">
          <Box className="table-search-text filter-inner-wrap">
            <Box className="filter-left-block">
              {
                this.state.searchQuery.length > 0 ?
                  <Typography className="search-results-text">{`Search results for "${this.state.searchQuery}"`}</Typography>
                  :
                  <Typography className="open-deals-sub-text">Contacts ({this.state.listingData?.meta?.total_count})</Typography>
              }
            </Box>
            <Box className="filter-right-block" >
              <Grid container style={webStyles.rightAlign}>
                <Grid className="mr-16">
                  {
                    <div>
                      <Button
                        type="button"
                        className="sort-deal-button"
                        id="sort-button"
                        aria-haspopup="true"
                        onClick={(event) => this.handleSortClick(event)}
                      >
                        <Typography className="asset-sort-text">
                          Sort By:
                        </Typography>
                        <Typography className="text-transform sort-value-text">
                          {this.state.sortValue}
                          <label className="dropdown-arrow"> <img src={downArrow} /></label>
                        </Typography>
                      </Button>
                      <Menu
                        style={{
                          top: '52px',
                          borderRadius: '8px'
                        }}
                        id="sort-menu"
                        aria-labelledby="sort-button"
                        anchorEl={sortMenu}
                        open={sortOpen}
                        onClose={this.handleMenuClose}
                        className="sort-popover"
                      >
                        <MenuItem className="menu-item-text" onClick={() => this.handleSortClose("Latest", 'contact')}>Latest {getCheckMarkIcon('latest', checkMark, this.state.sortValue)}</MenuItem>
                        <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('Oldest', 'contact')}>Oldest {getCheckMarkIcon('oldest', checkMark, this.state.sortValue)}</MenuItem>
                        <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('A-Z', 'contact')}>A-Z {getCheckMarkIcon('A-Z', checkMark, this.state.sortValue)}</MenuItem>
                        <MenuItem className="menu-item-text" onClick={() => this.handleSortClose('Z-A', 'contact')}>Z-A {getCheckMarkIcon('Z-A', checkMark, this.state.sortValue)}</MenuItem>
                      </Menu>
                    </div>
                  }
                </Grid>
                <Grid className="mr-16">
                  <TextField
                    className="search-input"
                    fullWidth
                    size="small"
                    placeholder="Search Contacts"
                    variant="outlined"
                    onChange={(e) => this.handleSearchValue(e.target.value, 'contact')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={searchIcon} className="ml-10" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid>
                  <Box>
                    <Tooltip title="Download CSV" placement="top" arrow>
                      <CSVLink className="download-icon" filename="contacts" data={this.state.csvData.data} headers={this.state.csvData.headers?.map((item: any) => {
                        return { label: item.label, key: item.label }
                      })}>
                        <img src={downloadIcon} />
                      </CSVLink>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid className="mt-1">
          {this.state.isLoading ?
            // <Loader loading={this.state.isLoading} contentLoader={true} />
            <Box className="skeleton-wrapper">
              {/* <TableFilterSkeleton></TableFilterSkeleton> */}
              <TableSkeleton></TableSkeleton>
            </Box>
            :
            <>
              {
                this.state.listingData.total_contacts > 0 ?
                  <Box>
                    {
                      this.state.listingData.contact?.data.length > 0 ?
                        <>
                          <TableContainer className="lead-table" component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead style={{ background: '#f8f9fa' }}>
                                <TableRow style={{ height: '52px' }}>
                                  <TableCell className="lead-table-heading" style={{ width: '200px' }}>CONTACT NAME</TableCell>
                                  <TableCell className="lead-table-heading" style={{ width: '200px' }}>EMAIL</TableCell>
                                  <TableCell className="lead-table-heading" style={{ width: '200px' }}>COMPANY</TableCell>
                                  <TableCell className="lead-table-heading" style={{ width: '480px' }}></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.listingData.contact?.data?.map((row: any, index: number) => (
                                  <TableRow key={index} className="row-container">
                                    <TableCell className="lead-table-data-company captialize-name">
                                      {capitalizeFirstLetter(row.attributes.first_name)} {row.attributes.last_name}
                                    </TableCell>
                                    <TableCell className="lead-table-data-row">
                                      {row.attributes.customer_email}
                                    </TableCell>
                                    <TableCell className="lead-table-data-row">
                                      {capitalizeFirstLetter(row.attributes.company_lead.company_name)}
                                    </TableCell>
                                    <TableCell className="lead-table-data-company">
                                      <div
                                        className={`edit-cell ${this.state.editView ? "show" : ""}`}
                                        onFocus={() => this.setEditView()}
                                        onBlur={() => this.closeEditView()}
                                        style={webStyles.clickEdit}
                                      >
                                        <div className="edit-button" onClick={() => this.setSingleContactData(row.attributes)}>
                                          <img src={editIcon} className="mr-4" />Edit details
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {
                            this.state.listingData.meta?.total_count > 10 &&
                            <Box className="table-page-info-block">
                              <Box className="pagination-label-wrapper">
                                <Typography className="pagination-text">
                                  Showing {this.getPageCount('contact')} out of {this.state.listingData.meta?.total_count} results
                                </Typography>
                              </Box>
                              <Box className="pagination-wrapper">
                                <Pagination count={this.state.listingData.meta?.total_pages} onChange={(event: any, page: any) => this.handlePageChange(page, 'contact')} page={this.state.currentPage || 1} shape="rounded" />
                              </Box>
                            </Box>
                          }
                        </>
                        :
                        <Box className="">
                          <Typography className="no-data-found">No contacts found</Typography>
                        </Box>
                    }
                  </Box>
                  :
                  <Box>
                    <Box>
                      <Table aria-label="simple table">
                        <TableHead style={{ background: '#f8f9fa' }}>
                          <TableRow>
                            <TableCell className="lead-table-heading" style={{ width: '200px' }}>CONTACT NAME</TableCell>
                            <TableCell className="lead-table-heading" style={{ width: '200px' }}>EMAIL</TableCell>
                            <TableCell className="lead-table-heading" style={{ width: '200px' }}>COMPANY</TableCell>
                            <TableCell className="lead-table-heading" style={{ width: '480px' }}></TableCell>

                          </TableRow>
                        </TableHead>
                      </Table>
                    </Box>
                    <Box className="empty-data-block height-lg">
                      <Box className="empty-content">
                        <Box className="empty-img">
                          <img src={emptyData} />
                        </Box>
                        <Box className="content">
                          <Typography className="empty-main-text">Get started by adding deals.</Typography>
                          <Typography className="empty-sub-text">Add more deals to this dashboad and soon this data shall be generated.</Typography>
                          <Box className="empty-btn">
                            <Button onClick={() => this.navigateToNewDeal()} className="custom-btn gray-btn btn-sm">
                              <Typography className="empty-button-text">
                                Register new deal
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
              }
            </>
          }
          <Modal
            open={this.state.contactEdit}
            onClose={this.closeEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="custom-modal confirmation-modal modal-sm"
            BackdropProps={{
              className: "custom-modal-backdrop"
            }}
          >
            <Box className="modal-body">
              <Box>
                <IconButton disableRipple className="close-btn" onClick={() => this.closeEditModal()}><img src={closeIcon} alt="close" /></IconButton>
                <Typography variant="h4">
                  {this.state.singleContactData.first_name} {this.state.singleContactData.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography className="edit-contact-label">
                  View customer details
                </Typography>
                <Formik
                  enableReinitialize
                  initialValues={{
                    id: this.state.singleContactData?.id,
                    customer_email: this.state.singleContactData.customer_email,
                    company_name: { value: this.state.singleContactData.company_lead.id, label: this.state.singleContactData.company_lead.company_name },
                    first_name: this.state.singleContactData.first_name,
                    last_name: this.state.singleContactData.last_name,
                  }}
                  validationSchema={contactUpdateSchema}
                  onSubmit={(values) => {
                    this.updateContact(values)
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Box >
                        <Box className="mt-1">
                          <Box>
                            <Grid container>
                              <Grid xs={6} md={6}>
                                <TextField
                                  name="first_name"
                                  size="small"
                                  fullWidth
                                  className="width-98"
                                  id="input-with-icon-textfield"
                                  label="First Name"
                                  variant="filled"
                                  value={formik.values.first_name}
                                  onChange={formik.handleChange}
                                  error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                                  helperText={this.getFormikHelperText(formik.touched.first_name, formik.errors.first_name)}
                                />
                              </Grid>
                              <Grid xs={6} md={6}>
                                <TextField
                                  name="last_name"
                                  size="small"
                                  fullWidth
                                  id="input-with-icon-textfield"
                                  label="Last Name"
                                  variant="filled"
                                  value={formik.values.last_name}
                                  onChange={formik.handleChange}
                                  error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                                  helperText={this.getFormikHelperText(formik.touched.last_name, formik.errors.last_name)}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box className="mt-1">
                          <TextField
                            name="customer_email"
                            size="small"
                            fullWidth
                            id="input-with-icon-textfield"
                            label="Customer email"
                            variant="filled"
                            value={formik.values.customer_email}
                            onChange={formik.handleChange}
                            error={this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)}
                            helperText={this.getFormikHelperText(formik.touched.customer_email, formik.errors.customer_email)}
                          />
                        </Box>
                        <Box className="mt-1">
                          <Box className="custom-search-dropdown-wrapper is-focused">
                            <Field name="company_name">
                              {(field: any) => (
                                <>
                                  {
                                    field.field.value !== null &&
                                    <label className="select-label">Company Name</label>
                                  }
                                  <Select
                                    {...field}
                                    value={formik.values.company_name}
                                    options={this.state.companyArray?.map((item: any) => {
                                      return { value: item.id, label: item.name }
                                    })}
                                    name="company_name"
                                    styles={selectStyle}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    onChange={(option) =>
                                      formik.setFieldValue('company_name', option)
                                    }
                                    placeholder="Company Name"
                                    className="custom-search-select xsmall-listbox"
                                  />
                                </>
                              )}
                            </Field>
                            <ErrorMessage className="deal-error-text" name="company_name" component="div" />
                          </Box>
                        </Box>
                        <Box className="btn-wrap">
                          <Button
                            onClick={() => this.closeEditModal()}
                            fullWidth
                            size="small"
                            variant="contained"
                            type="button"
                            className="custom-btn">Cancel</Button>
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            type="submit"
                            className="custom-btn green-btn">Save</Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>

            </Box>
          </Modal>
        </Grid>

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '20px 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  clickEdit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
const EditModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 3,
  p: 4,
};
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 61,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};
// Customizable Area End
