Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LeadManagement";
exports.labelBodyText = "LeadManagement Body";
exports.btnExampleTitle = "CLICK ME";
exports.btnTitle = "Submit";
exports.openCamera = "Open Camera";
exports.selectFromGallery = "Select From Gallery";
exports.cancel = "Cancel";
exports.headerTitle = "Lead Management";
exports.typeText = "text";
exports.typeDropdown = "dropdown";
exports.typeCheckbox = "checkbox";
exports.typeRadio = "radio";
exports.typeTextArea = "textarea";
exports.typeSubmitButton = "submit button";
exports.getInputDataEndPoint = "bx_block_leadmanagement/forms/4";
exports.postInputData = "bx_block_leadmanagement/create";
exports.createNewLeadEndPoint = "bx_block_leadmanagement/leads";
exports.companyListingEndPoint = "bx_block_leadmanagement/leads/company_listing"
exports.industryListingEndPoint = "bx_block_leadmanagement/leads/industry_listing"
exports.getSingleLeadEndPoint = "bx_block_leadmanagement/leads/show_lead"
exports.searchLeadEndPoint = "bx_block_leadmanagement/leads/search_leads"
exports.sortLeadEndPoint = "bx_block_leadmanagement/leads/sort_lead"
exports.putMethod = "PUT"
exports.deleteMethod = "DELETE"
exports.filterLeadEndPoint = "bx_block_leadmanagement/leads/filter_status"
exports.fileSizeError="Your file is too large to handle. Reduce the size of the file below [5MB] and try again."
exports.fileTypeError="Your file is in a wrong format. Convert the file to .csv and try again."
exports.csvCheckEndPoint = "bx_block_leadmanagement/leads/check_leads"
exports.csvUploadEndPoint = "bx_block_leadmanagement/leads/upload_leads"
exports.sampleCsvDownloadEndPoint = "bx_block_leadmanagement/leads/lead_csv_file"
exports.countryListingEndPoint = "bx_block_leadmanagement/leads/country_listing"
// Customizable Area End