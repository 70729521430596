import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import "./css/styles.css";
import {
  conversion,
  deals,
  leads,
  left_arrow,
  up_arrow,
  dollar,
  payment_pending,
  empty_graph,
  empty_conversion,
  empty_deals,
  empty_bars,
  prospecting_img,
  tracking_img,
  closed_lost_img,
  closed_won_img,
  engagement_img,
  closeIcon,
  site_logo,
  temp_graph
} from "./assets";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { graphBars } from "../../leadmanagement/src/assets";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler
);

import { calendarFilterArray, donughtData, getCalendarByValue, getCheckMarkIcon, getDashboardName, getDashboardValues, getDateRangeText, getIntitals, lineData } from "../../../components/src/commonUsage";
import { downArrow } from "../../assetsdashboard/src/assets"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { checkMark } from "../../companycontactpage2/src/assets";
import 'chartjs-plugin-zoom';
import DashboardSkeleton from "./DashboardSkeleton";
import AllTimeGraph from "../../../components/src/allTimeGraph";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { calendarMenu } = this.state;
    const sortCalendarOpen = Boolean(calendarMenu);
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar
                currentPage={{ name: "Dashboard", path: "DashboardWeb" }}
              />
              <Box style={webStyles.mainContainer}>
                <div className="dashboard-nav-container">
                  <div className="dashboard-heading">
                    {/* <div>
                      <Avatar
                        className="avtar-block purple-bg"
                        src={`${this.state.dashboardData.partner.partner_image.url}?${Date.now()}`}
                        alt=""
                        style={{ width: 52, height: 52, marginRight: 19 }}
                      >{getIntitals(getDashboardName(this.state.dashboardData))}</Avatar>
                    </div> */}
                    <div>
                      <p className="bredcrumb-text-dashboard">
                        Welcome to Builder.ai partners portal
                      </p>
                      <h4 className="heading-text">{getDashboardName(this.state.dashboardData)}</h4>
                    </div>
                  </div>
                  <Grid className="dashboard-calendar-container">
                    <Grid item style={webStyles.rightAlign}>
                      <div className="date-range-wrapper">
                        <p className="date-range-text">
                          Show data and deals over
                        </p>
                        <div className="display-flex ml-6">
                          <Button
                            className="select-deal-button"
                            id="select-button"
                            aria-haspopup="true"
                            data-test-id={"btnCalendarMenuOpen"}
                            onClick={this.handleCalendarClick}
                          >
                            <Typography className="select-stage-text">
                              {getCalendarByValue(this.state.calanderValue)}
                              <label className="dropdown-arrow">
                                {" "}
                                <img src={downArrow} />
                              </label>
                            </Typography>
                          </Button>
                          <Menu
                            style={{
                              top: "52px",
                              borderRadius: "8px",
                            }}
                            id="select-menu"
                            aria-labelledby="select-button"
                            anchorEl={calendarMenu}
                            open={sortCalendarOpen}
                            onClose={this.handleCalendarClose}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                            PaperProps={{
                              className: 'date-range-menu-paper', // Add your custom class name here
                            }}
                          >
                            {
                              calendarFilterArray.map((item: any, index: any) => {
                                return <MenuItem data-test-id={`selectCalanderMenu${index}`} key={item.value} className="menu-item-text" onClick={() => this.handleCalendarSelect(item.value)}>{item.label}{getCheckMarkIcon(item.value, checkMark, this.state.calanderValue)}</MenuItem>
                              })
                            }


                          </Menu>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={webStyles.rightAlign}>
                    <Button
                      onClick={() =>
                        this.navigateToLeadManagement()
                      }
                      data-test-id={"btnRegisteredDealLead1"}
                      variant="contained"
                      color="primary"
                      className="heading-button"
                    >
                      Register Deal/Lead
                    </Button>
                  </div>
                </div>
                {/* <Grid className="dashboard-calendar-container" container>
                  <Grid item xs={12} md={12} style={webStyles.rightAlign}>
                    <div className="date-range-wrapper">
                      <p className="date-range-text">
                        Show data and deals over
                      </p>
                      <div className="display-flex ml-6">
                        <Button
                          className="select-deal-button"
                          id="select-button"
                          aria-haspopup="true"
                          data-test-id={"btnCalendarMenuOpen"}
                          onClick={this.handleCalendarClick}
                        >
                          <Typography className="select-stage-text">
                            {getCalendarByValue(this.state.calanderValue)}
                            <label className="dropdown-arrow">
                              {" "}
                              <img src={downArrow} />
                            </label>
                          </Typography>
                        </Button>
                        <Menu
                          style={{
                            top: "52px",
                            borderRadius: "8px",
                          }}
                          id="select-menu"
                          aria-labelledby="select-button"
                          anchorEl={calendarMenu}
                          open={sortCalendarOpen}
                          onClose={this.handleCalendarClose}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          PaperProps={{
                            className: 'date-range-menu-paper', // Add your custom class name here
                          }}
                        >
                          {
                            calendarFilterArray.map((item: any, index: any) => {
                              return <MenuItem data-test-id={`selectCalanderMenu${index}`} key={item.value} className="menu-item-text" onClick={() => this.handleCalendarSelect(item.value)}>{item.label}{getCheckMarkIcon(item.value, checkMark, this.state.calanderValue)}</MenuItem>
                            })
                          }


                        </Menu>
                      </div>
                    </div>
                  </Grid>
                </Grid> */}
                <Grid className="dashboard-datepicker-container" container>
                  {this.state.openCalendar && (
                    <div className="calander-picker">
                      <div style={{ height: "390px", width: "351px" }}>
                        <div>
                          <Typography className="calander-range-text-label">Custom...</Typography>
                          <Typography className="calander-range-text-value">{moment(this.state.dateRangeValue[0]).format('MMM D')} - {moment(this.state.dateRangeValue[1]).format('MMM D YYYY')}</Typography>
                        </div>
                        <hr />
                        <div style={{ height: "337px" }}>
                          <DateRangePicker
                            isOpen={this.state.openCalendar}
                            value={this.state.dateRangeValue}
                            onChange={this.handleDateChange}
                            className="daterange-picker-css"
                          />
                        </div>
                        <hr />
                      </div>
                      <div style={webStyles.rightAlign}>
                        <Button data-test-id="close-calendar" onClick={() => this.closeCalander()}>
                          <Typography className="cal-cancel-btn">
                            Cancel
                          </Typography>
                        </Button>
                        <Button data-test-id="get-filter-data" onClick={() => this.getFilteredData()}>
                          <Typography className="cal-apply-btn">
                            Apply
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  )}
                </Grid>
                {/* <TableSkeleton /> */}
                {this.state.loading ? (
                  // <Loader loading={this.state.loading} contentLoader={true} />
                  <DashboardSkeleton />

                ) : (
                  <>
                    <Grid className="dashboard-section-one">
                      {this.state.emptyState ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          className="dashboard-section-empty-grid"
                        >
                          <Grid
                            className="lead-section-one-grid-empty-one"
                            item
                            xs={6}
                            md={6}
                          >
                            <Box>
                              <Typography className="grid-text-empty-heading">
                                Get started by adding deals
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="grid-text-empty-sub-heading">
                                Add more deals to this dashboad and soon the
                                data will be generated for you to view
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            className="lead-section-one-grid-empty-two"
                            item
                            xs={6}
                            md={6}
                          >
                            <img src={graphBars} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={12} container className="dashboard-section-one-grid-list">
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Total Deals Completed
                              </Typography>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container>
                                  <Grid item className="mr-16 icon-wrapper">
                                    <img src={deals} alt="Deals" />
                                  </Grid>
                                  <Grid item xs={4} className="grid-count-wrap">
                                    <Typography className="grid-count-text"
                                    >
                                      {getDashboardValues(
                                        this.state.dashboardData.leads_graph,
                                        "Closed won"
                                      )}
                                      <label className="grid-up-text">
                                        10%
                                      </label>
                                      <label>
                                        <img src={up_arrow} />
                                      </label>
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                  <Grid item className="grid-small-chart">
                                    <AllTimeGraph data={this.state.dashboardData.conversion_graph} />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Total Leads Gathered
                              </Typography>
                            </Box>
                            <Box className="grid-counts">
                              <Grid item xs={12} md={12} container>
                                <Grid item className="mr-16 icon-wrapper">
                                  <img src={leads} alt="Deals" />
                                </Grid>
                                <Grid item xs={4} className="grid-count-wrap">
                                  <Typography className="grid-count-text"
                                    style={{ cursor: 'pointer' }}
                                    data-test-id={"btnLeadDashboard-1"}
                                    onClick={() => this.navigateToLeadDashboard()}
                                  >
                                    {getDashboardValues(
                                      this.state.dashboardData.total_deals,
                                      "total leads"
                                    )}
                                    <label className="grid-up-text">10%</label>
                                    <label>
                                      <img src={up_arrow} />
                                    </label>
                                  </Typography>
                                  <Typography className="grid-count-sub-text">
                                    {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                  </Typography>
                                </Grid>
                                <Grid item className="grid-small-chart">
                                  <AllTimeGraph data={this.state.dashboardData.conversion_graph} />

                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Conversion Value
                              </Typography>
                            </Box>
                            <Box className="grid-counts">
                              <Grid item xs={12} md={12} container>
                                <Grid item className="mr-16 icon-wrapper">
                                  <img src={conversion} alt="Deals" />
                                </Grid>
                                <Grid item xs={4} className="grid-count-wrap">
                                  <Typography className="grid-count-text">
                                    $200K
                                    <label className="grid-up-text">10%</label>
                                    <label>
                                      <img src={up_arrow} />
                                    </label>
                                  </Typography>
                                  <Typography className="grid-count-sub-text">
                                    {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                  </Typography>
                                </Grid>
                                <Grid item className="grid-small-chart">
                                  <AllTimeGraph data={this.state.dashboardData.conversion_graph} />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {/* <div className="view-more-block">
                        <Button
                          data-test-id={"btnLeadDashboard-2"}
                          onClick={() => this.navigateToLeadDashboard()}
                          disableRipple
                          className="view-more-btn"
                        >
                          View Deals and Leads
                          <label className="small-icon">
                            <img
                              src={left_arrow}
                              alt="View Deals and leads"
                            />
                          </label>
                        </Button>
                      </div> */}
                    </Grid>
                    <Grid className="chart-section">
                      <div className="chart-row">
                        <div className="chart-col">
                          <div className="chart-col-inner-wrap">
                            <div className="chart-outer-wrap">
                              <Typography className="section-two-container-heading">
                                Deals in progress
                              </Typography>
                              {this.state.emptyState ? (
                                <div className="no-content-wrapper">
                                  <div className="empty-content-wrap">
                                    <Box className="image-block">
                                      <img src={empty_deals} />
                                    </Box>
                                    <Typography className="no-conversion-text">
                                      No data added for this report
                                    </Typography>
                                    <Typography className="no-conversion-sub-text">
                                      Get started by adding your first deal to
                                      this dashboard
                                    </Typography>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "16px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        data-test-id={"btnRegisteredDealLead2"}
                                        onClick={() =>
                                          this.navigateToLeadManagement()
                                        }
                                        variant="contained"
                                        color="primary"
                                        className="heading-button"
                                      >
                                        Register new deal
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="chart-wrapper">
                                    <img
                                      src={empty_bars}
                                      style={{
                                        marginTop: "22px",
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <Grid className="container-two-sub-text">
                                    <Typography className="grid-two-text-heading">
                                      TOTAL LEADS
                                    </Typography>
                                    <Box className="">
                                      <Typography className="grid-count-two-text">
                                        {getDashboardValues(
                                          this.state.dashboardData.total_deals,
                                          "total leads"
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid className="chart-wrapper" container style={{ position: 'relative' }}>
                                    <Grid xs={6} md={6}>
                                      <ul className="custom-legend-listing">
                                        <li className="legend-item">
                                          <Typography>
                                            <img
                                              src={tracking_img}
                                              className="mr-4"
                                            />
                                            In progress
                                          </Typography>
                                          <div className="number-text">
                                            {getDashboardValues(
                                              this.state.dashboardData.leads_graph,
                                              "In progress"
                                            )}
                                          </div>
                                        </li>
                                        <li className="legend-item">
                                          <Typography>
                                            <img
                                              src={prospecting_img}
                                              className="mr-4"
                                            />
                                            Open
                                          </Typography>
                                          <div className="number-text">
                                            {getDashboardValues(
                                              this.state.dashboardData.leads_graph,
                                              "Open"
                                            )}
                                          </div>
                                        </li>
                                        <li className="legend-item">
                                          <Typography>
                                            <img
                                              src={engagement_img}
                                              className="mr-4"
                                            />
                                            Disqualified
                                          </Typography>
                                          <div className="number-text">
                                            {getDashboardValues(
                                              this.state.dashboardData.leads_graph,
                                              "Disqualified"
                                            )}
                                          </div>
                                        </li>
                                        <li className="legend-item">
                                          <Typography>
                                            <img
                                              src={closed_won_img}
                                              className="mr-4"
                                            />
                                            Closed won
                                          </Typography>
                                          <div className="number-text">
                                            {getDashboardValues(
                                              this.state.dashboardData.leads_graph,
                                              "Closed won"
                                            )}
                                          </div>
                                        </li>
                                        <li className="legend-item">
                                          <Typography>
                                            <img
                                              src={closed_lost_img}
                                              className="mr-4"
                                            />
                                            Closed lost
                                          </Typography>
                                          <div className="number-text">
                                            {getDashboardValues(
                                              this.state.dashboardData.leads_graph,
                                              "Closed lost"
                                            )}
                                          </div>
                                        </li>
                                      </ul>
                                    </Grid>
                                    <Grid xs={6} md={6} className="doughnut-chart" style={{ position: 'relative' }}>
                                      <Doughnut
                                        options={{
                                          cutout: 60,
                                          maintainAspectRatio: false,
                                          responsive: true,
                                          plugins: {
                                            tooltip: {
                                              displayColors: false,
                                              backgroundColor: "#3C3E49",
                                              titleColor: "#fff",
                                              bodyColor: "#fff",
                                              padding: 16,
                                              bodyFont: {
                                                family: 'Rubik',
                                                size: 14
                                              },
                                              callbacks: {
                                                label: (data) => {
                                                  return `${data.label} ${data.formattedValue
                                                    }`;
                                                },
                                              },
                                            },
                                            legend: { display: false },
                                          },
                                        }}
                                        data={donughtData(
                                          this.state.dashboardData.leads_graph
                                        )}
                                      />
                                    </Grid>

                                  </Grid>

                                </>
                              )}
                            </div >
                            <div className="view-more-block">
                              <Button disableRipple className="view-more-btn"
                                data-test-id={"btnLeadDashboard3"}
                                onClick={() =>
                                  this.navigateToLeadDashboard()
                                }
                              >
                                View Deals
                                <label className="small-icon">
                                  <img
                                    src={left_arrow}
                                    alt="View Deals and leads"
                                  />
                                </label>
                              </Button>
                            </div>
                          </div >
                        </div >
                        <div className="chart-col" >
                          <div className="chart-col-inner-wrap">
                            <div className="chart-outer-wrap">
                              <Typography className="section-two-container-heading">
                                Conversion
                              </Typography>
                              <Grid className="line-chart">
                                {this.state.emptyState || this.state.dashboardData.conversion_graph.length === 0 ? (
                                  <div className="empty-line-content-wrap">
                                    <div className="empty-line-inner-wrap">
                                      <Box className="image-block">
                                        <img src={empty_conversion} />
                                      </Box>
                                      <Typography className="no-conversion-text">
                                        No conversions yet
                                      </Typography>
                                      <Typography className="no-conversion-sub-text">
                                        Add more deals to this dashboad and soon
                                        this data shall be generated
                                      </Typography>
                                    </div>
                                    <div className="empty-image-wrap">
                                      <img
                                        src={empty_graph}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <Line
                                    data={lineData(this.state.dashboardData.conversion_graph)}
                                    options={{
                                      maintainAspectRatio: false,
                                      plugins: {
                                        zoom: {
                                          pan: {
                                            enabled: true,
                                            mode: 'xy', // Enable panning in both directions
                                          },
                                          zoom: {
                                            wheel: {
                                              enabled: true,
                                            },
                                            pinch: {
                                              enabled: true,
                                            },
                                            mode: 'xy', // Enable zooming in both directions
                                          },
                                        },
                                        tooltip: {
                                          displayColors: false,
                                          backgroundColor: "#3C3E49",
                                          titleColor: "#fff",
                                          bodyColor: "#fff",
                                          padding: 16,
                                          titleFont: {
                                            family: 'Rubik',
                                            weight: 'normal',
                                            size: 10,
                                          },
                                          bodyFont: {
                                            family: 'Rubik',
                                            size: 14
                                          },
                                          callbacks: {
                                            //@ts-ignore
                                            title: (data) => {
                                              return moment(data[0].label).format('ddd DD MMMM');
                                            },
                                            label: (data) => {
                                              return `Deals Won ${data.formattedValue}`;
                                            },
                                          },
                                        },
                                        legend: { display: false },
                                      },
                                      scales: {
                                        y: {
                                          beginAtZero: true,
                                          drawBorder: false,
                                          title: {
                                            display: true,
                                            text: 'Deals won',
                                            font: {
                                              size: 14,
                                              family: 'Rubik',
                                              weight: '300',
                                              //@ts-ignore
                                              color: '#83889E',
                                            },
                                          },
                                          ticks: {
                                            //@ts-ignore
                                            minTicksLimit: 4,
                                            maxTicksLimit: 5,
                                          },
                                          grid: {
                                            color: '#ECEDF0',
                                          },
                                        },
                                        x: {
                                          beginAtZero: false,
                                          ticks: {
                                            maxTicksLimit: 5,
                                            maxRotation: 0,
                                            minRotation: 0,
                                            align: 'center',
                                            //@ts-ignore
                                            callback: function (val: any, index: any) {
                                              return [moment(this.getLabelForValue(val)).format('DD'), moment(this.getLabelForValue(val)).format('MMM')]
                                            },
                                          },
                                        },
                                      },
                                      aspectRatio: 2,
                                    }}
                                  />
                                )}
                              </Grid>
                            </div>
                            <div className="view-more-block">

                              <Button disableRipple className="view-more-btn"
                                data-test-id={"btnLeadDashboard-4"}
                                onClick={() => this.navigateToLeadDashboard()}

                              >
                                View More
                                <label className="small-icon">
                                  <img
                                    src={left_arrow}
                                    alt="View Deals and leads"
                                  />
                                </label>
                              </Button>
                            </div>
                          </div>
                        </div >
                      </div >
                    </Grid >
                    <Grid className="dashboard-section-three">
                      {this.state.emptyState ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          className="dashboard-section-empty-grid"
                        >
                          <Grid
                            className="lead-section-one-grid-empty-one height-lg"
                            item
                            xs={6}
                            md={6}
                          >
                            <Box>
                              <Typography className="grid-text-empty-heading">
                                No commissions yet
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="grid-text-empty-sub-heading">
                                Add more deals to this dashboad and soon this
                                data shall be generated
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            className="lead-section-one-grid-empty-two"
                            item
                            xs={6}
                            md={6}
                          >
                            <img src={payment_pending} className="adjust-spacing" />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className="commission-section">
                          <Box>
                            <Typography className="section-three-heading">
                              Commission report
                            </Typography>
                          </Box>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            container
                            className="section-container"
                          >
                            <Grid
                              item
                              xs={6}
                              md={6}
                              container
                              className="commission-inner-row section-grid-one"
                            >
                              <Grid className="commission-inner-col col-one">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    TOTAL LEADS
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      {getDashboardValues(
                                        this.state.dashboardData.total_deals,
                                        "total leads"
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="commission-inner-col col-two">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    LEADS CONVERTED
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      {getDashboardValues(
                                        this.state.dashboardData.leads_graph,
                                        "Closed won"
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="commission-inner-col col-three">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    CONVERSION VALUE
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      $200K
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={6}
                              container
                              className="commission-secondary-row"
                            >
                              <Grid
                                className="commission-secondary-col col-lg"
                                item
                                xs={8}
                                md={8}
                              >
                                <Grid item xs={12} md={12} container>
                                  <Grid item xs={12} md={12} className="col-inner-content">
                                    <i className="image-wrap">
                                      <img src={dollar} alt="Deals" />
                                    </i>
                                    <div className="content-block">
                                      <Typography className="grid-three-text-heading">
                                        Commission earned LAST 28 DAYS
                                      </Typography>
                                      <Box className="grid-counts">
                                        <Grid item xs={12} md={12} container>
                                          <Grid item xs={6} md={6}>
                                            <Typography className="grid-count-three-text-one">
                                              85
                                              {/* <label className="grid-up-text">
                                                10%
                                              </label>
                                              <label>
                                                <img src={up_arrow} />
                                              </label> */}
                                            </Typography>
                                            {/* <div className="border-line"></div> */}
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                className="commission-secondary-col col-sm"
                                item
                                xs={4}
                                md={4}
                              >
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    MINIMUM COMMITMENT
                                  </Typography>
                                </Box>
                                <Box className="grid-counts">
                                  <Typography className="grid-count-three-text">
                                    $40K
                                  </Typography>
                                  <Typography className="on-track-label">
                                    ON TRACK
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <div className="view-more-block">
                        <Button disableRipple className="view-more-btn"
                          data-test-id={"btnLeadDashboard-5"}
                          onClick={() => this.navigateToCommissionDashboard()}
                        >
                          View More
                          <label className="small-icon">
                            <img
                              src={left_arrow}
                              alt="View Deals and leads"
                            />
                          </label>
                        </Button>
                      </div>
                    </Grid>
                  </>
                )}
              </Box >
            </Grid >
          </Grid >
        </Grid >
        <Modal
          open={this.state.addMemberModal}
          onClose={() => this.closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
            </Box>
            <Box className="welcome-content">
              <Box className="logo-block">
                <img src={site_logo} alt="" />
              </Box>
              <Box className="welcontent-wrap">
                <Typography variant="h4">{`Welcome ${this.state.dashboardData.partner.name}`}</Typography>
                <Typography>Unlock profits through collaboration! Introducing Builder.ai's Partnership Platform – earn commissions by referring leads. Join us in shaping success!</Typography>
              </Box>
              <Box className="btn-wrap">
                <Button onClick={() => this.closeModal()} className="custom-btn green-btn full-width">Continue</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: "#F8F9FA",
  },
  mainContainer: {
    padding: "16px 0",
  },
  rightAlign: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
  },
};
// Customizable Area End
