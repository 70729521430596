// Customizable Area Start

import { Box } from "@material-ui/core";
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface MyProps {
  hasRightBlock?: boolean;
  hasProfileIcon?: boolean;
}



export default function RegisterLeadSkeleton(props: MyProps) {
  return (
    <Box className="skeleton-wrapper register-deal-lead-skeleton">
      <Box className="form-title-block">
        <Box className="left-block">
          <Skeleton height={24} width={140} />
          <Skeleton height={16} width={200} style={{ marginTop: '6px' }} />
        </Box>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box className="buttons-list-skeleton">
          <Skeleton className="btn-skeleton" height={48} />
        </Box>
        <Box className="right-block" style={{ paddingLeft: '10px' }}>
          <Skeleton height={16} width={150} />
        </Box>
      </Box>
      <Box className='form-outer-wrapper'>
        <Box className="left-block">

          <Box className="deal-form-container deal-form-skeleton">
            <Box className="deal-block-title-skeleton">
              <Skeleton height={20} width={250} />
            </Box>
            <Box className="form-element-list-skeleton">
              <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                <Skeleton height={60}></Skeleton>
                <Skeleton height={60}></Skeleton>
              </Box>
              <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                <Skeleton height={60}></Skeleton>
                <Skeleton height={60}></Skeleton>
              </Box>
              <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                <Skeleton height={60}></Skeleton>
              </Box>
              <Box className="deal-block-title-skeleton">
                <Skeleton height={20} width={250} />
              </Box>
              <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                <Skeleton height={60}></Skeleton>
                <Skeleton height={60}></Skeleton>
              </Box>
              <Box className="form-row-skeleton" style={{ margin: '0 0 16px' }}>
                <Skeleton height={60}></Skeleton>
              </Box>
              <Box className="deal-button-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Skeleton height={60} width={300} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// Customizable Area End